import axios from 'axios';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { useCancelToken } from 'hooks/useCancelToken';
import { isNil } from 'lodash';
import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { OBJECT_RECORD_FIELD_DETAILS } from 'utils/endpoints';
import {
  ObjectRecordUserAndGroupMetadata,
  ObjectRecordUserFieldDetails,
} from 'utils/types/api/objectRecords.types';

/**
 * Allows to retrieve user field details for a given field in a context of the specified object
 * record. It's mainly used to get the metadata of field value for users and groups, and to check
 * whether the field is fulfillable.
 *
 * @param objRecordId Object record id of the record to fetch details for.
 * @param fieldAlias Field alias of the field to fetch details for.
 */
export const useObjRecordUserFieldDetails = (
  objRecordId: number | undefined,
  fieldAlias: string
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [cachedFieldDetails, setCachedFieldDetails] = useState<{
    valueMetadata: ObjectRecordUserAndGroupMetadata;
    isMinUsersFulfilled: boolean;
  } | null>(null);

  const [valueMetadata, setValueMetadata] = useState<
    ObjectRecordUserAndGroupMetadata
  >({ users: [], groups: [] });

  const { cancelOngoingRequests, createCancelToken } = useCancelToken();

  const fetchFieldDetails = async (skipCache: boolean) => {
    try {
      // Simple cache. If we have the options we do not refetch them. Full browser refresh is required to invalidate this cache.
      if (!skipCache && !isNil(cachedFieldDetails)) {
        return cachedFieldDetails;
      }

      setIsLoading(true);
      cancelOngoingRequests();

      const cancelToken = createCancelToken();
      const endpoint = generatePath(OBJECT_RECORD_FIELD_DETAILS, {
        id: objRecordId,
        fieldAlias,
      });

      const response = await apiCall.get<ObjectRecordUserFieldDetails>(
        endpoint,
        { cancelToken }
      );

      const newValueMetadata: ObjectRecordUserAndGroupMetadata = {
        groups: Object.values(response.data._meta.user_groups ?? {}),
        users: Object.values(response.data._meta.users ?? {}),
      };

      const fieldDetails = {
        valueMetadata: newValueMetadata,
        isMinUsersFulfilled: response.data._meta.is_min_users_fulfilled,
      };

      setValueMetadata(newValueMetadata);
      setCachedFieldDetails(fieldDetails);

      return fieldDetails;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      showUnhandledErrorToast(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    valueMetadata,
    fetchFieldDetails,
  };
};
