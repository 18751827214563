import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useExpandablePickerDeselectOnlyGroupItemStyles = createUseStyles(
  (theme: ThemeState) => ({
    optionItem: {
      position: 'relative',
      minHeight: 47,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'default',
      padding: '3px 6px',
      gap: '6px',
      background: 'transparent',
      width: '100%',
      textAlign: 'start',

      '& .ant-avatar': {
        background: theme['border-color-base'],
      },

      '& div:not(:first-child)': {
        color: `${theme['disabled-switch-gray']} !important`,
      },

      '&:focus': {
        outline: `none`,
      },

      '&:hover': {
        backgroundColor: theme['dropdown-hover-gray'],
      },
    },
    syncIcon: {
      position: 'absolute',
      right: '30px',
      top: '8px',
      color: theme['disabled-switch-gray'],
    },
    deselectButton: {
      outline: 'none',
      background: 'transparent',
      border: 'none',
      width: 'auto',
      height: 'auto',
      padding: 0,
      color: theme['secondary-color'],

      '&:focus': {
        outline: 'none',
      },
    },
  })
);
