import React from 'react';
import { useAutologyxInformationStyles } from './AutologyxInformation.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  INFORMATION_SUBTITLE_TESTID,
  INFORMATION_TITLE_TESTID,
  INFORMATION_WRAPPER_TESTID,
} from 'utils/testIds';
import { useSelector } from 'react-redux';
import { customThemeSelector } from 'store/selectors/customThemeSelectors';
import { AutologyxInformationProps } from './AutologyxInformation.types';
import clsx from 'clsx';

export const AutologyxInformation = ({
  className,
}: AutologyxInformationProps) => {
  const intl = useIntl();
  const styles = useAutologyxInformationStyles();
  const {
    textOverrides: { headingText = undefined, bodyText = undefined } = {},
    isEnabled,
  } = useSelector(customThemeSelector);

  const defaultHeadingText = intl.formatMessage(
    {
      id: 'authLayout.headingText',
      defaultMessage: 'The {operationsPlatform} for Legal and Compliance',
    },
    {
      operationsPlatform: (
        <strong>
          <FormattedMessage
            id='misc.operationsPlatform'
            defaultMessage='Operations Platform'
          />
        </strong>
      ),
    }
  );
  const defaultBodyText = intl.formatMessage({
    id: 'authLayout.supportingText',
    defaultMessage:
      'Build digital solutions for your workforce and customers on a single platform. Turn ideas into reality, fast.',
  });

  return (
    <div
      className={clsx(styles.mainWrapper, className)}
      data-testid={INFORMATION_WRAPPER_TESTID}
    >
      <div className={styles.textWrapper}>
        <p className={styles.title} data-testid={INFORMATION_TITLE_TESTID}>
          {isEnabled && headingText !== undefined
            ? headingText
            : defaultHeadingText}
        </p>

        <p
          className={styles.subtitle}
          data-testid={INFORMATION_SUBTITLE_TESTID}
        >
          {isEnabled && bodyText !== undefined ? bodyText : defaultBodyText}
        </p>
      </div>
    </div>
  );
};
