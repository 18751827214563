import { createUseStyles } from 'react-jss';
import defaultTheme from 'utils/defaultTheme';

export const useForgotPasswordFormStyles = createUseStyles({
  title: {
    marginBottom: '4px',

    fontSize: '1.25rem',
    textAlign: 'center',
    fontWeight: 700,
  },
  subtitle: {
    fontSize: '0.75rem',
    margin: 0,
    color: defaultTheme['text-color'],
    opacity: 0.6,
    textAlign: 'center',
  },
  input: {
    marginTop: '32px',
  },
  buttonWrapper: {
    display: 'flex',
    marginTop: '32px',
    justifyContent: 'flex-end',
  },
  description: {
    fontSize: '0.75rem',
    marginTop: -13,
    textAlign: 'justify',
    fontWeight: 400,
    color: defaultTheme['disabled-color'],
    display: 'block',
    wordBreak: 'break-word',
  },
  errorIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  errorIcon: {
    width: 86,
    height: 86,

    marginBottom: '16px',
  },
});
