import { AvatarProps } from 'antd/lib/avatar';
import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseAvatarStylesProps = {
  size?: AvatarProps['size'];
};

export const useAvatarStyles = createUseStyles((theme: ThemeState) => ({
  countAvatar: {
    border: `1px solid ${theme['primary-color']}`,
    backgroundColor: '#fff',
    color: theme['primary-color'],
    fontSize: ({ size }: UseAvatarStylesProps = {}) =>
      size === 'small' ? 12 : 'initial',
    width: ({ size }: UseAvatarStylesProps = {}) =>
      size === 'small' ? 25 : 'initial',
    height: ({ size }: UseAvatarStylesProps = {}) =>
      size === 'small' ? 25 : 'initial',
  },
}));
