import axios, { AxiosResponse } from 'axios';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { useCancelToken } from 'hooks/useCancelToken';
import { StatusCodes } from 'http-status-codes';
import { ActivateAccountParams } from 'pages/ActivateAccount/types';
import { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { getAccessToken } from 'store/selectors/authSelectors';
import { apiCall } from 'utils/api';
import { RESET_PASSWORD_TOKEN } from 'utils/endpoints';
import routes, { nonProtectedRoutes } from 'utils/routingPaths';
import { ErrorCodes } from 'utils/types/errorResponse';

export const useResetPasswordData = () => {
  const { token } = useParams<ActivateAccountParams>();
  const history = useHistory();
  const accessToken = useSelector(getAccessToken);

  const { cancelOngoingRequests, createCancelToken } = useCancelToken();

  const [error, setError] = useState<AxiosResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenSuccessfulyVerified, setIsTokenSuccessfulyVerified] = useState(
    false
  );

  const verifyResetPasswordToken = useCallback(async () => {
    try {
      if (!token) {
        return;
      }

      cancelOngoingRequests();
      setIsLoading(true);

      const endpoint = generatePath(RESET_PASSWORD_TOKEN, { token });
      const cancelToken = createCancelToken();

      await apiCall.get(endpoint, {
        cancelToken,
      });

      setError(undefined);
      setIsTokenSuccessfulyVerified(true);
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      if (!axios.isAxiosError(error)) {
        showUnhandledErrorToast(error);
        return;
      }

      setError(error.response);

      if (error.response?.status === StatusCodes.FORBIDDEN) {
        history.replace(nonProtectedRoutes.LOGIN);
        return;
      }

      if (error.response?.data?.error_code === ErrorCodes.ERR_ACCOUNT_LOCKED) {
        return;
      }

      showUnhandledErrorToast(error);
    } finally {
      setIsLoading(false);
    }
  }, [history, token, cancelOngoingRequests, createCancelToken]);

  useEffect(() => {
    if (!!accessToken) {
      history.replace(routes.HOMEPAGE);
      return;
    }

    verifyResetPasswordToken();
  }, [accessToken, history, verifyResetPasswordToken]);

  return {
    error,
    isLoading,
    isTokenSuccessfulyVerified,
    verifyResetPasswordToken,
  };
};
