import React, { forwardRef } from 'react';
import { Avatar as AntAvatar } from 'antd';
import clsx from 'clsx';
import { useAvatarStyles } from './Avatar.styles';
import { AvatarExtendedProps } from './Avatar.types';

export const Avatar = forwardRef<HTMLElement, AvatarExtendedProps>(
  ({ isCountAvatar, className, size, ...rest }, ref) => {
    const styles = useAvatarStyles({ size });

    return (
      <AntAvatar
        ref={ref}
        className={clsx(className, {
          [styles.countAvatar]: isCountAvatar,
        })}
        size={size}
        {...rest}
      />
    );
  }
);
