import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CloseIcon } from 'components/Icon';
import {
  ButtonPrimary,
  ButtonSecondaryOutlined,
  ButtonTertiary,
} from 'components/lib/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import useTaskDisplayStyles from './styles';
import TaskHeader from './TaskHeader';
import { TaskFormProps } from './types';
import { Loader } from 'components/lib/Loader';
import clsx from 'clsx';
import { useCompleteOrSaveTask } from './hooks';
import {
  CANCEL_TASK_FORM_BUTTON,
  COMPLETE_TASK_FORM_BUTTON,
  SAVE_PROGRESS_TASK_FORM_BUTTON,
} from 'utils/testIds';
import { FormDataValue } from 'components/FormPreview2/types/formDataValue';
import { FormState } from 'components/FormPreview2/types/formState';
import SanitizedHTML from 'components/SanitizedHTML';
import ErrorComponent from 'components/ErrorComponent';
import FormPreview2 from 'components/FormPreview2';
import { FormPreview2RefProps } from 'components/FormPreview2/types';
import useTask from './useTask';
import { CustomFileItem } from 'components/lib/FileUpload/types';
import { useHelmetContext } from 'contexts/HelmetContext';
import { StatusCodes } from 'http-status-codes';
import PreventRedirects from 'components/PreventRedirects';
import TaskProperties from './components/TaskProperties';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import { TASK_COMPLETE_FORM_MODAL_ID } from 'components/MultiPageComponents/consts';
import NoPermissionModal from './components/NoPermissionsModal';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedTask } from 'store/selectors/taskSelectors';
import { getTaskAssignees } from 'store/selectors/tasksAssigneesSelectors';
import { selectTask } from 'store/actions/taskActions';
import { TaskStatus } from 'utils/Enums/TaskStatus';
import {
  TaskOwnershipContextConsumer,
  TaskOwnershipContextProvider,
} from './components/TaskOwnershipContext';
import AssignOwnerModal from './components/AssignOwnerModal';
import CancelConfigurationModal from 'components/CancelConfigurationModal';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { CustomFieldResetFunction } from 'alx-dynamic-form';
import { TASK_FORM_WRAPPER } from 'utils/elementsIds';
import { SearchBarDisplay } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/types/searchBarDisplay';
import { FieldSelectionWorkspace } from 'components/FormPreview2/types/fieldSelectionWorkspace';

const TaskDisplay = ({
  taskId,
  onCancelClick,
  readOnly,
  updatePageHeader,
  cancelButtonContent,
  checkPermission,
  shouldSetSelectedResource,
}: TaskFormProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useTaskDisplayStyles({});
  const [
    { hasErrors, isFormTouched, isUploadingInProgress },
    setFormState,
  ] = useState<FormState>({} as FormState);
  const [formHasUnsavedChanges, setFormHasUnsavedChanges] = useState(false);
  const selectedTask = useSelector(getSelectedTask);
  const [
    shouldNoPermissionBeDisplayed,
    setShouldNoPermissionBeDisplayed,
  ] = useState(false);
  const [isTaskAlreadyCompleted, setIsTaskAlreadyCompleted] = useState(false); //indicates whether the reason on POST request failure was already completed task
  const formRef = useRef<FormPreview2RefProps>(null);
  const isTaskSavingRef = useRef<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const [noPermissionModalSubtitle, setNoPermissionModalSubtitle] = useState(
    ''
  );
  const [noPermissionModalTitle, setNoPermissionModalTitle] = useState<
    string
  >();

  const { completeOrSaveTask, errors } = useCompleteOrSaveTask(
    taskId,
    setShouldNoPermissionBeDisplayed,
    setIsTaskAlreadyCompleted,
    setNoPermissionModalSubtitle,
    setNoPermissionModalTitle
  );
  const {
    error,
    instructions,
    isLoading,
    recordInformation,
    initialValues,
    schema,
    status,
    task,
    taskName,
    uiSchema,
    migrationError,
    accessErrors,
    meta,
    fetchData,
  } = useTask(
    taskId,
    readOnly,
    setShouldNoPermissionBeDisplayed,
    checkPermission
  );

  const { setSelectedResource } = useSelectedResourceContext();
  useEffect(() => {
    if (shouldSetSelectedResource && recordInformation.recordId) {
      setSelectedResource({
        record: {
          recordId: recordInformation.recordId.toString(),
          identifier: recordInformation.recordIdentifier.toString(),
          permissions: {},
          isAccessedFromTask: true,
          isSummaryPanelEnabled: true,
        },
      });
      return () => {
        setSelectedResource({ record: undefined });
      };
    }
  }, [
    recordInformation.recordId,
    recordInformation.recordIdentifier,
    setSelectedResource,
    shouldSetSelectedResource,
  ]);

  const stages = useSelector(getTaskAssignees(taskId.toString()));
  const { setPageTitle } = useHelmetContext();
  const { setShouldBeDisplayed } = useConfirmationModalContext();

  const accessError = accessErrors[readOnly ? 'view' : 'complete'];

  const title = taskName
    ? intl.formatMessage(
        { id: 'task.viewPageHeader', defaultMessage: 'View {taskName}' },
        { taskName }
      )
    : undefined;

  useEffect(() => {
    if (updatePageHeader && title) setPageTitle(title);
  }, [setPageTitle, title, updatePageHeader]);

  const onFormStateChange = useCallback((formState: FormState) => {
    setFormHasUnsavedChanges(true);

    setFormState(formState);
  }, []);

  const handleSubmit = useCallback(
    async (
      parsedFormData: MappedObject<FormDataValue>,
      parsedFilesData: MappedObject<CustomFileItem[]>
    ) => {
      if (isSaving) {
        return false;
      }
      setIsSaving(true);
      const result = await completeOrSaveTask({
        parsedFormData,
        parsedFilesData,
        isTaskSaving: isTaskSavingRef.current,
      });

      if (result) {
        setFormHasUnsavedChanges(false);

        if (onCancelClick) onCancelClick(true, false);
      } else {
        setIsSaving(false);
      }

      return result;
    },
    [completeOrSaveTask, onCancelClick, isSaving, setIsSaving]
  );

  const onConfirmNoPermissionModal = useCallback(async () => {
    setFormHasUnsavedChanges(false);

    if (isTaskAlreadyCompleted && selectedTask) {
      // switch display to readonly mode and refetch the list so button on panel changes from "complete" to "view"
      // fetchData() to update the input values with completed data.
      if (selectedTask?.refetchTasksList) await selectedTask.refetchTasksList();

      fetchData();
      setIsTaskAlreadyCompleted(false);
      setShouldNoPermissionBeDisplayed(false);
      dispatch(selectTask({ ...selectedTask, readOnly: true }));

      return;
    }

    if (isFormTouched)
      setFormState(prevState => ({ ...prevState, isFormTouched: false }));

    if (onCancelClick) onCancelClick(true);
  }, [
    isFormTouched,
    onCancelClick,
    selectedTask,
    dispatch,
    isTaskAlreadyCompleted,
    fetchData,
  ]);

  const renderError = useMemo(() => {
    if (!isLoading && (error || accessError)) {
      // treat 404 error as 403 as required by #32545
      const notFoundAsForbidden =
        error?.status === StatusCodes.NOT_FOUND ? 403 : error?.status;

      if (
        notFoundAsForbidden === StatusCodes.FORBIDDEN ||
        accessError === StatusCodes.FORBIDDEN
      ) {
        return (
          <NoPermissionModal
            visible={shouldNoPermissionBeDisplayed}
            onConfirm={onConfirmNoPermissionModal}
            subtitle={noPermissionModalSubtitle}
            title={noPermissionModalTitle}
          />
        );
      }

      return <ErrorComponent error={notFoundAsForbidden || accessError} />;
    }
  }, [
    isLoading,
    error,
    accessError,
    shouldNoPermissionBeDisplayed,
    onConfirmNoPermissionModal,
    noPermissionModalSubtitle,
    noPermissionModalTitle,
  ]);

  const handleClick = useCallback(
    async (isTaskSaving: boolean) => {
      isTaskSavingRef.current = isTaskSaving;

      const { submitForm } = formRef.current || {};

      if (submitForm && !isSaving) {
        submitForm();
      }
    },
    [isSaving]
  );

  useEffect(() => {
    // ensure task and selected task both refer to same thing
    if (
      task?.status === TaskStatus.Completed &&
      !readOnly &&
      selectedTask &&
      task?.id === selectedTask?.id
    ) {
      setShouldNoPermissionBeDisplayed(true);
    }
  }, [task, readOnly, selectedTask, dispatch]);

  useEffect(() => {
    if (readOnly) {
      setNoPermissionModalSubtitle(
        intl.formatMessage({
          id: 'misc.taskNoPermissionsViewDescription',
          defaultMessage: 'You cannot view it.',
        })
      );
    } else {
      setNoPermissionModalSubtitle(
        intl.formatMessage({
          id: 'misc.taskNoPermissionsCompleteDescription',
          defaultMessage: 'You cannot complete it.',
        })
      );
    }
  }, [readOnly, intl]);

  useEffect(() => {
    setShouldBeDisplayed(
      TASK_COMPLETE_FORM_MODAL_ID,
      isFormTouched && formHasUnsavedChanges
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormTouched, formHasUnsavedChanges]);

  if (renderError) {
    return renderError;
  }

  const customResetFunction: CustomFieldResetFunction = ({
    fieldId,
    currentVal,
  }) => {
    if (!!fieldId && fieldId > 0) {
      return currentVal;
    }
    return null;
  };

  return (
    <Loader
      size='large'
      spinning={isLoading}
      className={classes.loader}
      wrapperClassName={classes.loaderWrapper}
    >
      {!!task && !!stages ? (
        <>
          <TaskOwnershipContextProvider refetchData={fetchData}>
            <div className={classes.formContent}>
              <TaskHeader
                {...{
                  status,
                  taskId,
                  recordId: recordInformation.recordId,
                  taskName,
                }}
              />
              <TaskProperties {...{ task: { ...task, stages } }} />
              {!!instructions && (
                <SanitizedHTML
                  className={classes.instructions}
                  html={instructions}
                />
              )}
              <div className={classes.taskFormWrapper}>
                {
                  // to force unmount on task id change (key prop doesn't work)
                  !isLoading && (
                    <FormPreview2
                      schema={schema}
                      uischema={uiSchema}
                      withFormHeader={false}
                      disabled={readOnly}
                      saving={isSaving}
                      {...{
                        errors,
                        onFormStateChange,
                        initialValues,
                        readOnly,
                      }}
                      ref={formRef}
                      onSubmit={handleSubmit}
                      isCreateMode={!readOnly}
                      showShemaError={!!migrationError}
                      customFieldResetFunction={customResetFunction}
                      wrapperId={TASK_FORM_WRAPPER}
                      additionalFieldProps={{
                        taskId,
                        recordIdentifier: recordInformation.recordIdentifier,
                        classId: recordInformation.objectClassId,
                        meta: meta,
                        userTypeFields: {
                          searchBarBehavior:
                            task?.status === TaskStatus.Completed
                              ? SearchBarDisplay.Never
                              : SearchBarDisplay.Default,
                        },
                        fieldWorkspace: FieldSelectionWorkspace.EditRecordForm,
                      }}
                    />
                  )
                }
              </div>
            </div>
            <div className={classes.formFooter}>
              <div
                className={clsx(classes.formFooterSection, {
                  [classes.sectionDivider]: !readOnly,
                })}
              >
                <ButtonSecondaryOutlined
                  icon={<CloseIcon size={18} />}
                  data-testid={CANCEL_TASK_FORM_BUTTON}
                  onClick={() => onCancelClick?.(false, true)}
                >
                  {cancelButtonContent || (
                    <FormattedMessage
                      id='misc.cancel'
                      defaultMessage='Cancel'
                    />
                  )}
                </ButtonSecondaryOutlined>
                {!readOnly && (
                  <ButtonPrimary
                    onClick={() => handleClick(true)}
                    disabled={
                      !isFormTouched || isUploadingInProgress || isSaving
                    }
                    data-testid={SAVE_PROGRESS_TASK_FORM_BUTTON}
                  >
                    <FormattedMessage
                      id='misc.saveAndClose'
                      defaultMessage='Save and close'
                    />
                  </ButtonPrimary>
                )}
              </div>
              {!readOnly && (
                <div className={classes.formFooterSection}>
                  <ButtonTertiary
                    onClick={() => handleClick(false)}
                    disabled={hasErrors || isSaving || isUploadingInProgress}
                    data-testid={COMPLETE_TASK_FORM_BUTTON}
                  >
                    <FormattedMessage
                      id='misc.complete'
                      defaultMessage='Complete'
                    />
                  </ButtonTertiary>
                </div>
              )}
            </div>
            {/* 
          additional condition to prevent modal flickering. 
          Flickering used to occur on switching between tasks, because under `task` variable
          there was data of previously selected one, error403 was already set, and loading wasn't launched yet,
          */}
            {!isLoading && (
              <NoPermissionModal
                visible={shouldNoPermissionBeDisplayed}
                onConfirm={onConfirmNoPermissionModal}
                subtitle={noPermissionModalSubtitle}
                title={noPermissionModalTitle}
              />
            )}
            <TaskOwnershipContextConsumer>
              {({
                shouldBeConfirmationDisplayed,
                shouldBeAssignOwnerDisplayed,
                setShouldBeConfirmationDisplayed,
                onConfirmCallback,
                title,
                subtitle,
                confirmLabel,
                cancelLabel,
              }) => (
                <>
                  <CancelConfigurationModal
                    visible={shouldBeConfirmationDisplayed}
                    customTitle={title}
                    customConfirmationLabel={confirmLabel}
                    customCancelLabel={cancelLabel}
                    customSubtitle={subtitle}
                    onConfirm={onConfirmCallback}
                    onCancel={() => setShouldBeConfirmationDisplayed(false)}
                  />
                  {shouldBeAssignOwnerDisplayed && (
                    <AssignOwnerModal
                      owner={stages?.[0]?.owner}
                      taskId={taskId}
                      stageId={stages?.[0]?.name}
                    />
                  )}
                </>
              )}
            </TaskOwnershipContextConsumer>
          </TaskOwnershipContextProvider>
        </>
      ) : (
        // adding this div makes loader position properly when
        // no task has been loaded yet
        <div className={classes.formContent} />
      )}
      <PreventRedirects when={!!(isFormTouched && formHasUnsavedChanges)} />
    </Loader>
  );
};

export default TaskDisplay;
