export const getAvatarInitials = (
  firstName = '',
  lastName = '',
  groupName = '',
  isDeleted = false
) => {
  if (isDeleted) {
    return 'D';
  }

  if (groupName) {
    const userGroupWords = groupName.split(' ');
    const groupInitials =
      userGroupWords.length > 1
        ? `${userGroupWords[0].charAt(0)}${userGroupWords[1].charAt(0)}`
        : `${userGroupWords[0].substring(0, 2)}`;

    return groupInitials.toUpperCase();
  }

  return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
};
