import React from 'react';
import { useIntl } from 'react-intl';
import { AddGroupAssigneesPanelViewProps } from './AddGroupAssigneesPanelView.types';
import { ADD_GROUP_ASSIGNEES_TESTID } from 'utils/testIds';
import { useAddGroupAssigneesPanelViewStyles } from './AddGroupAssigneesPanelView.styles';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import GroupListElement from 'pages/UserGroups/components/Permissions/GroupListElement';
import AutocompleteUserGroupsSelect from 'components/AutocompleteUsersGroupsSelect';
import { OptionGroupAvatar } from 'components/OptionAvatars/OptionGroupAvatar';
import { useBeforeunload } from 'react-beforeunload';
import CancelConfigurationModal from 'components/CancelConfigurationModal';
import CustomPrompt from 'components/CustomPrompt';
import { CustomPromptType } from 'components/CustomPrompt/types';
import { Alert } from 'components/lib/Alert';
import { useGroupAssigneesSelection } from './hooks/useGroupAssigneesSelection';
import { useAddGroupAssignees } from './hooks/useAddGroupAssignees';
import { useGroupAssigneeesLimit } from './hooks/useGroupAssigneeesLimit';
import { useAddAssigneesCancellation } from 'components/AddAssigneesPanelView/hooks/useAddAssigneesCancellation';
import { AddAssigneesPanelViewFooter } from 'components/AddAssigneesPanelView/components/AddAssigneesPanelViewFooter';
import { AddAssigneesPanelViewHeader } from 'components/AddAssigneesPanelView/components/AddAssigneesPanelViewHeader';

/**
 * It's a component dedicated to use in panels that allows assiging groups for a given resource
 * e.g. object class permission set.
 */
export const AddGroupAssigneesPanelView = ({
  panelKey,
  resourceName,
  assignedGroupIds,
  autocompleteEndpoint,
  postEndpoint,
  additionalContextText,
  groupsLimit,
  changePanelView,
  refetchGroupAssignees,
}: AddGroupAssigneesPanelViewProps) => {
  const intl = useIntl();
  const styles = useAddGroupAssigneesPanelViewStyles();

  const {
    showUnsavedChangesModal,
    navigateTo,
    handleCustomPromptCancelBtn,
    handlePanelCancelBtn,
    handleUnsavedChangesModalConfirmBtn,
    handleUnsavedChangesModalCancelBtn,
    updatePanelCloseBtnBehavior,
  } = useAddAssigneesCancellation(panelKey, changePanelView);
  const {
    selectedGroups,
    hasSelectedGroups,
    getIsGroupDisabled,
    getDisabledGroupText,
    handleGroupSelect,
    handleGroupRemove,
  } = useGroupAssigneesSelection(assignedGroupIds, updatePanelCloseBtnBehavior);
  const { isSubmitting, handleSubmit } = useAddGroupAssignees(
    selectedGroups,
    postEndpoint,
    resourceName,
    groupsLimit,
    changePanelView,
    refetchGroupAssignees
  );
  const { isLimitReached, limitReachedText } = useGroupAssigneeesLimit(
    groupsLimit,
    selectedGroups.length,
    assignedGroupIds.length
  );

  const title = intl.formatMessage(
    {
      id: 'misc.assignUserGroupsTo',
      defaultMessage: 'Assign user groups to {name}',
    },
    { name: resourceName }
  );
  const counterText = intl.formatMessage(
    {
      id: 'misc.groupsSelected',
      defaultMessage:
        '{count, plural, one {# user group selected} other {# user groups selected}}',
    },
    { count: selectedGroups.length }
  );
  const emptyDataDescription = intl.formatMessage({
    id: 'misc.noUserGroupsSelected',
    defaultMessage: 'No user groups selected',
  });

  // Prevent e.g. refreshing or closing the page when user has selected some groups, i.e. the form is dirty
  useBeforeunload(event => hasSelectedGroups && event.preventDefault());

  return (
    <>
      <div
        className={styles.mainWrapper}
        data-testid={ADD_GROUP_ASSIGNEES_TESTID}
      >
        <AddAssigneesPanelViewHeader
          counterText={counterText}
          title={title}
          additionalContextText={additionalContextText}
        />

        <div className={styles.contentWrapper}>
          {isLimitReached ? (
            <Alert message={limitReachedText} type='info' />
          ) : (
            <AutocompleteUserGroupsSelect
              autocompleteUrl={autocompleteEndpoint}
              selectMultiple
              onChange={handleGroupSelect}
              checkOptionIsDisabled={getIsGroupDisabled}
              renderOption={(option, searchValue) => (
                <OptionGroupAvatar
                  option={option}
                  searchValue={searchValue}
                  disabledText={getDisabledGroupText(option.id)}
                  showUserCount
                />
              )}
            />
          )}

          <List<AvatarItem>
            className={styles.itemList}
            items={selectedGroups}
            emptyDataDescription={emptyDataDescription}
            renderItem={item => (
              <GroupListElement
                {...item}
                onDelete={() => handleGroupRemove(item.id)}
              />
            )}
          />
        </div>

        <AddAssigneesPanelViewFooter
          hasSelectedItems={hasSelectedGroups}
          isSubmitting={isSubmitting}
          onAddBtnClick={handleSubmit}
          onCancelBtnClick={() => handlePanelCancelBtn(hasSelectedGroups)}
        />
      </div>

      <CancelConfigurationModal
        visible={showUnsavedChangesModal}
        onCancel={handleUnsavedChangesModalCancelBtn}
        onConfirm={handleUnsavedChangesModalConfirmBtn}
      />
      <CustomPrompt
        when={!navigateTo && hasSelectedGroups}
        type={CustomPromptType.CANCEL}
        onCancel={handleCustomPromptCancelBtn}
      />
    </>
  );
};
