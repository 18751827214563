import { ClassFieldFormFields } from 'pages/ObjectClasses/enums';
import { ClassFieldForm } from '../../../components/ClassFieldForm/types';
import { getValueIfAnyElementInArray } from 'utils/functions/getValueIfAnyElementInArray';
import { SetStateAction } from 'react';
import { showErrorToast } from 'features/toasts/utils/showErrorToast';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { AxiosError } from 'axios';

export const handleNoPermissionsToSelectUserError = async (
  error: AxiosError,
  formData: ClassFieldForm,
  setValues: (
    values: SetStateAction<ClassFieldForm>,
    shouldValidate?: boolean
  ) => void
) => {
  const selectedUsers = formData.users ?? [];
  const selectedGroups = formData.user_groups ?? [];
  const errorMessage: string = error.response?.data?.options?.users?.[0] ?? '';
  const unavailableUserId = errorMessage.match(/\d+/)?.[0];

  const availableUsers = selectedUsers.filter(user => {
    return user.id.toString() !== unavailableUserId;
  });
  const availableUsersAndGroups = [...selectedGroups, ...availableUsers];

  setValues(
    {
      ...formData,
      [ClassFieldFormFields.Users]: availableUsers,
      [ClassFieldFormFields.MinUsersValues]: getValueIfAnyElementInArray(
        availableUsersAndGroups,
        formData.min_users_values
      ),
      [ClassFieldFormFields.MaxUsersValues]: getValueIfAnyElementInArray(
        availableUsersAndGroups,
        formData.max_users_values
      ),
    },
    true
  );
  showErrorToast({
    title: GlobalIntlSingleton.intl.formatMessage({
      id: 'misc.error',
      defaultMessage: 'Error!',
    }),
    subtitle: GlobalIntlSingleton.intl.formatMessage({
      id: 'errors.insufficientPermissionToAssignUsers',
      defaultMessage:
        'Insufficient permission to assign some of the users. Please review the user selection.',
    }),
  });
};
