import { SchemaComponentProperty } from 'components/formBuilder/types/schemaComponentProperty';
import { ExtendedJsonSchema } from 'components/formBuilder/types/extendedJsonSchema';
import { MigrationFunction } from '../types';
import {
  ChildClassPassThroughParams,
  PassThroughParams,
} from 'components/formBuilder/formBuilder/types';

/*
This migration function changes every child class property passThrough object to have a childClassParams object
*/
const migrationToV6: MigrationFunction = ({ schema, uiSchema }) => {
  const migratedSchema = migrateSchema(schema);

  return { schema: migratedSchema, uiSchema };
};

const migrateSchema = (schema: ExtendedJsonSchema): ExtendedJsonSchema => {
  const sectionsEntries = Object.entries(schema.properties || {});

  const migratedSectionEntries = sectionsEntries.map(
    ([sectionAlias, section]) => {
      const updatedSectionPropertiesEntries = Object.entries(
        section?.properties || {}
      ).map(([propertyAlias, property]) => {
        //move passThrough params to its own object - childClassParams
        const oldVersionPassThroughParams = (property as {
          passThrough: ChildClassPassThroughParams;
        })?.passThrough;

        if (
          propertyAlias.includes('child_class_') &&
          oldVersionPassThroughParams &&
          !('childClassParams' in oldVersionPassThroughParams)
        ) {
          return [
            propertyAlias,
            {
              ...property,
              passThrough: {
                childClassParams: {
                  childClassID: oldVersionPassThroughParams.childClassID,
                  childClassColumns:
                    oldVersionPassThroughParams.childClassColumns,
                },
                externalComponentParams: {
                  ...(property as {
                    passThrough: PassThroughParams;
                  }).passThrough.externalComponentParams,
                },
              },
            } as SchemaComponentProperty,
          ];
        }
        return [propertyAlias, property];
      });

      const migratedSection = {
        ...section,
        properties: Object.fromEntries(updatedSectionPropertiesEntries),
      };

      return [sectionAlias, migratedSection];
    }
  );

  const migratedSchema = {
    ...schema,
    schemaVersion: 6,
    properties: Object.fromEntries(migratedSectionEntries),
  };

  return migratedSchema;
};

export default migrationToV6;
