import { DynamicExtendedSchema } from 'components/formBuilder/formBuilder/types';
import useDynamicSchema from 'components/formBuilder/hooks/useDynamicSchema';
import migrateFormDefinitionToSupportedVersion from 'components/formBuilder/migrateDefinition';
import { ExtendedUiSchema } from 'components/formBuilder/types/extendedUiSchema';
import { applyFilesMetaToFormFields } from 'components/FormPreview2/utils';
import useData from 'hooks/useData';
import { StatusCodes } from 'http-status-codes';
import { TaskTypes } from 'pages/TaskTemplates/enums';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { setTaskAssignees } from 'store/actions/tasksAssigneesActions';
import {
  OBJECT_RECORD_DETAILS,
  TASKS_DETAILS,
  TASK_FIELDS,
} from 'utils/endpoints';
import { getErrorStatus } from 'utils/functions/getErrorStatus';
import routes from 'utils/routingPaths';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import { NextTaskAction, TaskDetails } from 'utils/types/api/tasks.types';
import { CheckTaskPermission } from './types';

const useTask = (
  taskId: string | number,
  readOnly: boolean,
  setShouldNoPermissionBeDisplayed: Dispatch<SetStateAction<boolean>>,
  checkPermission?: CheckTaskPermission
) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [
    task,
    { loading: isTaskLoading, error: taskError, fetchData: fetchTaskData },
  ] = useData<TaskDetails>(generatePath(TASKS_DETAILS, { id: taskId }), {
    fetchOnLoad: false,
  });

  const {
    configuration: {
      plugin_config: {
        configuration: { data_schema: dataSchema, ui_schema } = {},
        task_type: taskType,
      } = {},
    } = {},
    status,
    object_record: recordId,
    name: taskName,
    instructions,
    response,
    _meta: { permissions } = {},
  } = task ?? ({} as TaskDetails);

  const [
    recordData,
    { loading: isRecordLoading, fetchData: fetchRecordData },
  ] = useData<ObjectRecordDetails>(
    generatePath(OBJECT_RECORD_DETAILS, { id: recordId ?? 0 }),
    {
      fetchOnLoad: false,
    }
  );

  useEffect(() => {
    fetchTaskData();
  }, [taskId, fetchTaskData]);

  useEffect(() => {
    if (isTaskLoading || !task) {
      return;
    }

    dispatch(
      setTaskAssignees({ stages: task.stages, taskId: taskId.toString() })
    );
  }, [task, isTaskLoading, dispatch, taskId]);

  useEffect(() => {
    if (!recordId) {
      return;
    }

    fetchRecordData();
  }, [fetchRecordData, recordId]);

  useEffect(() => {
    const completeWithoutPermission =
      !readOnly &&
      task?._meta.permissions.complete === false &&
      !checkPermission; //checkPermission === undefined indicates that user opened the task page by url

    const viewWithoutPermission =
      readOnly && task?._meta.permissions.view === false;

    const hasNoCompleteAndViewPermission =
      task?._meta.permissions.complete === false &&
      task?._meta.permissions.view === false;

    if (
      !isTaskLoading &&
      ([StatusCodes.FORBIDDEN, StatusCodes.NOT_FOUND].includes(
        taskError?.status as StatusCodes
      ) ||
        completeWithoutPermission ||
        viewWithoutPermission ||
        hasNoCompleteAndViewPermission)
    ) {
      setShouldNoPermissionBeDisplayed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, taskError, isTaskLoading, readOnly]);

  const accessErrors = {
    view: getErrorStatus(taskError?.status, permissions, 'view'),
    complete: getErrorStatus(taskError?.status, permissions, 'complete'),
  };
  const { schema, uiSchema, loading: isSchemaLoading } = useDynamicSchema(
    dataSchema as DynamicExtendedSchema,
    ui_schema as ExtendedUiSchema,
    task?.configuration?.plugin_config?.object_class,
    generatePath(TASK_FIELDS, { id: taskId })
  );

  useEffect(() => {
    //checkPermission === undefined indicates that user opened the task page by url
    if (
      checkPermission === CheckTaskPermission.Complete &&
      (permissions?.complete === false ||
        (task?.next_actions &&
          !task?.next_actions.includes(NextTaskAction.Complete)))
    ) {
      history.replace(generatePath(routes.TASK_VIEW, { id: taskId }));
    }
  }, [task, checkPermission, history, taskId, permissions]);

  const { supportedSchema, supportedUiSchema, migrationError } = useMemo(() => {
    const {
      schema: supportedSchema,
      uiSchema: supportedUiSchema,
      migrationError,
    } = migrateFormDefinitionToSupportedVersion(
      JSON.stringify(taskType === TaskTypes.RecordUpdate ? schema : dataSchema),
      JSON.stringify(taskType === TaskTypes.RecordUpdate ? uiSchema : ui_schema)
    );

    return {
      supportedSchema,
      supportedUiSchema,
      migrationError,
    };
  }, [schema, uiSchema, dataSchema, ui_schema, taskType]);

  const initialValues = useMemo(() => {
    const filesData = applyFilesMetaToFormFields({
      uiSchema: supportedUiSchema,
      formData: response?.files || {},
      filesMeta: {
        ...(response?._meta?.labels?.files ?? {}),
        ...(recordData?._meta.labels.files ?? {}), //include files from object record when task is of type upadate object record
      },
    });

    return {
      ...response?.data,
      ...filesData,
    };
  }, [recordData, response, supportedUiSchema]);

  return {
    isLoading: isTaskLoading || isRecordLoading || isSchemaLoading,
    error: taskError,
    accessErrors,
    fetchData: fetchTaskData,
    task,
    recordInformation: {
      recordId,
      recordIdentifier: recordData?.object_name ?? recordId,
      objectClassId: recordData?.object_class,
    },
    taskName,
    schema: supportedSchema,
    uiSchema: supportedUiSchema,
    status,
    instructions,
    initialValues,
    migrationError,
    meta: response?._meta,
  };
};

export default useTask;
