import React from 'react';
import { AvatarGroup } from 'components/AvatarGroup';
import InPlaceEditLabel from 'components/InPlaceEditControls/components/InPlaceEditLabel';
import useInPlaceEditTextInputStyles from 'components/InPlaceEditControls/components/InPlaceEditWrapper/styles';
import { OwnersFieldPreviewProps } from './types';
import HiddenMessage from './HiddenMessage';

const OwnersAvatarsList: React.FC<OwnersFieldPreviewProps> = ({
  value = [],
  label,
  required,
  size,
  hideOwners = false,
}) => {
  const classes = useInPlaceEditTextInputStyles({});
  const noValueLabel = hideOwners ? <HiddenMessage /> : '-';

  return (
    <div className={classes.previewWrapper}>
      {!!label && <InPlaceEditLabel {...{ label, required, size }} />}
      {value.length ? <AvatarGroup items={value} /> : noValueLabel}
    </div>
  );
};

export default OwnersAvatarsList;
