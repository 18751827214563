import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useAutologyxInformationStyles = createUseStyles(
  (theme: ThemeState) => ({
    mainWrapper: {
      display: 'grid',
      placeItems: 'center',

      width: '100%',
      height: '100vh',

      background: 'linear-gradient(270deg, #01042B 6.67%, #000001 87.6%)',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',

      width: '75%',
    },
    title: {
      margin: 0,

      fontSize: '3rem',
      color: theme['white-color'],
      lineHeight: '1',
      letterSpacing: '2px',
    },
    subtitle: {
      fontSize: '1.25rem',
      color: theme['white-color'],
      lineHeight: '1.35',
    },
  })
);
