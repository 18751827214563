import ActionMenu from 'components/ActionMenu';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';
import { EditIcon } from 'components/Icon';
import { ButtonOutlined, ButtonTertiary } from 'components/lib/Button';
import { TASK_COMPLETE_FORM_MODAL_ID } from 'components/MultiPageComponents/consts';
import TaskStatusTag from 'components/TaskStatusTag';
import { UserAvatar } from 'components/UserAvatar';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import useDateFormat from 'hooks/useDateFormat';
import moment from 'moment';
import React, { useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { selectTask } from 'store/actions/taskActions';
import { SCROLLABLE_TASKS_LIST_ID, SIDE_PANEL } from 'utils/elementsIds';
import { TaskStatus } from 'utils/Enums/TaskStatus';
import {
  COMPLETE_TASK_BUTTON,
  TASKS_LIST_ITEM,
  TASK_ACTION_MENU,
  TASK_COMPLETED_BY,
  TASK_COMPLETE_DATE,
  VIEW_TASK_BUTTON,
} from 'utils/testIds';
import { TasksPanelMode } from '../../../../types';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { useTaskItemStyles } from './TaskItem.styles';
import { TaskItemProps } from './TaskItem.types';

export const TaskItem = ({
  task,
  task: {
    name,
    due_date: dueDate,
    status,
    id,
    object_record: objectRecord,
    _meta: { permissions },
    completed_by: completer,
    completed_at: completedDate,
    current_owner: owner,
  },
  isSelected,
  refetchTasksList,
  tasksListRef,
}: TaskItemProps) => {
  const intl = useIntl();
  const styles = useTaskItemStyles({ isSelected });
  const dispatch = useDispatch();

  const { dateFormat } = useDateFormat();
  const { setMode, setTask } = useTasksPanelContext();
  const {
    setStoredModalFunctions,
    shouldBeDisplayed,
    setShouldBeDisplayed,
  } = useConfirmationModalContext();

  const dispatchSelectTask = useCallback(
    (isView: boolean) => {
      dispatch(
        selectTask({
          id: id.toString(),
          name,
          objectRecord: objectRecord.toString(),
          readOnly: isView,
          refetchTasksList,
        })
      );
    },
    [dispatch, id, name, objectRecord, refetchTasksList]
  );

  const onOptionClick = useCallback(
    (isView: boolean) => {
      if (shouldBeDisplayed?.[TASK_COMPLETE_FORM_MODAL_ID]) {
        setStoredModalFunctions(TASK_COMPLETE_FORM_MODAL_ID, {
          onSubmit: () => {
            dispatchSelectTask(isView);
            setShouldBeDisplayed(TASK_COMPLETE_FORM_MODAL_ID, false);
            setStoredModalFunctions(TASK_COMPLETE_FORM_MODAL_ID, {});
          },
          callback: () =>
            setStoredModalFunctions(TASK_COMPLETE_FORM_MODAL_ID, {}),
        });
      } else {
        dispatchSelectTask(isView);
      }
    },
    [
      dispatchSelectTask,
      setShouldBeDisplayed,
      setStoredModalFunctions,
      shouldBeDisplayed,
    ]
  );

  const menuItems = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'misc.reassignTask',
          defaultMessage: 'Reassign task',
        }),
        action: () => {
          setMode(TasksPanelMode.Reassign);
          setTask(task);
        },
        icon: <EditIcon size={18} />,
        hasPermission: permissions?.assign && status === TaskStatus.Outstanding,
      },
    ],
    [intl, permissions, setMode, setTask, status, task]
  );

  const hasItems =
    menuItems.filter(({ hasPermission }) => hasPermission).length > 0;

  return (
    <div data-testid={TASKS_LIST_ITEM} className={styles.wrapper}>
      <div className={styles.leftCol}>
        <div className={styles.taskNameWrapper}>
          <p className={styles.taskName}>{name}</p>
          {hasItems && (
            <ActionMenu
              overlay={<ActionMenuList items={menuItems} />}
              shouldOverlayBeClosedOnClick
              withOutsideClick
              testId={TASK_ACTION_MENU}
              getPopupContainer={() =>
                tasksListRef?.current ||
                document.getElementById(SCROLLABLE_TASKS_LIST_ID) ||
                document.getElementById(SIDE_PANEL) ||
                document.body
              }
            />
          )}
        </div>
        <span className={styles.dateWrapper} data-testid={TASK_COMPLETE_DATE}>
          {status === TaskStatus.Completed && completedDate ? (
            <FormattedMessage
              id='tasksPanel.completedWithDate'
              defaultMessage='Completed: {date}'
              values={{
                date: moment(completedDate).format(dateFormat),
              }}
            />
          ) : (
            <FormattedMessage
              id='tasksPanel.dueDate'
              defaultMessage='Due: {date}'
              values={{
                date: !!dueDate ? moment(dueDate).format(dateFormat) : '-',
              }}
            />
          )}
        </span>
        <TaskStatusTag {...{ status }} />
      </div>
      <div className={styles.rightCol}>
        <div>
          {permissions.complete && status !== TaskStatus.Completed ? (
            <ButtonTertiary
              onClick={() => onOptionClick(false)}
              data-testid={COMPLETE_TASK_BUTTON}
              className={styles.button}
            >
              <FormattedMessage id='misc.complete' defaultMessage='Complete' />
            </ButtonTertiary>
          ) : (
            permissions.view && (
              <ButtonOutlined
                onClick={() => onOptionClick(true)}
                data-testid={VIEW_TASK_BUTTON}
              >
                <FormattedMessage id='misc.viewIt' defaultMessage='View' />
              </ButtonOutlined>
            )
          )}
        </div>
        <div className={styles.completeSection} data-testid={TASK_COMPLETED_BY}>
          {!!completer && (
            <>
              <div className={styles.label}>
                <FormattedMessage
                  id='tasksPanel.completedBy'
                  defaultMessage='Completed by:'
                />
              </div>
              <UserAvatar
                size='small'
                id={completer.id}
                firstName={completer.first_name}
                lastName={completer.last_name}
                isDeleted={completer.is_deleted}
                scrollableParentId={SCROLLABLE_TASKS_LIST_ID}
              />
            </>
          )}
          {status !== TaskStatus.Completed && !!owner && (
            <>
              <div className={styles.label}>
                <FormattedMessage
                  id='tasksPanel.taskOwner'
                  defaultMessage='Task owner:'
                />
              </div>
              <UserAvatar
                size='small'
                id={owner.id}
                firstName={owner.first_name}
                lastName={owner.last_name}
                isDeleted={owner.is_deleted}
                scrollableParentId={SCROLLABLE_TASKS_LIST_ID}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
