import { useCallback, useEffect } from 'react';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { useUserFieldMinErrorMessages } from 'components/UsersAndGroupsSelection/hooks/useUserFieldMinErrorMessages';
import { useValidateFulfillment } from 'components/UsersAndGroupsSelection/hooks/useValidateFulfillment';
import {
  SelectUserAndGroupOption,
  UsersAndGroupsSelectLimits,
} from 'utils/types/selectInput.types';
import { getEligibleUserOptions } from 'components/UsersAndGroupsSelection/utils/getEligibleUserOptions';
import { FieldSelectionWorkspace } from 'components/FormPreview2/types/fieldSelectionWorkspace';

export const useCustomUserWidgetValidation = (
  fieldLabel: string,
  errors: string[],
  limits: UsersAndGroupsSelectLimits,
  fieldWorkspace: FieldSelectionWorkspace,
  fulfillmentFormErroredFields?: Set<string>
) => {
  const { minGroupsError, minUsersError } = useUserFieldMinErrorMessages(
    fieldLabel,
    limits.selectionCountLimits?.minUsers,
    limits.selectionCountLimits?.minGroups
  );

  const {
    tooltip,
    fulfillmentErrors,
    isDisabledByFulfillment,
    validateFulfillment,
    evaluateFieldAgainstFormFulfillmentValidation,
  } = useValidateFulfillment(
    fieldLabel,
    fieldWorkspace,
    fulfillmentFormErroredFields
  );

  const validateFulfillmentPossibility = useCallback(
    (
      fieldValue: UsersAndGroupsFormValue | null | undefined,
      options: SelectUserAndGroupOption,
      isMinUsersFulfilled: boolean
    ) => {
      validateFulfillment(
        fieldValue,
        {
          users: getEligibleUserOptions(options.users, fieldValue),
          groups: options.groups,
          isRequired: limits.isFieldRequired,
          limits,
        },
        isMinUsersFulfilled
      );
    },
    [limits, validateFulfillment]
  );

  useEffect(() => {
    evaluateFieldAgainstFormFulfillmentValidation();
  }, [evaluateFieldAgainstFormFulfillmentValidation]);

  return {
    validateFulfillmentPossibility,
    minUsersError,
    minGroupsError,
    tooltip,
    errors: [...fulfillmentErrors, ...errors],
    isDisabledByFulfillment,
    shouldDisableDueToValidation: isDisabledByFulfillment,
  };
};
