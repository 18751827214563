import React from 'react';
import { useAuthLayoutFooterStyles } from './AuthLayoutFooter.styles';
import { useIntl } from 'react-intl';
import { AuthLayoutFooterProps } from './AuthLayoutFooter.types';
import clsx from 'clsx';

export const AuthLayoutFooter = ({ className }: AuthLayoutFooterProps) => {
  const intl = useIntl();
  const styles = useAuthLayoutFooterStyles();

  const text = intl.formatMessage(
    {
      id: 'misc.copyRight',
      defaultMessage: '{copy} {year} autologyx.com All rights reserved.',
    },
    {
      copy: <span>&copy;</span>,
      year: new Date().getFullYear(),
    }
  );

  return (
    <footer className={clsx(styles.wrapper, className)}>
      <span>{text}</span>
    </footer>
  );
};
