/**
 * Specified in what kind of context the field is being used.
 */
export enum FieldSelectionWorkspace {
  /**
   * The select is used in a context where the user creates a record from scratch.
   */
  CreateRecord = 'create-record',
  /**
   * The user is editing an existing record "in place" meaning that the changes will get commited as soon as the field will loose focus.
   */
  InPlaceEditRecord = 'in-place-edit-record',
  /**
   * The user is editing an existing record and the provided changes won't get commited until the final form is saved.
   */
  EditRecordForm = 'edit-record-form',
}
