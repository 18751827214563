import { ColumnDefinition } from 'components/formBuilder/types/columnDefinition';
import { createUiColumnContentSchema } from './createUiColumnContentSchema';

export const createUiColumnSchema = (
  sectionAlias: string,
  sectionContentKey: string,
  sectionContentValue: unknown,
  fieldsInDataSchema: {
    [key: string]: string[];
  }
) => {
  const column = sectionContentValue as ColumnDefinition[];

  return [
    sectionContentKey,
    column.map((columnDefinition: ColumnDefinition) =>
      createUiColumnContentSchema(
        sectionAlias,
        columnDefinition,
        fieldsInDataSchema
      )
    ),
  ];
};
