import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import { UserAvatarData } from 'hooks/avatars/types';
import { sortUserAvatars } from 'utils/functions/sortUserAvatars';

/**
 * Creates user avatars from provided user data.
 *
 * @param users Users to transform.
 *
 * @returns Alphatecially sorted list of user avatars.
 */
export const createUserAvatars = (users: UserAvatarData[]): AvatarItem[] => {
  const userAvatars: AvatarItem[] = users.map(user => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    accountType: user.accountType,
    isDeleted: user.isDeleted,
  }));

  return sortUserAvatars(userAvatars);
};
