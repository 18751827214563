import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { ButtonPrimary } from 'components/lib/Button';
import TextInput from 'components/Inputs/TextInput';
import ErrorIcon from 'img/icons/exclamation-mark.svg';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import {
  BUTTON_SUBMIT_TESTID,
  FORGOT_PASSWORD_SUBTITLE_TESTID,
  FORGOT_PASSWORD_TITLE_TESTID,
} from 'utils/testIds';
import { useForgotPasswordForm } from './hooks/useForgotPasswordForm';
import { useForgotPasswordFormStyles } from './ForgotPasswordForm.styles';
import { ForgotPasswordFormProps } from './ForgotPasswordForm.types';
import { ForgotPasswordFormView } from './types/forgotPasswordFormView';
import { AccountLockedInfo } from 'components/AccountLockedInfo';
import { RequestSentInfo } from 'components/RequestSentInfo';

export const ForgotPasswordForm = ({
  customTitle,
  customSubtitle,
  customButtonLabel,
  showErrorIcon = false,
}: ForgotPasswordFormProps) => {
  const intl = useIntl();
  const styles = useForgotPasswordFormStyles();

  const {
    formView,
    isSubmitting,
    initialValues,
    lockoutEndDate,
    validationSchema,
    rawValidationSchema,
    onSubmit,
  } = useForgotPasswordForm();

  if (formView === ForgotPasswordFormView.RequestSent) {
    return (
      <RequestSentInfo
        text={intl.formatMessage(
          {
            id: 'resetPassword.accountExists',
            defaultMessage:
              'If an account exists for {email}, we’ll send an email with a link to reset your password.',
          },
          { email: <b>{initialValues.email}</b> }
        )}
      />
    );
  }

  return (
    <>
      {showErrorIcon && (
        <div className={styles.errorIconWrapper}>
          <img src={ErrorIcon} alt='Error icon' className={styles.errorIcon} />
        </div>
      )}

      {formView === ForgotPasswordFormView.AccountLocked ? (
        <AccountLockedInfo lockoutEndDate={lockoutEndDate ?? ''} />
      ) : (
        <>
          <p
            className={styles.title}
            data-testid={FORGOT_PASSWORD_TITLE_TESTID}
          >
            {customTitle ?? (
              <FormattedMessage
                id='resetPassword.title'
                defaultMessage='Forgotten your password?'
              />
            )}
          </p>

          <p
            className={styles.subtitle}
            data-testid={FORGOT_PASSWORD_SUBTITLE_TESTID}
          >
            {customSubtitle ?? (
              <FormattedMessage
                id='resetPassword.subtitle'
                defaultMessage='Enter your email address to reset your password. We will send a link to the e-mail address provided, from which you will be able to reset your password.'
              />
            )}
          </p>
        </>
      )}

      <Formik {...{ initialValues, onSubmit, validationSchema }}>
        {({ values, errors }) => (
          <Form>
            <FormLabel className={styles.input}>
              <FormattedMessage
                id='activateAccount.username'
                defaultMessage='Username'
              />
            </FormLabel>
            <TextInput
              name='email'
              type='email'
              maxLength={rawValidationSchema.email.max_length}
            />
            <span className={styles.description}>
              <FormattedMessage
                id='users.usernameHint'
                defaultMessage='This must be valid email'
              />
            </span>
            <div className={styles.buttonWrapper}>
              <ButtonPrimary
                loading={isSubmitting}
                htmlType='submit'
                disabled={Object.keys(errors).length > 0 || !values.email}
                data-testid={BUTTON_SUBMIT_TESTID}
              >
                {customButtonLabel ?? (
                  <FormattedMessage id='misc.reset' defaultMessage='Reset' />
                )}
              </ButtonPrimary>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
