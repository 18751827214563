import React from 'react';
import { UserAvatar } from 'components/UserAvatar';
import { AVATARS_GROUP_REST_ITEMS } from 'utils/testIds';
import { evaluateDisplayName } from './utils/evaluateDisplayName';
import { useAvatarGroupPopoverStyles } from './AvatarGroupPopover.styles';
import { AvatarGroupPopoverProps } from './AvatarGroupPopover.types';

export const AvatarGroupPopover = ({
  items,
  nonInteractiveAvatars,
  customDetailsEndpoint,
}: AvatarGroupPopoverProps) => {
  const styles = useAvatarGroupPopoverStyles();

  return (
    <div
      className={styles.wrapper}
      id={AVATARS_GROUP_REST_ITEMS}
      data-testid={AVATARS_GROUP_REST_ITEMS}
      onScroll={event => event.stopPropagation()}
    >
      {items.map(item => {
        return item.id ? (
          <div
            key={item.userGroup ? `group-${item.id}` : `user-${item.id}`}
            className={styles.item}
          >
            <UserAvatar
              id={item.id}
              userGroup={item.userGroup}
              firstName={item.firstName}
              lastName={item.lastName}
              disablePopover={nonInteractiveAvatars}
              accountType={item.accountType}
              isDeleted={item.isDeleted}
              size='small'
              closeOnElementScroll={AVATARS_GROUP_REST_ITEMS}
              customDetailsEndpoint={customDetailsEndpoint}
            />

            <p className={styles.displayName}>{evaluateDisplayName(item)}</p>
          </div>
        ) : null;
      })}
    </div>
  );
};
