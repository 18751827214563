import { createUseStyles } from 'react-jss';

export const useResetPasswordPageStyles = createUseStyles({
  notFoundWrapper: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  },
  passwordRules: {
    marginTop: '16px',
    padding: 0,
    maringBottom: 0,
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    width: '100%',
    marginTop: '32px',
  },
  title: {
    fontSize: '1.25rem',
    textAlign: 'center',
    marginBottom: '32px',
    fontWeight: 700,
  },
});
