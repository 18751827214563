import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { usePostWithToasts } from 'hooks/usePostWithToasts';
import { AuthState } from 'utils/types/api/auth.types';
import { FormMode } from 'utils/Enums/FormMode';
import { useState } from 'react';
import useValidationSchemaBuilder from 'hooks/useValidationSchemaBuilder';
import { OptionSelect } from 'utils/types/selectInput.types';
import { setTokens } from 'store/actions/authActions';
import routes from 'utils/routingPaths';
import { FormikHelpers } from 'formik';
import { RESET_PASSWORD } from 'utils/endpoints';
import { ResetPasswordFormField } from '../types/resetPasswordFormField';
import { RESET_PASSWORD_FORM_VALIDATION } from '../consts/resetPasswordFormValidation';
import { ResetPasswordPageParams } from '../types/resetPasswordPageParams';
import { ResetPasswordForm } from '../types/resetPasswordForm';

export const useResetPasswordForm = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useParams<ResetPasswordPageParams>();
  const { sendData } = usePostWithToasts<AuthState, AuthState>(FormMode.Create);
  const [isPasswordFulfilled, setIsPasswordFulfilled] = useState(false);
  const validationBuilder = useValidationSchemaBuilder<
    OptionSelect | undefined
  >(RESET_PASSWORD_FORM_VALIDATION);
  const [initialValues] = useState({
    [ResetPasswordFormField.Password]: '',
    [ResetPasswordFormField.ConfirmPassword]: '',
  });

  const callback = (data?: AuthState) => {
    if (data) dispatch(setTokens(data));

    history.push(routes.HOMEPAGE);
  };

  const onSubmit = async (
    values: ResetPasswordForm,
    {
      setErrors,
      setFieldError,
      setSubmitting,
    }: FormikHelpers<ResetPasswordForm>
  ) => {
    if (
      values[ResetPasswordFormField.Password] !==
      values[ResetPasswordFormField.ConfirmPassword]
    ) {
      return setFieldError(
        ResetPasswordFormField.ConfirmPassword,
        intl.formatMessage({
          defaultMessage: 'Passwords do not match',
          id: 'errors.noMatchPassword',
        })
      );
    }

    await sendData({
      url: generatePath(RESET_PASSWORD, { token }),
      data: { password: values[ResetPasswordFormField.Password] },
      fields: ResetPasswordFormField,
      callback,
      setErrors,
      setSubmitting,
    });
  };

  return {
    initialValues,
    onSubmit,
    setIsPasswordFulfilled,
    isPasswordFulfilled,
    ...validationBuilder,
  };
};
