import React, { useRef } from 'react';
import clsx from 'clsx';
import { PhoneIcon, EmailIcon, MobilePhoneIcon } from 'components/Icon';
import { UserAvatar } from 'components/UserAvatar';
import { useAvatarDetailsStyles } from 'components/UserAvatar/styles/avatarDetailsStyles';
import { useOutsideClick } from 'hooks/useOutsideClick';
import {
  USER_DETAILS_AT_ICON_TESTID,
  USER_DETAILS_COMPANY_NAME,
  USER_DETAILS_EMAIL,
  USER_DETAILS_FULL_NAME,
  USER_DETAILS_JOB,
  USER_DETAILS_MOBILE_PHONE,
  USER_DETAILS_PHONE,
  USER_DETAILS_WRAPPER,
} from 'utils/testIds';
import { UserDetailsProps } from './UserDetails.types';
import { FormattedMessage } from 'react-intl';

export const UserDetails = ({
  userData,
  noViewPermission = false,
  closePopover,
}: UserDetailsProps) => {
  const styles = useAvatarDetailsStyles();

  const containerRef = useRef<HTMLDivElement>(null);

  const {
    id,
    first_name: firstName,
    last_name: lastName,
    username,
    mobile,
    phone,
    job_title: job,
    account_type: accountType,
    company_name: companyName,
  } = userData;

  useOutsideClick(containerRef, closePopover, { event: 'click' });

  if (noViewPermission) {
    return (
      <div
        ref={containerRef}
        className={styles.wrapper}
        data-testid={USER_DETAILS_WRAPPER}
      >
        {firstName && lastName && (
          <div className={clsx([styles.center, styles.titleWrapper])}>
            <UserAvatar
              {...{ id, firstName, lastName, accountType }}
              disablePopover
              size='small'
            />

            <span
              data-testid={USER_DETAILS_FULL_NAME}
              className={clsx([styles.title, styles.wrapText])}
            >{`${firstName} ${lastName}`}</span>
          </div>
        )}

        <p className={clsx(styles.descriptionWrapper, styles.wrapText)}>
          <FormattedMessage
            id='misc.noPermToViewUserDetails'
            defaultMessage='You do not have permission to view detailed information about this user.'
          />
        </p>
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className={styles.wrapper}
      data-testid={USER_DETAILS_WRAPPER}
    >
      {firstName && lastName && (
        <div className={clsx([styles.center, styles.titleWrapper])}>
          <UserAvatar
            {...{ id, firstName, lastName, accountType }}
            disablePopover
            size='small'
          />
          <span
            data-testid={USER_DETAILS_FULL_NAME}
            className={clsx([styles.title, styles.wrapText])}
          >{`${firstName} ${lastName}`}</span>
        </div>
      )}

      {job && (
        <div
          data-testid={USER_DETAILS_JOB}
          className={clsx([styles.job, styles.wrapText])}
        >
          {job}
        </div>
      )}

      {companyName && (
        <div
          data-testid={USER_DETAILS_COMPANY_NAME}
          className={clsx([styles.description, styles.wrapText])}
        >
          {companyName}
        </div>
      )}

      {username && (
        <div className={styles.iconItem} data-testid={USER_DETAILS_EMAIL}>
          <EmailIcon
            className={styles.icon}
            size={16}
            data-testid={USER_DETAILS_AT_ICON_TESTID}
          />
          <a href={`mailto:${username}`} className={styles.link}>
            {username}
          </a>
        </div>
      )}

      {mobile && (
        <div className={clsx([styles.iconItem, styles.center])}>
          <MobilePhoneIcon className={styles.icon} size={16} />
          <span
            className={clsx(styles.wrapText, styles.iconText)}
            data-testid={USER_DETAILS_MOBILE_PHONE}
          >
            {mobile}
          </span>
        </div>
      )}

      {phone && (
        <div className={clsx([styles.iconItem, styles.center])}>
          <PhoneIcon className={styles.icon} size={16} />
          <span
            className={clsx(styles.wrapText, styles.iconText)}
            data-testid={USER_DETAILS_PHONE}
          >
            {phone}
          </span>
        </div>
      )}
    </div>
  );
};
