import React from 'react';
import { useIntl } from 'react-intl';
import EnvelopeSent from 'img/envelope-sent.png';
import CardWrapper from 'components/CardWrapper';
import {
  REQUEST_SENT_CARD_SUBTITLE_TESTID,
  REQUEST_SENT_CARD_TITLE_TESTID,
} from 'utils/testIds';
import { usePendingRequestPageStyles } from './styles';

const PendingRequestPage: React.FC = () => {
  const intl = useIntl();
  const styles = usePendingRequestPageStyles();

  const title = intl.formatMessage({
    id: 'activateAccount.requestPending',
    defaultMessage: 'Your request is pending',
  });
  const subtitle = intl.formatMessage({
    id: 'activateAccount.receivedRequest',
    defaultMessage:
      'Our system administrators received your request. Once accepted, you will receive a new activation link.',
  });

  return (
    <CardWrapper>
      <img
        src={EnvelopeSent}
        className={styles.icon}
        alt='Expired Activation Link'
      />
      <p className={styles.title} data-testid={REQUEST_SENT_CARD_TITLE_TESTID}>
        {title}
      </p>
      <p
        className={styles.subtitle}
        data-testid={REQUEST_SENT_CARD_SUBTITLE_TESTID}
      >
        {subtitle}
      </p>
    </CardWrapper>
  );
};

export default PendingRequestPage;
