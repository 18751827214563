import { DisabledOptionParams } from 'components/AutocompleteUsersSelect/types';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import { AccountTypeOptions } from 'pages/Users/enums';
import { useIntl } from 'react-intl';

/**
 * Handles the logic for disabling user option in the autocomplete select.
 */
export const useDisabledUserAssigneeOption = (
  assignedUserIds: number[],
  hasAnyAssignees: boolean | undefined,
  hasSelectedUsers: boolean,
  selectedUsers: AvatarItem[]
) => {
  const intl = useIntl();

  const getIsUserAlreadySelected = (userId: number) => {
    return selectedUsers.some(({ id }) => {
      return userId === id;
    });
  };

  const getIsUserAlreadyAssigned = (userId: number) => {
    return assignedUserIds.some(id => {
      return userId === id;
    });
  };

  const getIsUserDisabled = (user: DisabledOptionParams) => {
    if (
      getIsUserAlreadySelected(user.id) ||
      getIsUserAlreadyAssigned(user.id)
    ) {
      return true;
    }

    if (user.account_type === AccountTypeOptions.OneTimeCompletion) {
      if (hasSelectedUsers) {
        return true;
      }

      return hasAnyAssignees ?? false;
    }

    return false;
  };

  const getDisabledUserText = (userId: number) => {
    if (getIsUserAlreadyAssigned(userId)) {
      return intl.formatMessage({
        id: 'misc.alreadyAssigned',
        defaultMessage: 'Already assigned',
      });
    }

    if (getIsUserAlreadySelected(userId)) {
      return intl.formatMessage({
        id: 'misc.alreadySelected',
        defaultMessage: 'Already selected',
      });
    }

    return undefined;
  };

  return {
    getIsUserDisabled,
    getDisabledUserText,
  };
};
