/**
 * Evaluates the given array and returns the provided value if it contains any elements.
 *
 * @param array The array to evaluate.
 * @param value The value to return if the array is not empty.
 *
 * @returns The provided value if the array is not empty, or undefined otherwise.
 */
export const getValueIfAnyElementInArray = <TValue>(
  array: Array<unknown>,
  value: TValue
) => {
  return array.length > 0 ? value : undefined;
};
