import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import { AccountTypeOptions } from 'pages/Users/enums';
import { useState } from 'react';
import { SelectUserOption } from 'utils/types/selectInput.types';

/**
 * Handles the logic for selecting and removing users from the list of assignees.
 */
export const useUserAssigneesSelection = (
  updatePanelCloseBtnBehavior: (hasSelectedUsers: boolean) => void
) => {
  const [selectedUsers, setSelectedUsers] = useState<AvatarItem[]>([]);
  const [isOneTCUserSelected, setIsOneTCUserSelected] = useState(false);

  const hasSelectedUsers = selectedUsers.length > 0;

  const handleUserSelect = (user: SelectUserOption | undefined) => {
    if (!user) {
      return;
    }

    const userAvatarItem: AvatarItem = {
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      company: user.company_name,
      email: user.username,
      accountType: user.account_type,
      isDeleted: user.is_deleted,
    };

    if (user.account_type === AccountTypeOptions.OneTimeCompletion) {
      setIsOneTCUserSelected(true);
    }

    setSelectedUsers(prev => {
      const updatedSelectedUsers = [...prev, userAvatarItem];

      // Update the close button behavior only when no users were selected before
      if (prev.length === 0) {
        updatePanelCloseBtnBehavior(true);
      }

      return updatedSelectedUsers;
    });
  };

  const handleUserRemove = async (user: AvatarItem) => {
    if (user.accountType === AccountTypeOptions.OneTimeCompletion) {
      setIsOneTCUserSelected(false);
    }

    setSelectedUsers(prev => {
      const updatedSelectedUsers = prev.filter(({ id }) => user.id !== id);

      // Update the close button behavior only when no more users are selected
      if (updatedSelectedUsers.length === 0) {
        updatePanelCloseBtnBehavior(false);
      }

      return updatedSelectedUsers;
    });
  };

  return {
    hasSelectedUsers,
    isOneTCUserSelected,
    selectedUsers,
    handleUserSelect,
    handleUserRemove,
  };
};
