import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LoaderOverlay from '../../components/LoaderOverlay';
import { Checkbox } from '../../components/lib/Checkbox';
import PasswordRules from '../../components/PasswordRules';
import { Formik } from 'formik';
import FormLabel from '../TaskTemplates/components/FormLabel';
import PasswordInput from '../../components/Inputs/PasswordInput';
import { ButtonPrimary } from '../../components/lib/Button';
import { useToggle } from '../../hooks/useToggle';
import ErrorComponent from '../../components/ErrorComponent';
import { StatusCodes } from 'http-status-codes';
import { useParams } from 'react-router-dom';
import { RESET_PASSWORD_TITLE_TESTID } from 'utils/testIds';
import { usePasswordRulesData } from 'hooks/usePasswordRulesData';
import { ActivationTokenError } from 'pages/ActivateAccount/enums';
import { ForgotPasswordForm } from 'pages/ForgotPasswordPage/components/ForgotPasswordForm';
import { useResetPasswordData } from './hooks/useResetPasswordData';
import { useResetPasswordForm } from './hooks/useResetPasswordForm';
import { ResetPasswordFormField } from './types/resetPasswordFormField';
import { ResetPasswordPageParams } from './types/resetPasswordPageParams';
import { ResetPasswordForm } from './types/resetPasswordForm';
import { useResetPasswordPageStyles } from './ResetPasswordPage.styles';
import { AuthLayout } from 'components/AuthLayout';
import { ErrorCodes } from 'utils/types/errorResponse';
import { AccountLockedInfo } from 'components/AccountLockedInfo';

export const ResetPasswordPage = () => {
  const intl = useIntl();
  const styles = useResetPasswordPageStyles();

  const {
    error,
    isLoading,
    isTokenSuccessfulyVerified,
    verifyResetPasswordToken,
  } = useResetPasswordData();
  const { passwordComplexityData } = usePasswordRulesData({
    fetchOnLoad: false,
    shouldFetch: isTokenSuccessfulyVerified,
  });
  const {
    setIsPasswordFulfilled,
    onSubmit,
    buildValidationSchema,
    initialValues,
    isPasswordFulfilled,
  } = useResetPasswordForm();
  const { token } = useParams<ResetPasswordPageParams>();
  const [toggled, { toggleOff, toggleOn }] = useToggle(false);

  const isTokenExpired =
    error?.data?.error_code === ActivationTokenError.TokenExpired;

  if (!token) {
    return (
      <div className={styles.notFoundWrapper}>
        <ErrorComponent error={StatusCodes.NOT_FOUND} />
      </div>
    );
  }

  if (
    (isTokenSuccessfulyVerified && !passwordComplexityData) ||
    (!isTokenSuccessfulyVerified && !error)
  ) {
    return <LoaderOverlay />;
  }

  if (error?.data?.error_code === ErrorCodes.ERR_ACCOUNT_LOCKED) {
    return (
      <AuthLayout>
        <AccountLockedInfo
          lockoutEndDate={error?.data?.data?.lockout_ends_at ?? ''}
        />

        <div className={styles.buttonWrapper}>
          <ButtonPrimary
            loading={isLoading}
            onClick={() => verifyResetPasswordToken()}
          >
            <FormattedMessage
              id='reset.reset'
              defaultMessage='Reset password'
            />
          </ButtonPrimary>
        </div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      {isTokenExpired ? (
        <ForgotPasswordForm
          customTitle={intl.formatMessage({
            defaultMessage: 'Reset password link has expired',
            id: 'resetPassword.passwordLinkExpired',
          })}
          customSubtitle={intl.formatMessage({
            defaultMessage:
              'Please enter your username below and request a new link to reset your password.',
            id: 'resetPassword.enterUsernameBelowToReset',
          })}
          customButtonLabel={intl.formatMessage({
            defaultMessage: 'Reset password',
            id: 'resetPassword.resetPassword',
          })}
          showErrorIcon
        />
      ) : (
        <>
          <p className={styles.title} data-testid={RESET_PASSWORD_TITLE_TESTID}>
            <FormattedMessage
              id='reset.title'
              defaultMessage='Reset password'
            />
          </p>

          <Formik
            {...{ initialValues, onSubmit }}
            validationSchema={buildValidationSchema()}
            enableReinitialize
          >
            {({ handleSubmit, errors, dirty, isSubmitting }) => (
              <>
                <FormLabel required>
                  <FormattedMessage
                    id='reset.password'
                    defaultMessage='New password'
                  />
                </FormLabel>
                <PasswordInput
                  visibilityToggle={false}
                  isPasswordVisible={toggled}
                  name={ResetPasswordFormField.Password}
                />
                <FormLabel required>
                  <FormattedMessage
                    id='reset.confirmPassword'
                    defaultMessage='Confirm new password'
                  />
                </FormLabel>
                <PasswordInput
                  visibilityToggle={false}
                  isPasswordVisible={toggled}
                  name={ResetPasswordFormField.ConfirmPassword}
                />
                <Checkbox
                  checked={toggled}
                  onChange={() => (toggled ? toggleOff() : toggleOn())}
                >
                  <FormattedMessage
                    id='reset.showPassword'
                    defaultMessage='Show password'
                  />
                </Checkbox>
                <PasswordRules<ResetPasswordForm>
                  className={styles.passwordRules}
                  setIsFulfilled={setIsPasswordFulfilled}
                  options={passwordComplexityData}
                  passwordKey={ResetPasswordFormField.Password}
                />
                <div className={styles.buttonWrapper}>
                  <ButtonPrimary
                    disabled={
                      Object.keys(errors).length > 0 ||
                      isSubmitting ||
                      !dirty ||
                      !isPasswordFulfilled
                    }
                    onClick={() => handleSubmit()}
                  >
                    <FormattedMessage
                      id='reset.reset'
                      defaultMessage='Reset password'
                    />
                  </ButtonPrimary>
                </div>
              </>
            )}
          </Formik>
        </>
      )}
    </AuthLayout>
  );
};
