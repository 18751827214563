import React from 'react';
import { ReactComponent as ErrorIcon } from 'img/icons/exclamation-mark.svg';
import { AccountLockedInfoProps } from './AccountLockedInfo.types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useAccountLockedInfoStyles } from './AccountLockedInfo.styles';
import { SUBTITLE_TESTID, TITLE_TESTID } from 'utils/testIds';

/**
 * Displays information about locked account due to too many failed login attempts. Among others it
 * shows when the lockout period ends.
 */
export const AccountLockedInfo = ({
  lockoutEndDate,
}: AccountLockedInfoProps) => {
  const intl = useIntl();
  const styles = useAccountLockedInfoStyles();

  const title = intl.formatMessage({
    id: 'misc.accountLocked',
    defaultMessage: 'Account locked',
  });
  const subtitle = intl.formatMessage(
    {
      id: 'misc.accountLockedInfo',
      defaultMessage:
        'Your account is locked due to too many failed login attempts. Password reset is currently disabled. Please try again after the lockout period ends at {datetime}.',
    },
    {
      datetime: moment(lockoutEndDate).format('H:mm, dddd, D MMMM YYYY'),
    }
  );

  return (
    <div className={styles.mainWrapper}>
      <ErrorIcon className={styles.icon} />

      <div className={styles.textWrapper}>
        <p className={styles.title} data-testid={TITLE_TESTID}>
          {title}
        </p>
        <p className={styles.subtitle} data-testid={SUBTITLE_TESTID}>
          {subtitle}
        </p>
      </div>
    </div>
  );
};
