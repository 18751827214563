import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { NO_PERMISSIONS_TO_ASSIGN_USER_ERROR_REGEX } from 'utils/consts';

/**
 * Checks whether the error is a "no permissions to assign user" error. The only way to check if
 * the error is a "no permissions to assign user" error is to evaluate the error message by
 * predefined regex.
 *
 * @param error Axios error returned from the API.
 * @param messagePath Path to the error message in the error object.
 *
 * @returns Boolean that indicates whether the error is a "no permissions to assign user" error.
 */
export const isNoPermissionsToAssignUserError = (
  error: AxiosError,
  messagePath = 'detail'
) => {
  if (!error.response) {
    return false;
  }

  const { data, status } = error.response;

  if (status !== StatusCodes.BAD_REQUEST) {
    return false;
  }

  return NO_PERMISSIONS_TO_ASSIGN_USER_ERROR_REGEX.test(
    data?.[messagePath]?.[0]
  );
};
