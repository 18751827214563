import { ColumnDefinition } from 'components/formBuilder/types/columnDefinition';

export const createUiColumnContentSchema = (
  sectionAlias: string,
  columnDefinition: ColumnDefinition,
  fieldsInDataSchema: {
    [key: string]: string[];
  }
) => {
  return {
    ...columnDefinition,
    components: columnDefinition.components.filter((alias: string) =>
      fieldsInDataSchema[sectionAlias]?.includes(alias)
    ),
  };
};
