import React from 'react';
import { useManageTaskUserAssigneesStyles } from './ManageTaskUserAssignees.styles';
import { useIntl } from 'react-intl';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import DisabledButtonTooltip from 'components/DisabledButtonTooltip';
import { ButtonPrimary } from 'components/lib/Button';
import { PlusIcon } from 'components/Icon';
import {
  ADD_USER_ASSIGNEES_BTN_TESTID,
  MANAGE_USER_ASSIGNEES_TESTID,
} from 'utils/testIds';
import clsx from 'clsx';
import { TasksPanelMode } from 'components/SidePanels/TasksPanel/types';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { UnassignTaskUserAssigneeModal } from './components/UnassignTaskUserAssigneeModal';
import { getSortedUserAvatars } from 'utils/functions/getSortedUserAvatars';
import { TaskUserAssigneesLimitAlert } from './components/TaskUserAssigneesLimitAlert';
import { useTaskUserAssigneesLimit } from './hooks/useTaskUserAssigneesLimit';
import { useUnassignTaskUserAssignee } from './hooks/useUnassignTaskUserAssignee';

/**
 * View for managing users assigned to a task. Displays a list of users who are already assigned
 * and allows to unassign them or assign a new ones.
 */
export const ManageTaskUserAssignees = () => {
  const intl = useIntl();
  const styles = useManageTaskUserAssigneesStyles();

  const { taskAssignees, setMode } = useTasksPanelContext();
  const {
    disabledBtnTooltipText,
    isOneTCUserAssigned,
    isUsersLimitReached,
    userAssigneesLimit,
  } = useTaskUserAssigneesLimit();
  const {
    userToUnassign,
    isUnassigningHimself,
    isUnassigning,
    cancelUnassigning,
    setUserToUnassign,
    unassignUserFromTask,
  } = useUnassignTaskUserAssignee();

  const handleUserItemDelete = (user: AvatarItem) => {
    const { id, firstName, lastName } = user;

    setUserToUnassign({ id, name: `${firstName} ${lastName}` });
  };

  const openAddUsersView = () => {
    setMode(TasksPanelMode.AddAssignees);
  };

  return (
    <div
      className={styles.contentWrapper}
      data-testid={MANAGE_USER_ASSIGNEES_TESTID}
    >
      <DisabledButtonTooltip
        title={disabledBtnTooltipText}
        showTooltip={isUsersLimitReached}
      >
        <ButtonPrimary
          className={clsx({ [styles.disabledBtn]: isUsersLimitReached })}
          icon={<PlusIcon size={10} />}
          disabled={isUsersLimitReached}
          data-testid={ADD_USER_ASSIGNEES_BTN_TESTID}
          onClick={openAddUsersView}
        />
      </DisabledButtonTooltip>

      {userAssigneesLimit && (
        <TaskUserAssigneesLimitAlert
          isOneTCUserAssigned={isOneTCUserAssigned}
          usersLimit={userAssigneesLimit}
        />
      )}

      <List<AvatarItem>
        className={styles.usersList}
        items={getSortedUserAvatars(taskAssignees.users)}
        emptyDataDescription={intl.formatMessage({
          id: 'misc.noUsersHaveBeenAssigned',
          defaultMessage: 'No users have been assigned',
        })}
        renderItem={item => (
          <PeopleListElement
            {...item}
            onDelete={() => handleUserItemDelete(item)}
          />
        )}
      />

      <UnassignTaskUserAssigneeModal
        userToUnassign={userToUnassign}
        isUnassigningHimself={isUnassigningHimself}
        isLoading={isUnassigning}
        onConfirm={unassignUserFromTask}
        onCancel={cancelUnassigning}
      />
    </div>
  );
};
