import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useManageOwnersStyles } from './styles';
import { ButtonPrimary, ButtonSecondaryOutlined } from 'components/lib/Button';
import { PlusIcon } from 'components/Icon';
import { useSelector } from 'react-redux';
import {
  getObjectClassPermissionsClassOwnersAvatars,
  getObjectClassPermissionsClassOwnersCount,
  getObjectClassPermissionsClassOwnersItemsLimit,
} from 'store/selectors/objectClassPermissionsSelectors';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import { SCROLLABLE_CONTAINER } from 'utils/elementsIds';
import RemoveOwnerModal from './RemoveOwnerModal';
import {
  BUTTON_CANCEL_TESTID,
  MANAGE_OWNERS_PANEL_BUTTON_ADD_OWNERS,
  OBJECT_CLASS_PERMISSIONS_MANAGE_OWNERS,
} from 'utils/testIds';
import useManageOwnersPanel from './hooks';
import DisabledButtonTooltip from 'components/DisabledButtonTooltip';
import LimitMessage from '../LimitMessage';
import clsx from 'clsx';

const ManageOwnersPanel = () => {
  const intl = useIntl();
  const styles = useManageOwnersStyles();
  const count = useSelector(getObjectClassPermissionsClassOwnersCount);
  const ownersAvatars = useSelector(
    getObjectClassPermissionsClassOwnersAvatars
  );
  const itemsLimit = useSelector(
    getObjectClassPermissionsClassOwnersItemsLimit
  );

  const {
    handleCancel,
    isLimitExceeded,
    openAddOwnersPanel,
    onDeleteFabric,
    objectClassId,
    ownerToDelete,
    setOwnerToDelete,
  } = useManageOwnersPanel();

  return (
    <div
      className={styles.contentWrapper}
      data-testid={OBJECT_CLASS_PERMISSIONS_MANAGE_OWNERS}
    >
      <div className={styles.headerWrapper}>
        <span className={styles.text}>
          <FormattedMessage
            id='objectClass.manageOwners.counter'
            defaultMessage='{count, plural, one {# owner} other {# owners}}'
            values={{ count }}
          />
        </span>
        <DisabledButtonTooltip
          title={intl.formatMessage({
            id: 'owners.disabledButtonMessage',
            defaultMessage:
              'The maximum number of owners has been reached. Remove owners to add new ones.',
          })}
          showTooltip={isLimitExceeded}
        >
          <ButtonPrimary
            onClick={openAddOwnersPanel}
            icon={<PlusIcon size={9} />}
            data-testid={MANAGE_OWNERS_PANEL_BUTTON_ADD_OWNERS}
            disabled={isLimitExceeded}
            className={clsx({ [styles.buttonDisabled]: isLimitExceeded })}
          >
            <FormattedMessage id='misc.addOwners' defaultMessage='Add owners' />
          </ButtonPrimary>
        </DisabledButtonTooltip>
      </div>
      <LimitMessage {...{ count, itemsLimit }} />
      <div className={styles.scrollableContainer} id={SCROLLABLE_CONTAINER}>
        <List<AvatarItem>
          emptyDataDescription={intl.formatMessage({
            id: 'objectClass.manageOwners.noOwnersHaveBeenAdded',
            defaultMessage: 'No owners have been added.',
          })}
          items={ownersAvatars}
          renderItem={item => (
            <PeopleListElement {...item} onDelete={onDeleteFabric(item)} />
          )}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <ButtonSecondaryOutlined
          onClick={handleCancel}
          data-testid={BUTTON_CANCEL_TESTID}
        >
          <FormattedMessage id='misc.close' defaultMessage='Close' />
        </ButtonSecondaryOutlined>
      </div>
      <RemoveOwnerModal
        {...{
          objectClassId,
          ownerToDelete,
          setOwnerToDelete,
        }}
      />
    </div>
  );
};

export default ManageOwnersPanel;
