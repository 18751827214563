import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { FormikConfig, FormikHelpers } from 'formik';
import {
  TASKS_LIST,
  TASK_GROUP_ASSIGNEES,
  TASK_USER_ASSIGNEES,
} from 'utils/endpoints';
import { TaskDetails } from 'utils/types/api/tasks.types';
import { apiCall } from 'utils/api';
import { StatusCodes } from 'http-status-codes';
import { useAutoSaveErrorModalContext } from 'contexts/AutoSaveErrorModalContext';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { TasksPanelMode } from 'components/SidePanels/TasksPanel/types';
import { TaskFormType } from '../../types';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { parseError } from 'utils/parseError';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { isObjectNotExistsError } from 'utils/functions/isObjectNotExistsError';
import {
  SelectUserGroupOption,
  SelectUserOption,
} from 'utils/types/selectInput.types';
import { isNoPermissionsToResourceError } from 'utils/functions/isNoPermissionsToResourceError';
import { prepareTaskCreateData } from './utils/prepareTaskCreateData';
import { showTaskCreatedToast } from './utils/showTaskCreatedToast';
import { transformTaskFormErrors } from './utils/transformTaskFormErrors';
import { showSomeUsersHaveBeenDeletedToast } from './utils/showSomeUsersHaveBeenDeletedToast';
import { showPermissionsToSomeGroupsRevokedToast } from './utils/showPermissionsToSomeGroupsRevokedToast';
import { showSomeGroupsHaveBeenDeletedToast } from './utils/showSomeGroupsHaveBeenDeletedToast';
import { showPermissionsToSomeUsersRevokedToast } from './utils/showPermissionsToSomeUsersRevokedToast';
import { isNoPermissionsToAssignUserError } from 'utils/functions/isNoPermissionsToAssignUserError';

export const useTaskFormSubmit = () => {
  const intl = useIntl();

  const {
    selectedResource: { record: { recordId = undefined } = {} } = {},
  } = useSelectedResourceContext();
  const { setMode } = useTasksPanelContext();
  const { showErrorModal } = useAutoSaveErrorModalContext();

  const createTask = useCallback(
    async (
      formData: TaskFormType,
      formikHelpers: FormikHelpers<TaskFormType>
    ) => {
      const requestData = prepareTaskCreateData(formData, recordId);

      try {
        const { data } = await apiCall.post<TaskDetails>(
          TASKS_LIST,
          requestData
        );

        showTaskCreatedToast();

        return data;
      } catch (error) {
        if (!axios.isAxiosError(error) || !error.response) {
          showUnhandledErrorToast(error);
          return;
        }

        // Checks whether the object record has been deleted while creating a task.
        if (isObjectNotExistsError(error, 'object_record') && showErrorModal) {
          showErrorModal({
            ...error,
            response: {
              ...error.response,
              status: StatusCodes.NOT_FOUND,
            },
          });

          formikHelpers.resetForm();
          return;
        }

        const { messages } = parseError(error);

        if (messages) {
          formikHelpers.setErrors(transformTaskFormErrors(messages, intl));
          return;
        }

        showUnhandledErrorToast(error);
      }
    },
    [intl, recordId, showErrorModal]
  );

  const assignUsersToTask = async (
    taskId: string,
    users: SelectUserOption[]
  ) => {
    if (users.length === 0) {
      return;
    }

    const endpoint = generatePath(TASK_USER_ASSIGNEES, { id: taskId });
    const requestData = users.map(({ id }) => id);

    try {
      await apiCall.post(endpoint, requestData);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        showUnhandledErrorToast(error);
        return;
      }

      // Checks whether some users have been deleted while creating a task.
      if (isObjectNotExistsError(error)) {
        showSomeUsersHaveBeenDeletedToast();
        return;
      }

      // Checks whether permissions to some users have been revoked while creating a task.
      if (isNoPermissionsToAssignUserError(error)) {
        showPermissionsToSomeUsersRevokedToast();
        return;
      }

      showUnhandledErrorToast(error);
    }
  };

  const assignGroupsToTask = async (
    taskId: string,
    groups: SelectUserGroupOption[]
  ) => {
    if (groups.length === 0) {
      return;
    }

    const endpoint = generatePath(TASK_GROUP_ASSIGNEES, { id: taskId });
    const requestData = groups.map(({ id }) => id);

    try {
      await apiCall.post(endpoint, requestData);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        showUnhandledErrorToast(error);
        return;
      }

      // Checks whether some groups have been deleted while creating a task.
      if (isObjectNotExistsError(error)) {
        showSomeGroupsHaveBeenDeletedToast();
        return;
      }

      // Checks whether permissions to some groups have been revoked while creating a task.
      if (isNoPermissionsToResourceError(error)) {
        showPermissionsToSomeGroupsRevokedToast();
        return;
      }

      showUnhandledErrorToast(error);
    }
  };

  const onSubmit = useCallback<FormikConfig<TaskFormType>['onSubmit']>(
    async (formData, formikHelpers) => {
      const task = await createTask(formData, formikHelpers);

      if (!task) {
        return;
      }

      await assignUsersToTask(task.id, formData.assignees.users);
      await assignGroupsToTask(task.id, formData.assignees.groups);

      setMode(TasksPanelMode.List);
    },
    [createTask, setMode]
  );

  return {
    onSubmit,
  };
};
