import { createUseStyles } from 'react-jss';

export const useAvatarGroupPopoverStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    width: '100%',
    maxHeight: 240,
    overflow: 'auto',
  },
  item: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    wordBreak: 'break-word',
    gap: '8px',
  },
  displayName: {
    marginBottom: 0,
  },
});
