import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { UserAvatar } from 'components/UserAvatar';
import { useAvatarDetailsStyles } from 'components/UserAvatar/styles/avatarDetailsStyles';
import { UserManagementIcon } from 'components/Icon';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { UserGroupDetailsProps } from './UserGroupDetails.types';

export const UserGroupDetails = ({
  groupData,
  noViewPermission = false,
  closePopover,
}: UserGroupDetailsProps) => {
  const styles = useAvatarDetailsStyles();

  const containerRef = useRef<HTMLDivElement>(null);

  const { id, name, description, num_of_members: membersCount } = groupData;

  useOutsideClick(containerRef, closePopover, { event: 'click' });

  if (noViewPermission) {
    return (
      <div className={styles.wrapper} ref={containerRef}>
        {name && (
          <div className={clsx([styles.center, styles.titleWrapper])}>
            <UserAvatar
              {...{ id }}
              userGroup={name}
              disablePopover
              size='small'
            />
            <span className={clsx([styles.title, styles.wrapText])}>
              {name}
            </span>
          </div>
        )}

        <p className={clsx(styles.descriptionWrapper, styles.wrapText)}>
          <FormattedMessage
            id='misc.noPermToViewGroupDetails'
            defaultMessage='You do not have permission to view detailed information about this group.'
          />
        </p>
      </div>
    );
  }

  return (
    <div className={styles.wrapper} ref={containerRef}>
      {name && (
        <div className={clsx([styles.center, styles.titleWrapper])}>
          <UserAvatar
            {...{ id }}
            userGroup={name}
            disablePopover
            size='small'
          />
          <span className={clsx([styles.title, styles.wrapText])}>{name}</span>
        </div>
      )}

      {description && (
        <p className={clsx(styles.descriptionWrapper, styles.wrapText)}>
          {description}
        </p>
      )}

      <div className={styles.iconItem}>
        <UserManagementIcon className={styles.icon} size={16} />

        <span>
          <FormattedMessage
            id='members.membersCount'
            defaultMessage='{membersCount, plural, one {# member} other {# members}}'
            values={{ membersCount }}
          />
        </span>
      </div>
    </div>
  );
};
