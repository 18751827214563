import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useValidationSchemaBuilder from 'hooks/useValidationSchemaBuilder';
import { getAccessToken } from 'store/selectors/authSelectors';
import { OptionSelect } from 'utils/types/selectInput.types';
import { apiCall } from 'utils/api';
import { FORGOTTEN_PASSWORD } from 'utils/endpoints';
import routes from 'utils/routingPaths';
import { FORGOT_PASSWORD_FORM_VALIDATION } from '../consts/forgotPasswordFormValidation';
import { ForgotPasswordForm } from '../types/forgotPasswordForm';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import axios from 'axios';
import { ErrorCodes } from 'utils/types/errorResponse';
import { ForgotPasswordFormView } from '../types/forgotPasswordFormView';

export const useForgotPasswordForm = () => {
  const history = useHistory();
  const token = useSelector(getAccessToken);

  const {
    rawValidationSchema,
    buildValidationSchema,
  } = useValidationSchemaBuilder<OptionSelect | undefined>(
    FORGOT_PASSWORD_FORM_VALIDATION
  );

  const [formView, setFormView] = useState(ForgotPasswordFormView.Default);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lockoutEndDate, setLockoutEndDate] = useState<string>();
  const [initialValues, setInitialForm] = useState<ForgotPasswordForm>({
    email: '',
  });

  const onSubmit = async (values: ForgotPasswordForm) => {
    try {
      setIsSubmitting(true);

      await apiCall.post(FORGOTTEN_PASSWORD, values);

      setInitialForm(values);
      setFormView(ForgotPasswordFormView.RequestSent);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        showUnhandledErrorToast(error);
        return;
      }

      if (error.response?.data?.error_code === ErrorCodes.ERR_ACCOUNT_LOCKED) {
        setFormView(ForgotPasswordFormView.AccountLocked);
        setLockoutEndDate(error.response.data?.data?.lockout_ends_at);
        return;
      }

      showUnhandledErrorToast(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    history.replace(routes.HOMEPAGE);
  }, [history, token]);

  return {
    formView,
    isSubmitting,
    initialValues,
    lockoutEndDate,
    rawValidationSchema,
    validationSchema: buildValidationSchema(),
    onSubmit,
  };
};
