import { createUseStyles } from 'react-jss';
import defaultTheme from 'utils/defaultTheme';

const theme = defaultTheme;

const useExpiredPasswordStyles = createUseStyles({
  notFoundWrapper: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  },
  buttonWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  subtitle: {
    textAlign: 'center',
    display: 'block',
    fontSize: '0.75rem',
    fontWeight: 400,
    color: theme['heading-color'],
    opacity: 0.6,
    margin: 0,
  },
  title: {
    fontSize: '1.25rem',
    textAlign: 'center',
    margin: 0,
    fontWeight: 700,
    color: theme['heading-color'],
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    marginBottom: '32px',
  },
});

export default useExpiredPasswordStyles;
