import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';

/**
 * Evaluates the item and returns the appropriate display name.
 *
 * @param item Item to evaluete.
 *
 * @returns Item's display name.
 */
export const evaluateDisplayName = (item: AvatarItem) => {
  const { userGroup, isDeleted, id, firstName, lastName } = item;

  if (userGroup) {
    return isDeleted
      ? GlobalIntlSingleton.intl.formatMessage(
          {
            id: 'misc.deletedUserGroup',
            defaultMessage: 'Deleted user group {id}',
          },
          { id }
        )
      : userGroup;
  }

  return isDeleted
    ? GlobalIntlSingleton.intl.formatMessage(
        {
          id: 'misc.deletedUser',
          defaultMessage: 'Deleted user {id}',
        },
        { id }
      )
    : `${firstName} ${lastName}`;
};
