import { FormValue } from 'alx-dynamic-form';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import {
  UsersAndGroupsExpandablePickerRef,
  EditModeOptions,
} from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/types';
import { useRef, useState } from 'react';
// eslint-disable-next-line
import { CustomUserWidget } from '../CustomUserWidget';
import { FieldSelectionWorkspace } from 'components/FormPreview2/types/fieldSelectionWorkspace';

/**
 * This hook provides the edit mode behavior for the {@link CustomUserWidget}.
 */
export const useCustomUserWidgetEditMode = (
  originalFormValue: UsersAndGroupsFormValue | null | undefined,
  setValue: (value: FormValue | ((previous: FormValue) => FormValue)) => void,
  additionalFieldProps: {
    fieldWorkspace: FieldSelectionWorkspace;
  }
) => {
  const isEditMode =
    additionalFieldProps.fieldWorkspace ===
      FieldSelectionWorkspace.InPlaceEditRecord ||
    additionalFieldProps.fieldWorkspace ===
      FieldSelectionWorkspace.EditRecordForm;

  const [tempValue, setTempValue] = useState<
    UsersAndGroupsFormValue | null | undefined
  >(originalFormValue);

  const usersAndGroupsExpandablePickerRef = useRef<UsersAndGroupsExpandablePickerRef | null>(
    null
  );

  const editModeBehavior: EditModeOptions = {
    isSaving: false,
    onCloseIntent: async () => {
      setValue(tempValue ? (tempValue as FormValue) : null);
      setTempValue(tempValue);
      return true;
    },
    onRejection: () => {
      setTempValue(originalFormValue);
      if (!usersAndGroupsExpandablePickerRef.current) {
        return;
      }

      usersAndGroupsExpandablePickerRef.current.blur();
    },
  };

  return {
    isEditMode,
    usersAndGroupsExpandablePickerRef,
    editModeBehavior: isEditMode ? editModeBehavior : undefined,
    tempValue,
    setTempValue,
  };
};
