import React, { useEffect } from 'react';
import { useLoginStyles } from './styles';
import LoginForm from './components/LoginForm/LoginForm';
import { FormattedMessage, useIntl } from 'react-intl';
import { LOGIN_TITLE_TESTID } from 'utils/testIds';
import { useProviders } from './hooks';
import LoaderOverlay from '../../components/LoaderOverlay';
import { useHelmetContext } from 'contexts/HelmetContext';
import { AuthLayout } from 'components/AuthLayout';

const Login = () => {
  const intl = useIntl();
  const styles = useLoginStyles();

  const { setPageTitle } = useHelmetContext();
  const { providers, loadingAuthenticationData } = useProviders();

  const title = intl.formatMessage({
    id: 'misc.autologyx',
    defaultMessage: 'Autologyx',
  });

  useEffect(() => {
    setPageTitle(title);
  }, [setPageTitle, title]);

  if (loadingAuthenticationData) {
    return <LoaderOverlay />;
  }

  return (
    <AuthLayout>
      <p className={styles.title} data-testid={LOGIN_TITLE_TESTID}>
        <FormattedMessage
          id='login.title'
          defaultMessage='Log in to Autologyx'
        />
      </p>

      <LoginForm providers={providers} />
    </AuthLayout>
  );
};

export default Login;
