import { useToggle } from 'hooks/useToggle';
import { useEffect } from 'react';

export const useUserAvatarPopover = (closeOnElementScroll?: string) => {
  const [
    isPopoverVisible,
    { toggleOff: closePopover, toggleOn: openPopover },
  ] = useToggle(false);

  useEffect(() => {
    if (!isPopoverVisible || !closeOnElementScroll) {
      return;
    }

    const element = window.document.getElementById(closeOnElementScroll);

    if (!element) {
      return;
    }

    element.addEventListener('scroll', closePopover);

    return () => {
      if (!element) {
        return;
      }

      element.removeEventListener('scroll', closePopover);
    };
  }, [closePopover, isPopoverVisible, closeOnElementScroll]);

  return { isPopoverVisible, closePopover, openPopover };
};
