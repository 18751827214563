import React from 'react';
import EnvelopeSent from 'img/envelope-sent.png';
import {
  REQUEST_SENT_CARD_SUBTITLE_TESTID,
  REQUEST_SENT_CARD_TITLE_TESTID,
} from 'utils/testIds';
import { useRequestSentInfoStyles } from './RequestSentInfo.styles';
import { RequestSentInfoProps } from './RequestSentInfo.types';
import { useIntl } from 'react-intl';

/**
 * Displays information that the request has been sent with additional provided text.
 */
export const RequestSentInfo = ({ text }: RequestSentInfoProps) => {
  const intl = useIntl();
  const styles = useRequestSentInfoStyles();

  const title = intl.formatMessage({
    id: 'misc.requestSent',
    defaultMessage: 'Your request has been sent',
  });

  return (
    <div className={styles.mainWrapper}>
      <img
        src={EnvelopeSent}
        className={styles.icon}
        alt='Expired Activation Link'
      />

      <div className={styles.textWrapper}>
        <p
          className={styles.title}
          data-testid={REQUEST_SENT_CARD_TITLE_TESTID}
        >
          {title}
        </p>
        <p
          className={styles.subtitle}
          data-testid={REQUEST_SENT_CARD_SUBTITLE_TESTID}
        >
          {text}
        </p>
      </div>
    </div>
  );
};
