import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { OBJECT_NOT_EXISTS_ERROR_REGEX } from 'utils/consts';

/**
 * Checks whether the error is an "object does not exist" error. The only way to check if the
 * error is an "object does not exist" error is to evaluate the error message by predefined regex.
 *
 * @param error Axios error returned from the API.
 * @param messagePath Path to the error message in the error object.
 *
 * @returns Boolean that indicates whether the error is an "object does not exist" error.
 */
export const isObjectNotExistsError = (
  error: AxiosError,
  messagePath = 'detail'
) => {
  if (!error.response) {
    return false;
  }

  const { data, status } = error.response;

  if (status !== StatusCodes.BAD_REQUEST) {
    return false;
  }

  return OBJECT_NOT_EXISTS_ERROR_REGEX.test(data?.[messagePath]?.[0]);
};
