import AutocompleteUsersSelect from 'components/AutocompleteUsersSelect';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SELECT_USERS_WRAPPER } from 'utils/elementsIds';
import { FilterUserValueProps } from './FilterUserValue.types';
import { SelectUserOption } from 'utils/types/selectInput.types';
import { useFilterUserValueStyles } from './FilterUserValue.styles';
import { OptionAvatar } from 'components/OptionAvatars/OptionAvatar';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';

export const FilterUserValue = ({
  value,
  autocompleteUrl,
  isMultiSelect,
  testId,
  onChange,
}: FilterUserValueProps) => {
  const intl = useIntl();
  const styles = useFilterUserValueStyles();

  const selectedUsers: SelectUserOption[] = useMemo(() => {
    if (!value) {
      return [];
    }

    return Array.isArray(value) ? value : [value];
  }, [value]);

  const avatars: AvatarItem[] = useMemo(() => {
    return selectedUsers.map(user => ({
      firstName: user.first_name,
      lastName: user.last_name,
      id: user.id,
      accountType: user.account_type,
      email: user.username,
      company: user.company_name,
    }));
  }, [selectedUsers]);

  const getIsUserSelected = (id: number) => {
    return selectedUsers.some(user => id === user.id);
  };

  const getDisabledText = (user: SelectUserOption) => {
    if (!getIsUserSelected(user.id)) {
      return '';
    }

    return intl.formatMessage({
      id: 'misc.alreadySelected',
      defaultMessage: 'Already selected',
    });
  };

  const removeSelectedUser = (id: number | string) => {
    if (!selectedUsers) {
      return;
    }

    const updatedUsers = selectedUsers.filter(user => user.id !== id);

    onChange(updatedUsers);
  };

  const handleChange = (newValue: SelectUserOption | undefined) => {
    if (!newValue) {
      return;
    }

    const isAlreadyAdded = selectedUsers.some(user => user.id === newValue.id);

    if (isAlreadyAdded) {
      removeSelectedUser(newValue.id);
      return;
    }

    if (isMultiSelect) {
      onChange([newValue, ...selectedUsers]);
      return;
    }

    onChange(newValue);
  };

  const getFixedAutocompleteUrl = (autocompleteUrlBase: string) => {
    return autocompleteUrlBase.replace(
      '?',
      '?search_in=first_name,last_name,company_name&'
    );
  };

  return (
    <div
      id={SELECT_USERS_WRAPPER}
      className={styles.wrapper}
      data-testid={testId}
    >
      <AutocompleteUsersSelect
        selectMultiple={isMultiSelect}
        autocompleteUrl={getFixedAutocompleteUrl(autocompleteUrl)}
        checkOptionIsDisabled={({ id }) => getIsUserSelected(id)}
        onChange={handleChange}
        renderOption={(option, searchValue) => (
          <OptionAvatar
            option={option}
            searchValue={searchValue}
            disabledText={getDisabledText(option)}
          />
        )}
      />

      <List<AvatarItem>
        hideEmptyData
        items={avatars}
        renderItem={item => (
          <PeopleListElement
            id={item.id}
            firstName={item.firstName}
            lastName={item.lastName}
            accountType={item.accountType}
            email={item.email}
            company={item.company}
            onDelete={() => removeSelectedUser(item.id)}
          />
        )}
      />
    </div>
  );
};
