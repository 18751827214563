import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select } from 'components/lib/Select';
import {
  BUTTON_CANCEL_TESTID,
  BUTTON_SUBMIT_TESTID,
  OBJECT_CLASS_PERMISSIONS_MANAGE_OWNERS,
  RECORD_ACCESS_IDENTIFIER,
  RECORD_ACCESS_PERMISSION_SET_SELECT,
} from 'utils/testIds';
import { useAddUsersAsStyles } from './styles';
import useAddUsersAs from './hooks';
import { ButtonSecondaryOutlined, ButtonTertiary } from 'components/lib/Button';
import PeopleListElement from '../PeopleListElement';
import { SCROLLABLE_CONTAINER, SELECT_USERS_WRAPPER } from 'utils/elementsIds';
import { PlusIcon } from 'components/Icon';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import AutocompleteUsersSelect from 'components/AutocompleteUsersSelect';
import LimitExceededNotification from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassPermissions/components/AddUsersPanel/components/LimitExceededNotification';
import { OptionAvatar } from 'components/OptionAvatars/OptionAvatar';
import CancelConfigurationModal from 'components/CancelConfigurationModal';
import CustomPrompt from 'components/CustomPrompt';
import { useAutoSaveErrorModalContext } from 'contexts/AutoSaveErrorModalContext';
import { useSaveChangesModal } from 'components/FormHeader/hooks';
import { CustomPromptType } from 'components/CustomPrompt/types';
import { Location } from 'history';
import { Loader } from 'components/lib/Loader';
import ErrorComponent from 'components/ErrorComponent';

const AddUsersAs = () => {
  const intl = useIntl();
  const classes = useAddUsersAsStyles({});
  const {
    selectedPermissionSet,
    permissionSetSelectOptions,
    handleSelectPermissionSet,
    handleCancel,
    handleSubmit,
    isSubmitting,
    usersCount,
    itemsLimit,
    selectedUsers,
    hasSelectedUsers,
    isLimitExceeded,
    isLimitAchieved,
    onSelectChange,
    checkOptionIsDisabled,
    getDisabledText,
    removeSeletedUser,
    showUnsavedChangesModal,
    hideUnsavedChangesModal,
    onConfirmCancelModal,
    loading,
    limitMessage,
    identifier,
    error,
    autocompleteUrl,
  } = useAddUsersAs();

  const { isVisibleErrorModal } = useAutoSaveErrorModalContext();
  const { navigateTo, handleLocationChange } = useSaveChangesModal();

  const onCancelCustomPrompt = (navigateTo?: Location) => {
    if (navigateTo) {
      handleLocationChange(`${navigateTo.pathname}${navigateTo.search}`);
    }
  };

  if (error) return <ErrorComponent error={error?.status} />;

  return (
    <Loader spinning={loading} wrapperClassName={classes.loader}>
      <div
        className={classes.contentWrapper}
        data-testid={OBJECT_CLASS_PERMISSIONS_MANAGE_OWNERS}
      >
        <div
          className={classes.identifier}
          data-testid={RECORD_ACCESS_IDENTIFIER}
        >
          {identifier}
        </div>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <FormattedMessage id='misc.addPeople' defaultMessage='Add people' />
          </div>
          <div className={classes.selectWrapper}>
            <FormattedMessage id='misc.addAs' defaultMessage='Add as' />
            <Select
              value={loading ? undefined : selectedPermissionSet}
              className={classes.permissionSelect}
              options={permissionSetSelectOptions}
              onChange={handleSelectPermissionSet}
              data-testid={RECORD_ACCESS_PERMISSION_SET_SELECT}
              virtual={false}
              placeholder={intl.formatMessage({
                id: 'misc.select',
                defaultMessage: 'Select',
              })}
              getPopupContainer={() => document.body}
            />
          </div>
        </div>
        {isLimitAchieved ? (
          <LimitExceededNotification
            {...{ itemsLimit, itemsCount: usersCount }}
            customMessage={limitMessage}
          />
        ) : (
          <div id={SELECT_USERS_WRAPPER} className={classes.bottomGap}>
            <AutocompleteUsersSelect
              onChange={onSelectChange}
              className={classes.autocompleteSelect}
              selectMultiple
              {...{
                checkOptionIsDisabled,
                autocompleteUrl,
              }}
              placeholder={intl.formatMessage({
                id: 'misc.searchForUsersAndUserGroups',
                defaultMessage: 'Search for users and user groups',
              })}
              renderOption={(option, searchValue) => (
                <OptionAvatar
                  {...{ option, searchValue }}
                  disabledText={getDisabledText(+option.value)}
                  disabled={checkOptionIsDisabled(option)}
                />
              )}
            />
          </div>
        )}
        <div className={classes.scrollableContainer} id={SCROLLABLE_CONTAINER}>
          <List<AvatarItem>
            emptyDataDescription={intl.formatMessage({
              id: 'owners.noUsersOrGroupsSelected',
              defaultMessage: 'No users or groups selected.',
            })}
            items={selectedUsers}
            renderItem={item => (
              <PeopleListElement
                {...item}
                onDelete={() => removeSeletedUser(item.id)}
              />
            )}
          />
        </div>
        <div className={classes.buttonsWrapper}>
          <ButtonSecondaryOutlined
            onClick={handleCancel}
            data-testid={BUTTON_CANCEL_TESTID}
          >
            <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
          </ButtonSecondaryOutlined>
          <ButtonTertiary
            disabled={
              !hasSelectedUsers || isLimitExceeded || !selectedPermissionSet
            }
            onClick={handleSubmit}
            loading={isSubmitting}
            data-testid={BUTTON_SUBMIT_TESTID}
            icon={<PlusIcon size={9} />}
          >
            <FormattedMessage id='misc.add' defaultMessage='Add' />
          </ButtonTertiary>
        </div>
        <CancelConfigurationModal
          visible={showUnsavedChangesModal && !isVisibleErrorModal}
          onCancel={hideUnsavedChangesModal}
          onConfirm={onConfirmCancelModal}
        />
        <CustomPrompt
          when={!navigateTo && hasSelectedUsers && !isVisibleErrorModal}
          onCancel={onCancelCustomPrompt}
          type={CustomPromptType.CANCEL}
        />
      </div>
    </Loader>
  );
};

export default AddUsersAs;
