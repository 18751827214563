import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { DisabledOptionParams } from 'components/AutocompleteUsersSelect/types';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import { useAutoSaveErrorModalContext } from 'contexts/AutoSaveErrorModalContext';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { useToggle } from 'hooks/useToggle';
import { StatusCodes } from 'http-status-codes';
import { CustomErrorMessage } from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassPermissions/components/AddUsersPanel/types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import { apiCall } from 'utils/api';
import {
  OBJECT_CLASS_RECORD_PERMISSION_SET_ASSIGNEES,
  OBJECT_RECORD_OWNERS,
} from 'utils/endpoints';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { ErrorCodes } from 'utils/types/errorResponse';
import { SelectUserOption } from 'utils/types/selectInput.types';
import { PermissionSetSelectOptions } from '../RecordOwners/types';
import { UsersMode } from './types';
import usePermissionSetAssignees from './usePermissionSetAssignees';
import { preventDefault } from 'utils/functions/preventDefault';
import { useBeforeunload } from 'react-beforeunload';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import usePermissionSetOptions from '../../hooks/usePermissionSetOptions';
import {
  getClassPermissionSets,
  getObjectRecordUsersError,
  getObjectRecordUsersLoading,
} from 'store/selectors/objectRecordSelectors';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import usePanels from 'hooks/usePanels';
import { useRefetchResourceContext } from 'contexts/RefetchResourceContext';
import { RefetchResources } from 'contexts/types';
import { showErrorToast } from 'features/toasts/utils/showErrorToast';
import { showSuccessToast } from 'features/toasts/utils/showSuccessToast';

const panelKey = FlexLayoutWindows.RecordAccess;

const useAddUsersAs = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { showErrorModal } = useAutoSaveErrorModalContext();
  const { refetchData } = useRefetchResourceContext();

  const { togglePanelsPreferences } = usePanels();
  const {
    [panelKey]: { selectedUsers = [], mode = UsersMode.Adding } = {},
  } = useSelector(getSidebarData);
  const classPermissionSets = useSelector(getClassPermissionSets);
  const isLoadingPermissionSets = useSelector(getObjectRecordUsersLoading);
  const error = useSelector(getObjectRecordUsersError);

  const [
    changedWithSelectedUsers,
    { toggleOn: toggleOnWithSelectedUsers },
  ] = useToggle(false);

  const {
    setShouldBeDisplayed,
    setStoredModalFunctions,
  } = useConfirmationModalContext();
  const {
    globalMethods: { closeComponent },
  } = useFlexLayoutContext();

  const [selectedPermissionSet, setSelectedPermissionSet] = useState<number>();

  const [
    shouldBackToParentPanel,
    { toggleOff: disableBackToParentPanel, toggleOn: enableBackToParentPanel },
  ] = useToggle(false);
  const [
    showUnsavedChangesModal,
    {
      toggleOff: hideUnsavedChangesModal,
      toggleOn: displayUnsavedChangesModal,
    },
  ] = useToggle(false);
  const [
    isSubmitting,
    { toggleOn: startSubmitting, toggleOff: endSubmitting },
  ] = useToggle(false);

  const handleSelectPermissionSet = useCallback(
    (value: number) => {
      setSelectedPermissionSet(value);

      if (selectedUsers.length && !!selectedPermissionSet)
        toggleOnWithSelectedUsers();
    },
    [selectedUsers.length, selectedPermissionSet, toggleOnWithSelectedUsers]
  );

  const {
    options,
    loading: optionsLoading,
    error: optionsError,
  } = usePermissionSetOptions(selectedPermissionSet);

  const {
    selectedResource: {
      record: { recordId = undefined, identifier = '' } = {},
    } = {},
  } = useSelectedResourceContext();

  const hasSelectedUsers = selectedUsers.length > 0;

  const permissionSetSelectOptions = useMemo(
    () => [
      {
        value: PermissionSetSelectOptions.Owners,
        label: intl.formatMessage({
          id: 'filterSelect.owner',
          defaultMessage: 'Owner',
        }),
      },
      ...(classPermissionSets
        .map(({ id, name }) => ({ value: id, label: name }))
        .sort((a, b) => {
          const aLabel = a.label.toLocaleLowerCase();
          const bLabel = b.label.toLocaleLowerCase();

          if (aLabel > bLabel) return 1;
          if (aLabel < bLabel) return -1;
          return 0;
        }) || []),
    ],
    [classPermissionSets, intl]
  );

  const selectedPermissionSetName = useMemo(
    () =>
      permissionSetSelectOptions?.find(
        ({ value }) => value === selectedPermissionSet
      )?.label || '',
    [permissionSetSelectOptions, selectedPermissionSet]
  );

  const {
    permissionSetAssignees,
    fetchPersmissionSetAssignees,
  } = usePermissionSetAssignees(selectedPermissionSet);
  const usersCount = permissionSetAssignees?.length || 0;

  const checkIsPermissionSetAssignee = useCallback(
    (idToCheck: number | string) =>
      permissionSetAssignees?.some(({ id }) => idToCheck === id),
    [permissionSetAssignees]
  );

  const checkIsSelectedUser = useCallback(
    (idToCheck: number | string) =>
      selectedUsers.some(({ id }) => idToCheck === id),
    [selectedUsers]
  );

  const addSelectedUser = useCallback(
    (newUser: AvatarItem) => {
      if (
        !checkIsPermissionSetAssignee(newUser.id) &&
        !checkIsSelectedUser(newUser.id)
      )
        dispatch(
          setSidebarData(panelKey, {
            selectedUsers: [newUser, ...selectedUsers],
            mode,
          })
        );
    },
    [
      checkIsPermissionSetAssignee,
      checkIsSelectedUser,
      dispatch,
      mode,
      selectedUsers,
    ]
  );

  const onSelectChange = async (props: SelectUserOption | undefined) => {
    const {
      id,
      first_name: firstName,
      last_name: lastName,
      company_name: company,
      username: email,
      account_type: accountType,
    } = props || {};

    if (!id) return;

    addSelectedUser({
      id,
      firstName,
      lastName,
      company,
      email,
      accountType,
    });
  };

  const removeSeletedUser = useCallback(
    (userId: number | string) =>
      dispatch(
        setSidebarData(panelKey, {
          selectedUsers: selectedUsers.filter(({ id }) => id !== userId),
          mode,
        })
      ),
    [dispatch, mode, selectedUsers]
  );

  const backToParentPanel = useCallback(() => {
    dispatch(
      setSidebarData(panelKey, {
        mode: UsersMode.Manage,
      })
    );
  }, [dispatch]);

  const closePanel = useCallback(() => {
    closeComponent(FlexLayoutWindows.RecordAccess);
    backToParentPanel();
    togglePanelsPreferences(FlexLayoutWindows.RecordAccess, true);
  }, [backToParentPanel, closeComponent, togglePanelsPreferences]);

  const handleCancel = () => {
    if (hasSelectedUsers) {
      displayUnsavedChangesModal();
      enableBackToParentPanel();

      return;
    }

    backToParentPanel();
  };

  useBeforeunload(event => hasSelectedUsers && preventDefault(event));

  useEffect(() => {
    // set "true" because we control the panels actions by methods in setStoredModalFunctions
    setShouldBeDisplayed(panelKey, true);

    return () => setShouldBeDisplayed(panelKey, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStoredModalFunctions(FlexLayoutWindows.RecordAccess, {
      callback: () => {
        displayUnsavedChangesModal();
        disableBackToParentPanel();
      },
      preventCloseTab: !hasSelectedUsers ? closePanel : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelectedUsers]);

  const customErrorMessage = useCallback(
    ({ errorCode, itemsLimit, count }: CustomErrorMessage) =>
      errorCode === ErrorCodes.ERR_LIMIT_EXCEEDED && itemsLimit
        ? {
            title:
              selectedPermissionSet === PermissionSetSelectOptions.Owners
                ? intl.formatMessage(
                    {
                      id: 'owners.countNotAddedOwners',
                      defaultMessage: `{count, plural, one {# owner} other {# owners}} not added`,
                    },
                    { count }
                  )
                : intl.formatMessage(
                    {
                      id: 'owners.countNotAddedAssignees',
                      defaultMessage: `{count, plural, one {# assignee} other {# assignees}} not added`,
                    },
                    { count }
                  ),
            subtitle:
              selectedPermissionSet === PermissionSetSelectOptions.Owners
                ? intl.formatMessage(
                    {
                      id: 'owners.limitReachedForOwners',
                      defaultMessage:
                        'The maximum number of owners has been reached.',
                    },
                    { itemsLimit }
                  )
                : intl.formatMessage(
                    {
                      id: 'owners.limitReachedForAssignees',
                      defaultMessage:
                        'The maximum number of assignees has been reached.',
                    },
                    { itemsLimit }
                  ),
          }
        : undefined,
    [intl, selectedPermissionSet]
  );

  const messageOnAddOwners = useCallback(
    (addedCount: number, notAddedCount: number) => {
      let subtitle: string | React.ReactNode = '';

      if (selectedPermissionSet === PermissionSetSelectOptions.Owners) {
        if (addedCount === 0) {
          subtitle = intl.formatMessage(
            {
              id: 'owners.ownersNotAdded',
              defaultMessage: `{notAddedCount, plural, one {# user was already an owner so has} other {# users were already an owners so have}} been ignored.`,
            },
            { notAddedCount }
          );
        } else if (changedWithSelectedUsers && notAddedCount) {
          subtitle = intl.formatMessage(
            {
              id: 'owners.ownersAddedAndNotAdded',
              defaultMessage: `{addedCount, plural, one {# owner} other {# owners}} has been added.{br}{notAddedCount, plural, one {# user was already an owner so has} other {# users were already an owners so have}} been ignored.`,
            },
            {
              addedCount,
              notAddedCount,
              name: selectedPermissionSetName,
              br: <br />,
            }
          );
        } else {
          subtitle = intl.formatMessage(
            {
              id: 'owners.ownersAdded',
              defaultMessage: `{count, plural, one {# owner has} other {# owners have}} been added.`,
            },
            { count: addedCount }
          );
        }
      } else if (addedCount === 0) {
        subtitle = intl.formatMessage(
          {
            id: 'owners.assigneesNotAdded',
            defaultMessage: `{notAddedCount, plural, one {# user was already in this permission set so has} other {# users were already in this permission set so have}} been ignored.`,
          },
          { notAddedCount }
        );
      } else if (changedWithSelectedUsers && notAddedCount) {
        subtitle = intl.formatMessage(
          {
            id: 'owners.assigneesAddedAndNotAddedTo',
            defaultMessage: `{addedCount, plural, one {# user} other {# users}} given access in {name}.{br}{notAddedCount, plural, one {# user was already in this permission set so has} other {# users were already in this permission set so have}} been ignored.`,
          },
          {
            addedCount,
            notAddedCount,
            name: selectedPermissionSetName,
            br: <br />,
          }
        );
      } else {
        subtitle = intl.formatMessage(
          {
            id: 'owners.assigneesAddedTo',
            defaultMessage: `{count, plural, one {# assignee} other {# assignees}} added to {name}.`,
          },
          { count: addedCount, name: selectedPermissionSetName }
        );
      }

      showSuccessToast({
        title: intl.formatMessage({
          id: 'misc.success',
          defaultMessage: 'Success!',
        }),
        subtitle,
      });
    },
    [
      changedWithSelectedUsers,
      intl,
      selectedPermissionSet,
      selectedPermissionSetName,
    ]
  );

  const handleSubmit = async () => {
    startSubmitting();

    try {
      const postApiEndpoint =
        selectedPermissionSet === PermissionSetSelectOptions.Owners
          ? generatePath(OBJECT_RECORD_OWNERS, {
              id: recordId,
            })
          : generatePath(OBJECT_CLASS_RECORD_PERMISSION_SET_ASSIGNEES, {
              recordId,
              permissionSetId: selectedPermissionSet,
            });

      const users = selectedUsers.map(({ id }) => id);
      const { data } = await apiCall.post(postApiEndpoint, users);

      messageOnAddOwners(data.length, selectedUsers.length - data.length);

      dispatch(
        setSidebarData(panelKey, {
          selectedUsers: [],
          mode,
        })
      );
      refetchData(RefetchResources.RecordAccess);

      backToParentPanel();
    } catch (error) {
      const { response: { data = {}, status = undefined } = {} } = error || {};

      if (status === StatusCodes.NOT_FOUND && showErrorModal) {
        showErrorModal(error as AxiosError);

        return;
      }

      if (data?.error_code === ErrorCodes.ERR_LIMIT_EXCEEDED)
        fetchPersmissionSetAssignees();

      const { title, subtitle } =
        customErrorMessage({
          errorCode: data.error_code,
          count: selectedUsers.length,
          itemsLimit,
        }) || {};

      if (title) {
        showErrorToast({ title, subtitle });
      } else {
        showUnhandledErrorToast(error);
      }

      endSubmitting();
    }
  };

  const itemsLimit = options?.restrictions?.limit_items;
  const usersSum = usersCount + selectedUsers.length;
  const isLimitExceeded =
    !!selectedPermissionSet &&
    itemsLimit !== undefined &&
    usersSum > itemsLimit;
  const isLimitAchieved =
    !!selectedPermissionSet &&
    itemsLimit !== undefined &&
    usersSum >= itemsLimit;

  const checkOptionIsDisabled = useCallback(
    ({ id: idToCheck }: DisabledOptionParams) => {
      return (
        checkIsPermissionSetAssignee(idToCheck) ||
        checkIsSelectedUser(idToCheck)
      );
    },
    [checkIsPermissionSetAssignee, checkIsSelectedUser]
  );

  const onConfirmCancelModal = useCallback(() => {
    hideUnsavedChangesModal();

    if (shouldBackToParentPanel) backToParentPanel();
    else closePanel();
  }, [
    backToParentPanel,
    closePanel,
    hideUnsavedChangesModal,
    shouldBackToParentPanel,
  ]);

  const getDisabledText = useCallback(
    (idToCheck: number) => {
      if (checkIsPermissionSetAssignee(idToCheck)) {
        return selectedPermissionSet === PermissionSetSelectOptions.Owners
          ? intl.formatMessage({
              id: 'misc.alreadyAnOwner',
              defaultMessage: 'Already an owner',
            })
          : intl.formatMessage({
              id: 'misc.alreadyAnAssignee',
              defaultMessage: 'Already an assignee',
            });
      } else if (checkIsSelectedUser(idToCheck)) {
        return intl.formatMessage({
          id: 'misc.alreadySelected',
          defaultMessage: 'Already selected',
        });
      }
    },
    [
      selectedPermissionSet,
      checkIsPermissionSetAssignee,
      checkIsSelectedUser,
      intl,
    ]
  );

  const toAdd = itemsLimit ?? 0;

  const limitMessage = useMemo(
    () =>
      selectedPermissionSet === PermissionSetSelectOptions.Owners
        ? intl.formatMessage(
            {
              id: 'recordAccess.maxOfOwners',
              defaultMessage: `Maximum of {toAdd} new {toAdd, plural, one {owner} other {owners}} can be added to {identifier} ({alreadyAdded} already added).`,
            },
            {
              identifier,
              toAdd,
              alreadyAdded: usersCount,
            }
          )
        : intl.formatMessage(
            {
              id: 'recordAccess.maxOfAssignees',
              defaultMessage: `Maximum of {toAdd} new {toAdd, plural, one {assignee} other {assignees}} can be added to {name} ({alreadyAdded} already added).`,
            },
            {
              name: selectedPermissionSetName,
              toAdd,
              alreadyAdded: usersCount,
            }
          ),
    [
      identifier,
      intl,
      selectedPermissionSet,
      selectedPermissionSetName,
      toAdd,
      usersCount,
    ]
  );

  const autocompleteUrl = options?.batch?.autocomplete;

  return {
    selectedPermissionSet,
    permissionSetSelectOptions,
    loading: isLoadingPermissionSets || optionsLoading,
    error: error || optionsError,
    handleSelectPermissionSet,
    handleCancel,
    handleSubmit,
    isSubmitting,
    usersCount,
    usersSum,
    itemsLimit,
    selectedUsers,
    hasSelectedUsers,
    isLimitExceeded,
    isLimitAchieved,
    onSelectChange,
    checkOptionIsDisabled,
    getDisabledText,
    removeSeletedUser,
    showUnsavedChangesModal,
    hideUnsavedChangesModal,
    onConfirmCancelModal,
    limitMessage,
    identifier,
    autocompleteUrl,
  };
};

export default useAddUsersAs;
