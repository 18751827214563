import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { FormPreview2Props, FormPreview2RefProps } from './types';
import FormPreview2Content from './FormPreview2Content';
import { ExtendedJsonSchema } from 'components/formBuilder/types/extendedJsonSchema';
import { FORM_BUILDER_SUPPORTED_SCHEMA_VERSION } from 'utils/consts';
import CouldNotOpenForm from 'components/formBuilder/components/CouldNotOpenForm';
import { FileUploadContextProviderWrapper } from './FileUploadContext';
import { FORM_PREVIEW_WRAPPER } from '../../utils/elementsIds';

const FormPreview2: ForwardRefRenderFunction<
  FormPreview2RefProps,
  FormPreview2Props
> = ({ wrapperId, ...props }, ref) => {
  const { schema, uischema, showShemaError, onSchemaErrorGoBackClick } = props;

  if (showShemaError) return <CouldNotOpenForm hideClearFormButton />;

  if (!schema || !uischema) return null;

  try {
    const { schemaVersion } = JSON.parse(schema) as ExtendedJsonSchema;

    if (schemaVersion !== FORM_BUILDER_SUPPORTED_SCHEMA_VERSION)
      return (
        <CouldNotOpenForm
          hideClearFormButton
          onClick={onSchemaErrorGoBackClick}
        />
      );
  } catch {
    return (
      <CouldNotOpenForm
        hideClearFormButton
        onClick={onSchemaErrorGoBackClick}
      />
    );
  }

  return (
    <FileUploadContextProviderWrapper>
      <FormPreview2Content
        {...props}
        ref={ref}
        schema={schema}
        uischema={uischema}
        wrapperId={wrapperId ?? FORM_PREVIEW_WRAPPER}
      />
    </FileUploadContextProviderWrapper>
  );
};

export default forwardRef<FormPreview2RefProps, FormPreview2Props>(
  FormPreview2
);
