import { useCallback, useState } from 'react';
import {
  SelectUserAndGroupOption,
  UsersAndGroupsSelectLimits,
} from 'utils/types/selectInput.types';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { useUserFieldMinErrorMessages } from 'components/UsersAndGroupsSelection/hooks/useUserFieldMinErrorMessages';
import { useValidateFulfillment } from 'components/UsersAndGroupsSelection/hooks/useValidateFulfillment';
import { useFieldRequiredValidation } from 'components/InPlaceEditControls/hooks';
import { useUsersValidation } from './useUsersValidation';
import { useGroupsValidation } from './useGroupsValidation';
import { ObjectRecordUserAndGroupMetadata } from 'utils/types/api/objectRecords.types';
import { uniqBy } from 'lodash';
import { FieldSelectionWorkspace } from 'components/FormPreview2/types/fieldSelectionWorkspace';

/**
 * Provides tools to validate various elements of InPlaceEditUser.
 */
export const useInPlaceEditUserValidation = (
  fieldLabel: string,
  apiErrors: string[],
  limits: UsersAndGroupsSelectLimits
) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const { validateRequired } = useFieldRequiredValidation(
    limits.isFieldRequired
  );
  const { validateUsers } = useUsersValidation(
    fieldLabel,
    limits.selectionCountLimits
  );
  const { validateGroups } = useGroupsValidation(
    fieldLabel,
    limits.selectionCountLimits
  );
  const { minGroupsError, minUsersError } = useUserFieldMinErrorMessages(
    fieldLabel,
    limits.selectionCountLimits?.minUsers ?? 1,
    limits.selectionCountLimits?.minGroups ?? 1
  );
  const {
    validateFulfillment,
    tooltip,
    fulfillmentErrors,
  } = useValidateFulfillment(
    fieldLabel,
    FieldSelectionWorkspace.InPlaceEditRecord
  );

  const clearErrors = useCallback(() => {
    setValidationErrors([]);
  }, []);

  const validateField = useCallback(
    (fieldValue: UsersAndGroupsFormValue | null) => {
      const errors = [
        ...validateRequired(fieldValue),
        ...validateUsers(fieldValue),
        ...validateGroups(fieldValue),
      ];

      setValidationErrors(errors);
      return errors.length === 0;
    },
    [validateGroups, validateRequired, validateUsers]
  );

  /**
   * Validates whether the field is fulfillable. It takes into account the current value of the
   * field and the available options defined in the class field.
   */
  const validateFulfillmentPossibility = useCallback(
    (
      fieldValue: UsersAndGroupsFormValue | undefined,
      options: SelectUserAndGroupOption,
      valueMetadata: ObjectRecordUserAndGroupMetadata,
      isMinUsersFulfilled: boolean
    ) => {
      const combinedUsers = uniqBy(
        [
          ...options.users.filter(user => !user.is_deleted),
          ...valueMetadata.users,
        ],
        'id'
      );
      const combinedGroups = uniqBy(
        [...options.groups, ...valueMetadata.groups],
        'id'
      );

      validateFulfillment(
        fieldValue,
        {
          users: combinedUsers,
          groups: combinedGroups,
          isRequired: limits.isFieldRequired ?? false,
          limits,
        },
        isMinUsersFulfilled
      );
    },
    [limits, validateFulfillment]
  );

  return {
    validateFulfillmentPossibility,
    validateField,
    errors: [...fulfillmentErrors, ...validationErrors, ...(apiErrors ?? [])],
    tooltip,
    clearErrors,
    minUsersError,
    minGroupsError,
  };
};
