import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { showWarningToast } from 'features/toasts/utils/showWarningToast';

export const showPermissionsToSomeUsersRevokedToast = () => {
  showWarningToast({
    title: GlobalIntlSingleton.intl.formatMessage({
      id: 'misc.warning',
      defaultMessage: 'Warning!',
    }),
    subtitle: GlobalIntlSingleton.intl.formatMessage({
      id: 'tasksPanel.permissionsToSomeUsersHaveBeenRevoked',
      defaultMessage:
        'None of the selected users could be assigned to the task due to insufficient permissions.',
    }),
  });
};
