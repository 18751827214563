import { createUseStyles } from 'react-jss';

export const useAuthLayoutFooterStyles = createUseStyles({
  wrapper: {
    display: 'grid',
    placeItems: 'center',

    padding: '8px',

    fontSize: '0.75rem',
  },
});
