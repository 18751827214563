import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { get } from 'lodash';
import { NO_PERMISSIONS_TO_SELECT_GROUP_ERROR_REGEX } from 'utils/consts';

/**
 * Checks whether the error is a "no permissions to select group" error. The only way to check if
 * the error is a "no permissions to select group" error is to evaluate the error message by
 * predefined regex.
 *
 * @param error Axios error returned from the API.
 * @param messagePath Path to the error message in the error object. This can either be:
 *   - A string representing a simple dot-separated path (e.g., "detail" or "options.user_groups").
 *   - An array of strings representing the path (e.g., ["options", "user_groups"]).
 *
 * @returns Boolean that indicates whether the error is a "no permissions to select group" error.
 */
export const isNoPermissionsToSelectGroupError = (
  error: AxiosError,
  messagePath: string | string[] = ['options', 'user_groups']
) => {
  if (!error.response) {
    return false;
  }

  const { data, status } = error.response;

  if (status !== StatusCodes.BAD_REQUEST) {
    return false;
  }

  const message = get(data, messagePath)?.[0];

  return NO_PERMISSIONS_TO_SELECT_GROUP_ERROR_REGEX.test(message);
};
