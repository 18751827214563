import React from 'react';
import { FormattedMessage } from 'react-intl';
import useExpiredPasswordStyles from './styles';
import LoaderOverlay from '../../components/LoaderOverlay';
import { useExpiredPasswordData, useExpiredPasswordForm } from './hooks';
import { Checkbox } from '../../components/lib/Checkbox';
import PasswordRules from '../../components/PasswordRules';
import { Formik } from 'formik';
import FormLabel from '../TaskTemplates/components/FormLabel';
import PasswordInput from '../../components/Inputs/PasswordInput';
import { ButtonPrimary } from '../../components/lib/Button';
import { ExpiredPasswordFields } from './enums';
import { useToggle } from '../../hooks/useToggle';
import { useParams } from 'react-router-dom';
import { ExpiredPasswordFormValues, ExpiredPasswordParams } from './types';
import { usePasswordRulesData } from 'hooks/usePasswordRulesData';
import { AuthLayout } from 'components/AuthLayout';
import { StatusCodes } from 'http-status-codes';
import ErrorComponent from 'components/ErrorComponent';

const ExpiredPassword = () => {
  const { token } = useParams<ExpiredPasswordParams>();
  const styles = useExpiredPasswordStyles();
  const {
    loadingPasswordRulesData,
    passwordComplexityData,
  } = usePasswordRulesData({ fetchOnLoad: !!token });
  const {
    activateAccountData,
    loadingActivateAccountData,
    error,
  } = useExpiredPasswordData();
  const {
    setIsPasswordFulfilled,
    onSubmit,
    buildValidationSchema,
    initialValues,
    isPasswordFulfilled,
  } = useExpiredPasswordForm();
  const [toggled, { toggle }] = useToggle(false);

  if (
    loadingActivateAccountData &&
    loadingPasswordRulesData &&
    !passwordComplexityData &&
    (!activateAccountData || !error)
  )
    return <LoaderOverlay />;

  if (!token)
    return (
      <div className={styles.notFoundWrapper}>
        <ErrorComponent error={StatusCodes.NOT_FOUND} />
      </div>
    );

  return (
    <AuthLayout>
      <header className={styles.header}>
        <p className={styles.title}>
          <FormattedMessage
            id='expiredPassword.title'
            defaultMessage='Reset expired password'
          />
        </p>
        <p className={styles.subtitle}>
          <FormattedMessage
            id='expiredPassword.provideNewPassword'
            defaultMessage='Your password has expired. Please provide new password.'
          />
        </p>
      </header>

      <Formik
        {...{ initialValues, onSubmit }}
        validationSchema={buildValidationSchema()}
        enableReinitialize
      >
        {({ handleSubmit, errors, dirty, isSubmitting }) => (
          <>
            <FormLabel required>
              <FormattedMessage
                id='expiredPassword.password'
                defaultMessage='New password'
              />
            </FormLabel>
            <PasswordInput
              visibilityToggle={false}
              isPasswordVisible={toggled}
              name={ExpiredPasswordFields.Password}
            />
            <FormLabel required>
              <FormattedMessage
                id='expiredPassword.confirmPassword'
                defaultMessage='Confirm new password'
              />
            </FormLabel>
            <PasswordInput
              visibilityToggle={false}
              isPasswordVisible={toggled}
              name={ExpiredPasswordFields.ConfirmPassword}
            />
            <Checkbox checked={toggled} onChange={toggle}>
              <FormattedMessage
                id='expiredPassword.showPassword'
                defaultMessage='Show password'
              />
            </Checkbox>
            <PasswordRules<ExpiredPasswordFormValues>
              setIsFulfilled={setIsPasswordFulfilled}
              options={passwordComplexityData}
              passwordKey={ExpiredPasswordFields.Password}
            />
            <div className={styles.buttonWrapper}>
              <ButtonPrimary
                disabled={
                  Object.keys(errors).length > 0 ||
                  isSubmitting ||
                  !dirty ||
                  !isPasswordFulfilled
                }
                onClick={() => handleSubmit()}
              >
                <FormattedMessage
                  id='expiredPassword.reset'
                  defaultMessage='Reset password'
                />
              </ButtonPrimary>
            </div>
          </>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default ExpiredPassword;
