import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import { sortUserGroupAvatars } from './utils/sortUserGroupAvatars';
import { UserGroupAvatarData } from 'hooks/avatars/types';

/**
 * Creates user group avatars from provided user group data.
 *
 * @param users User groups to transform.
 *
 * @returns Alphatecially sorted list of user group avatars.
 */
export const createUserGroupAvatars = (
  groups: UserGroupAvatarData[]
): AvatarItem[] => {
  const sortedGroups = sortUserGroupAvatars(groups);

  return sortedGroups.map(group => ({
    id: group.id,
    userGroup: group.name,
  }));
};
