export const FORGOT_PASSWORD_FORM_VALIDATION = {
  email: {
    type: 'email',
    validators: [
      {
        type: 'max_length',
        length: 100,
      },
    ],
  },
};
