import EmptyData from 'components/EmptyData';
import useDynamicSchema from 'components/formBuilder/hooks/useDynamicSchema';
import { Loader } from 'components/lib/Loader';
import useBackToList from 'hooks/useBackToList';
import useData from 'hooks/useData';
import { StatusCodes } from 'http-status-codes';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { OBJECT_CLASS_DETAILS, OBJECT_RECORD_DETAILS } from 'utils/endpoints';
import routes from 'utils/routingPaths';
import useEditRecordFormStyles from './styles';
import { EditRecordFormProps } from './types';
import useEditRecordForm from './hooks';
import { ButtonSecondaryOutlined } from 'components/lib/Button';
import { extractFormDataFromRecord } from 'components/formBuilder/utils/formPreviewUtils';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import { useToggle } from '../../../../hooks/useToggle';
import ErrorModal from '../CreateRecordForm/ErrorModal';
import { useRecordOwners } from 'pages/Records/hooks';
import ErrorComponent from 'components/ErrorComponent';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import { useHelmetContext } from 'contexts/HelmetContext';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import FormPreview2 from 'components/FormPreview2';
import { FormPreview2RefProps } from 'components/FormPreview2/types';
import migrateFormDefinitionToSupportedVersion from 'components/formBuilder/migrateDefinition';
import useRefetchResource from 'hooks/useRefetchResource';
import { RefetchResources } from 'contexts/types';
import { AutoSaveErrorModalContextProvider } from 'contexts/AutoSaveErrorModalContext';
import { useRefetchResourceContext } from 'contexts/RefetchResourceContext';
import { EDIT_RECORD_FORM_COMPONENT_NAME } from './consts';
import { useDispatch, useSelector } from 'react-redux';
import { selectChildClasses } from 'store/selectors/childClassesSelectors';
import useChildClasses from 'pages/Records/useChildClasses';
import useNestedRecordsStack from 'hooks/useNestedRecordsStack';
import { setObjectClassesResponse } from 'store/actions/objectClassesActions';
import { FORM_DRAWER_TESTID } from 'utils/testIds';
import { ObjectClassDetailsFromApi } from 'pages/ObjectClasses/components/ObjectClassForm/types';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import { setCurrentTable } from 'store/actions/filtersActions';

const getTestId = (isDrawer: boolean) => {
  return isDrawer ? FORM_DRAWER_TESTID : undefined;
};

const EditRecordForm = ({
  recordId,
  isDrawer,
  wrapperId,
}: EditRecordFormProps) => {
  const intl = useIntl();
  const { setPageTitle } = useHelmetContext();
  const styles = useEditRecordFormStyles();
  const history = useHistory();
  const { generateBackPath } = useBackToList();
  const formRef = useRef<FormPreview2RefProps>(null);
  const [isOpen, { toggleOff: closeModal, toggleOn: openModal }] = useToggle(
    false
  );
  const { setSelectedResource } = useSelectedResourceContext();
  const { refetchData: refetchResourceData } = useRefetchResourceContext();
  const childClasses = useSelector(selectChildClasses);
  const dispatch = useDispatch();
  const recordPath = generatePath(OBJECT_RECORD_DETAILS, { id: recordId });
  const currentTable = useSelector(getCurrentTable);

  const [
    record,
    { loading: recordLoading, error: recordError, fetchData },
  ] = useData<ObjectRecordDetails>(recordPath, {
    dataTransformFn: extractFormDataFromRecord,
  });

  // wrapper function is made so parameters from "useRefetchResource" are not passed to "fetchData"
  const refetchData = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useRefetchResource(
    RefetchResources.Records,
    refetchData,
    EDIT_RECORD_FORM_COMPONENT_NAME
  );

  const isEditPage = record?._meta?.permissions?.edit;
  const hasViewOwnersPermission = !!record?._meta.permissions.view_owners;

  const [
    objectClass,
    { loading: classLoading, error: classError, fetchData: fetchClass },
  ] = useData<ObjectClassDetailsFromApi>(
    record?.object_class
      ? generatePath(OBJECT_CLASS_DETAILS, { id: record?.object_class })
      : '',
    {
      fetchOnLoad: false,
    }
  );

  const { updateSelectedElement } = useNestedRecordsStack(
    isDrawer,
    objectClass,
    record
  );

  const { setObjectClassChildren, childrenClassLoading } = useChildClasses({
    objectClass,
  });

  useEffect(() => {
    setObjectClassChildren();
  }, [objectClass, setObjectClassChildren]);

  const rawUiSchema = objectClass?.display_configuration?.recordView?.ui_schema;

  const { initialFormData, setInitialFormData, errors } = useEditRecordForm(
    recordId,
    formRef,
    openModal,
    rawUiSchema
  );

  useEffect(() => {
    if (
      errors &&
      Object.values(errors).some(item =>
        item.includes('This field must be unique.')
      )
    ) {
      openModal();
    } else if (errors) {
      showUnhandledErrorToast(new Error('Error while saving record'));
    }
  }, [errors, openModal]);

  useEffect(() => {
    if (!record) {
      return;
    }

    (async () => {
      dispatch(setObjectClassesResponse({ loading: true }));
      const { data, error } = await fetchClass(
        generatePath(OBJECT_CLASS_DETAILS, { id: record?.object_class })
      );
      dispatch(setObjectClassesResponse({ data, error, loading: false }));
    })();
  }, [record, fetchClass, setSelectedResource, dispatch]);

  const onFieldSaveSuccess = useCallback(
    data => {
      if (data?.object_name && data.object_name !== record?.object_name) {
        updateSelectedElement({ recordIdentifier: data.object_name });
      }
      refetchResourceData(RefetchResources.Records, {
        skipComponents: 'editRecord',
        refetchFnData: { recordId },
      });
    },
    [record, refetchResourceData, recordId, updateSelectedElement]
  );

  const { loading: schemaLoading, schema, uiSchema } = useDynamicSchema(
    objectClass?.display_configuration?.recordView?.data_schema,
    rawUiSchema,
    objectClass?.id
  );

  const { supportedSchema, supportedUiSchema } = useMemo(() => {
    const {
      schema: supportedSchema,
      uiSchema: supportedUiSchema,
    } = migrateFormDefinitionToSupportedVersion(
      JSON.stringify(schema),
      JSON.stringify(uiSchema)
    );

    return {
      supportedSchema,
      supportedUiSchema,
    };
  }, [schema, uiSchema]);

  const suppordesSchemaData = useMemo(
    () => (supportedSchema ? JSON.parse(supportedSchema) : undefined),
    [supportedSchema]
  );

  const {
    loading: ownersLoading,
    data: owners = [] as AvatarItem[],
  } = useRecordOwners(
    suppordesSchemaData,
    recordId,
    false,
    hasViewOwnersPermission
  );

  useEffect(() => {
    if (!record || !rawUiSchema) return;

    setInitialFormData(record, owners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, rawUiSchema, setInitialFormData, JSON.stringify(owners)]);

  const title = isEditPage
    ? intl.formatMessage(
        {
          id: 'objectRecords.edit',
          defaultMessage: 'Edit {identifierValue} record',
        },
        { identifierValue: record?.object_name }
      )
    : intl.formatMessage(
        {
          id: 'objectRecords.view',
          defaultMessage: 'View {identifierValue} record',
        },
        { identifierValue: record?.object_name }
      );

  useEffect(() => {
    if (title) setPageTitle(title);
  }, [setPageTitle, title]);

  /**
   * Clear current table when the form is closed. Child class table cannot do it on its own as it
   * is not aware whether it's rendered in a drawer or not. Setting state to undefined when it's a
   * drawer would cause tables from the parent record to break.
   */
  useEffect(() => {
    if (!currentTable || isDrawer) {
      return;
    }

    return () => {
      dispatch(setCurrentTable(undefined));
    };
  }, [currentTable, isDrawer, dispatch]);

  const testId = getTestId(isDrawer);

  if (
    recordError?.status === StatusCodes.NOT_FOUND ||
    classError?.status === StatusCodes.NOT_FOUND
  )
    return (
      <EmptyData
        title={intl.formatMessage(
          {
            id: 'objectRecords.noRecordFound',
            defaultMessage: 'No record with id {id} found',
          },
          { id: recordId }
        )}
      />
    );

  if (schema && !schema.enabled)
    return (
      <div>
        <h4 className={styles.noRecordViewMessage}>
          <FormattedMessage
            id='objectRecords.createEditViewDisabled'
            defaultMessage='Create/edit view is disabled'
          />
        </h4>
      </div>
    );

  if (
    ownersLoading ||
    recordLoading ||
    classLoading ||
    childrenClassLoading ||
    (rawUiSchema !== undefined &&
      rawUiSchema !== null &&
      (schemaLoading || !initialFormData))
  )
    return (
      <div className={styles.loaderWrapper}>
        <Loader size='large' />
      </div>
    );

  if (
    recordError?.status ||
    classError?.status ||
    !record?._meta?.permissions?.view
  ) {
    return (
      <ErrorComponent
        error={recordError?.status || classError?.status || 403}
      />
    );
  }

  const cancelForm = () => history.push(generateBackPath(routes.RECORDS));

  return (
    <div className={styles.wrapper}>
      {!schema || !rawUiSchema ? (
        <div>
          <div className={styles.noRecordViewTitleRow}>
            <div className={styles.title}>{title}</div>
            <ButtonSecondaryOutlined onClick={cancelForm}>
              <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
            </ButtonSecondaryOutlined>
          </div>
          <h4 className={styles.noRecordViewMessage}>
            <FormattedMessage
              id='objectRecords.noRecordViewDefined'
              defaultMessage='No record view display defined'
            />
          </h4>
        </div>
      ) : (
        <AutoSaveErrorModalContextProvider
          redirectUrl={routes.RECORDS}
          title={
            <FormattedMessage
              id='misc.recordDeleted'
              defaultMessage='{name} has been deleted.'
              values={{
                name: record.object_name,
              }}
            />
          }
        >
          <FormPreview2
            schema={supportedSchema}
            uischema={supportedUiSchema}
            testId={testId}
            initialValues={initialFormData}
            omitDefaultValues
            ref={formRef}
            onSchemaErrorGoBackClick={cancelForm}
            {...{
              title,
              inPlaceEditMode: true,
              inPlaceEditUrl: recordPath,
              readOnly: !isEditPage,
              hideOwners: !hasViewOwnersPermission,
            }}
            additionalFieldProps={{
              identifier: record?.object_name,
              recordId: record?.id,
              classId: record.object_class,
              childClasses,
              meta: record._meta,
            }}
            onInPlaceSaveSuccess={onFieldSaveSuccess}
            wrapperId={wrapperId}
          />
        </AutoSaveErrorModalContextProvider>
      )}
      <ErrorModal {...{ closeModal, isOpen }} />
    </div>
  );
};

export default EditRecordForm;
