import { StatusCodes } from 'http-status-codes';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  APPEND_SEQUENCES,
  RESET_SEQUENCES,
  SET_SEQUENCES_FETCHING,
  SET_SEQUENCES,
  SET_SEQUENCES_COLUMNS,
  RESET_SEQUENCES_COLUMNS,
  SET_SEQUENCES_SELECTED_ROW,
  SET_SEQUENCES_RESTRICTIONS,
  SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES,
  SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES_LOADING,
} from 'store/constants/sequences.consts';
import { RootAction, RootState } from 'store/reducers';
import { apiCall } from 'utils/api';
import { SEQUENCES_LIST, SEQUENCE_DETAILS } from 'utils/endpoints';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import DataFetchType from 'utils/Enums/DataFetchType';
import { OptionsResponse } from 'utils/types';
import { createTableUrl } from 'utils/functions/createTableUrl';
import { Sequence, SequenceApiState } from 'utils/types/api/sequences.types';
import {
  SetTableAction,
  ActionObject,
  ColumnsMetadata,
  Restrictions,
} from 'utils/types/api/table.types';
import { generatePath } from 'react-router-dom';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import axios, { AxiosError } from 'axios';

export type SetSequencesAction = SetTableAction<
  Pick<ActionObject<Sequence>, 'list' | 'total' | 'filtered'>,
  typeof SET_SEQUENCES
>;

export type AppendSequencesAction = SetTableAction<
  Pick<ActionObject<Sequence>, 'list' | 'total' | 'filtered'>,
  typeof APPEND_SEQUENCES
>;

export type SetSequencesFetchingAction = SetTableAction<
  Pick<ActionObject<Sequence>, 'fetching'>,
  typeof SET_SEQUENCES_FETCHING
>;

export type SetSequencesColumnsAction = SetTableAction<
  Pick<ActionObject<Sequence>, 'payload'>,
  typeof SET_SEQUENCES_COLUMNS
>;

export type ResetProcessColumnsAction = Action<typeof RESET_SEQUENCES_COLUMNS>;

export type ResetSequencesAction = Action<typeof RESET_SEQUENCES>;

export type SetSelectedRowAction = SetTableAction<
  Pick<ActionObject<Sequence>, 'selectedRow'>,
  typeof SET_SEQUENCES_SELECTED_ROW
>;

export type SetSequencesRestrictionsAction = SetTableAction<
  { restrictions: Restrictions },
  typeof SET_SEQUENCES_RESTRICTIONS
>;
export type SetSequencesTaskDefaultAssigneesAction = SetTableAction<
  { data: AvatarItem[] },
  typeof SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES
>;
export type SetSequencesTaskDefaultAssigneesLoadingAction = SetTableAction<
  { data: boolean },
  typeof SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES_LOADING
>;

export type SequencesAction =
  | SetSequencesAction
  | SetSequencesFetchingAction
  | AppendSequencesAction
  | ResetSequencesAction
  | SetSequencesColumnsAction
  | ResetProcessColumnsAction
  | SetSelectedRowAction
  | SetSequencesRestrictionsAction
  | SetSequencesTaskDefaultAssigneesAction
  | SetSequencesTaskDefaultAssigneesLoadingAction;

export const setSequences = (
  list: Sequence[],
  total: number,
  filtered: number
): SetSequencesAction => {
  return {
    type: SET_SEQUENCES,
    list,
    total,
    filtered,
  };
};

export const appendSequences = (
  list: Sequence[],
  total: number,
  filtered: number
): AppendSequencesAction => {
  return {
    type: APPEND_SEQUENCES,
    list,
    total,
    filtered,
  };
};

export const setFetchingSequences = (
  fetching: boolean
): SetSequencesFetchingAction => {
  return {
    type: SET_SEQUENCES_FETCHING,
    fetching,
  };
};

export const resetSequences = (): ResetSequencesAction => {
  return {
    type: RESET_SEQUENCES,
  };
};

export const resetSequencesColumns = (): ResetProcessColumnsAction => {
  return {
    type: RESET_SEQUENCES_COLUMNS,
  };
};

export const getSequences = (
  queryParams?: string,
  fetchType:
    | DataFetchType.Append
    | DataFetchType.Overwrite = DataFetchType.Overwrite
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  dispatch(setFetchingSequences(true));

  try {
    const { status, data } = await apiCall.get(
      createTableUrl(SEQUENCES_LIST, queryParams)
    );

    if (status === StatusCodes.OK) {
      if (fetchType === DataFetchType.Overwrite) {
        dispatch(
          setSequences(data.results, data.total_count, data.filtered_count)
        );
      } else {
        dispatch(
          appendSequences(data.results, data.total_count, data.filtered_count)
        );
      }
    }
  } catch (error) {
    dispatch(setFetchingSequences(false));
    showUnhandledErrorToast(error);
  }
};

export const setSequencesColumns = (
  columns: ColumnsMetadata[]
): SetSequencesColumnsAction => {
  return {
    type: SET_SEQUENCES_COLUMNS,
    payload: columns,
  };
};

export const setSequencesRestrictions = (
  restrictions: Restrictions
): SetSequencesRestrictionsAction => ({
  type: SET_SEQUENCES_RESTRICTIONS,
  restrictions,
});

export const getSequencesColumnConfiguration = (): ThunkAction<
  void,
  RootState,
  undefined,
  RootAction
> => async dispatch => {
  try {
    const {
      status,
      data: {
        list: { columns },
        restrictions,
      },
    } = await apiCall.options<OptionsResponse>(SEQUENCES_LIST);

    if (status === StatusCodes.OK) {
      dispatch(setSequencesColumns(columns));

      if (restrictions !== undefined) {
        dispatch(setSequencesRestrictions(restrictions));
      }
    }
  } catch {}
};

export const setSequencesSelectedRow = (
  data: string | undefined
): SetSelectedRowAction => ({
  type: SET_SEQUENCES_SELECTED_ROW,
  selectedRow: data,
});

export const updateSequenceState = (
  id: string,
  state: SequenceApiState,
  callback: () => Promise<void>,
  errorCallback?: (error: AxiosError) => void
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  dispatch(setFetchingSequences(true));

  try {
    await apiCall.patch(generatePath(SEQUENCE_DETAILS, { id }), { state });
    dispatch(setFetchingSequences(false));

    await callback();
  } catch (error) {
    if (
      axios.isAxiosError(error) &&
      (error?.response?.status === StatusCodes.FORBIDDEN ||
        error?.response?.status === StatusCodes.NOT_FOUND) &&
      errorCallback
    ) {
      errorCallback(error as AxiosError);
    } else {
      showUnhandledErrorToast(error);
    }
    dispatch(setFetchingSequences(false));
  }
};

export const setDefaultTaskAssignees = (
  results: AvatarItem[]
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  dispatch({
    type: SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES,
    data: results,
  });
};

export const setDefaultTaskAssigneesLoading = (
  loading: boolean
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  dispatch({
    type: SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES_LOADING,
    data: loading,
  });
};
