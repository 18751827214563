import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import { useMemo } from 'react';
import { createUserAvatars, createUserGroupAvatars } from './utils';
import { UserAvatarData, UserGroupAvatarData } from '../../types';

/**
 * Transforms users and groups into singular avatar list.
 *
 * @param users Users to transform.
 * @param groups Groups to transform.
 *
 * @returns Alphatecially sorted list of user and group avatars.
 */
export const useUserAndGroupAvatars = (
  users: UserAvatarData[],
  groups: UserGroupAvatarData[]
) => {
  const userAvatars: AvatarItem[] = useMemo(() => {
    return createUserAvatars(users);
  }, [users]);

  const groupAvatars: AvatarItem[] = useMemo(() => {
    return createUserGroupAvatars(groups);
  }, [groups]);

  return { avatars: [...groupAvatars, ...userAvatars] };
};
