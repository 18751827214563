import { createUseStyles } from 'react-jss';

export const useLoginStyles = createUseStyles({
  title: {
    fontSize: 20,
    textAlign: 'center',
    margin: '20px 0',
    lineHeight: '34.5px',
    fontWeight: 700,
  },
});
