import { MouseEvent } from 'react';

export const useUserAvatarOnClick = (
  shouldFetchDetails: boolean,
  isPopoverVisible: boolean,
  disablePopover: boolean,
  isDeleted: boolean | undefined,
  getUserAvatarDetails: () => Promise<void>,
  openPopover: () => void
) => {
  const handleClick = async (event: MouseEvent<HTMLElement>) => {
    if (disablePopover || isDeleted) {
      return;
    }

    event.stopPropagation();

    if (isPopoverVisible) {
      return;
    }

    if (shouldFetchDetails) {
      getUserAvatarDetails();
      return;
    }

    openPopover();
  };

  return { handleClick };
};
