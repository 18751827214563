import { AccountTypeOptions } from 'pages/Users/enums';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';

export const selectLimitForUserGroup = (
  assignees: AvatarItem[] | undefined,
  oneTimeCompletionLimit: number | undefined,
  standardLimit: number | undefined
) =>
  !!assignees?.find(
    assignee => assignee.accountType === AccountTypeOptions.OneTimeCompletion
  )
    ? oneTimeCompletionLimit
    : standardLimit;
