import React from 'react';
import clsx from 'clsx';
import first from 'lodash/first';
import { ErrorsComponentProps } from './ErrorsComponent.types';
import { useFormPreviewStyles } from 'components/formBuilder/styles/formPreviewStyles';
import { ERROR_COMPONENT_TESTID } from 'utils/testIds';

export const ErrorsComponent = ({
  rawErrors,
  whitelist,
  blacklist,
}: ErrorsComponentProps) => {
  const styles = useFormPreviewStyles();

  let errors = rawErrors;

  if (whitelist) {
    errors = errors?.filter(error => whitelist.includes(error));
  }

  if (blacklist) {
    errors = errors?.filter(error => !blacklist.includes(error));
  }

  if (!errors?.length) {
    return null;
  }

  return (
    <span
      data-testid={ERROR_COMPONENT_TESTID}
      className={clsx([styles.errorMessage])}
    >
      {first(errors)}
    </span>
  );
};
