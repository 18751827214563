import { createUseStyles } from 'react-jss';
import defaultTheme from 'utils/defaultTheme';

export const useAuthLayoutStyles = createUseStyles({
  mainWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',

    height: '100vh',
  },
  leftSideWrapper: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',

    padding: '32px',
  },
  card: {
    width: '100%',
    maxWidth: '500px',
    padding: '32px',

    backgroundColor: defaultTheme['white-color'],
    boxShadow: '0px 4px 50px rgba(46, 63, 87, 0.19)',
    borderRadius: '4px',
  },
  logoWrapper: {
    display: 'grid',
    placeItems: 'center',

    marginBottom: '32px',
  },
  footer: {
    position: 'absolute',
    bottom: '0',
  },
  alxInformation: {}, // Needed to aviod error

  [`@media (max-width: 960px)`]: {
    mainWrapper: {
      gridTemplateColumns: '1fr',
    },

    alxInformation: {
      display: 'none',
    },
  },
});
