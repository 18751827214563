import { orderBy, uniqBy } from 'lodash';
import {
  SelectUserAndGroupOption,
  SelectUserAndGroupOptionMap,
} from 'utils/types/selectInput.types';

/**
 * Determines which items should be visible on the left list of UsersAndGroupsExpandablePicker based
 * on the search filter and the selected items.
 */
export const useUsersAndGroupsExpandablePickerVisibleItems = (
  availableItems: SelectUserAndGroupOption,
  selectedItems: SelectUserAndGroupOptionMap,
  searchFilter?: string
) => {
  // Some groups might have been selected in the past but are no longer available as an option.
  // They still should be shown to allow the user to remove them from the selection.
  const visibleGroups = uniqBy(
    [...availableItems.groups, ...Array.from(selectedItems.groups.values())],
    'id'
  );
  const sortedGroups = orderBy(visibleGroups, [
    ({ text }) => text.toLowerCase(),
  ]);

  // groups with sync mode disabled can select users that were not present on the original available users list
  // we check here for any selected users that are outside the available list and add them to the left panel if
  // they are selected.
  const visibleUsers = uniqBy(
    [
      ...availableItems.users.filter(user => !user.is_deleted),
      ...Array.from(selectedItems.users.values()),
    ],
    'id'
  );
  const sortedUsers = orderBy(visibleUsers, [
    ({ first_name, last_name, username }) =>
      (first_name + last_name + username).toLowerCase(),
  ]);

  const allItems = [...sortedGroups, ...sortedUsers];
  const filteredItems = allItems.filter(item =>
    item.text.toUpperCase().includes(searchFilter?.toUpperCase() ?? '')
  );

  return { visibleItems: filteredItems, allItems };
};
