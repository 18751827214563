import { useCallback, useMemo, useRef, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { FormValue } from 'alx-dynamic-form';
import { CustomFileItem } from 'components/lib/FileUpload/types';
import { FormPreview2RefProps, FormState } from 'components/FormPreview2/types';
import {
  applyFilesMetaToFormFields,
  getFilesTokens,
} from 'components/FormPreview2/utils';
import useData from 'hooks/useData';
import { apiCall } from 'utils/api';
import {
  TASK_1TC,
  TASK_1TC_COMPLETE,
  TASK_1TC_FIELDS,
  TASK_1TC_SAVE,
  FILE_UPLOAD_1TC,
} from 'utils/endpoints';
import { TaskDetails } from 'utils/types/api/tasks.types';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { ResponseError } from 'utils/types/errorResponse';
import { isBadRequest, isForbidden, isInvalidTokenError } from 'utils/apiUtils';
import useDynamicSchema from 'components/formBuilder/hooks/useDynamicSchema';
import { DynamicExtendedSchema } from 'components/formBuilder/formBuilder/types';
import { ExtendedUiSchema } from 'components/formBuilder/types/extendedUiSchema';
import migrateFormDefinitionToSupportedVersion from 'components/formBuilder/migrateDefinition';
import { TaskTypes } from 'pages/TaskTemplates/enums';

const useCompleteTaskBy1TC = () => {
  const formRef = useRef<FormPreview2RefProps>(null);
  const remountKey = useRef(0);
  const shouldSaveTaskProgress = useRef(false);
  const { id } = useParams<{ id: string }>();
  const [data, { loading, error: dataError, setLoading, fetchData }] = useData<
    TaskDetails
  >(generatePath(TASK_1TC, { id }));

  const { object_class, task_type } = data?.configuration.plugin_config || {};
  const { data_schema, ui_schema } =
    data?.configuration.plugin_config.configuration || {};

  const { schema, uiSchema, loading: schemaLoading } = useDynamicSchema(
    data_schema as DynamicExtendedSchema,
    ui_schema as ExtendedUiSchema,
    object_class,
    generatePath(TASK_1TC_FIELDS, { id })
  );

  const { supportedSchema, supportedUiSchema } = useMemo(() => {
    const {
      schema: supportedSchema,
      uiSchema: supportedUiSchema,
    } = migrateFormDefinitionToSupportedVersion(
      JSON.stringify(
        task_type === TaskTypes.RecordUpdate ? schema : data_schema
      ),
      JSON.stringify(
        task_type === TaskTypes.RecordUpdate ? uiSchema : ui_schema
      )
    );

    return {
      supportedSchema,
      supportedUiSchema,
    };
  }, [task_type, schema, data_schema, uiSchema, ui_schema]);

  const [formState, setFormState] = useState<FormState>({
    hasErrors: false,
    isFormTouched: false,
    isUploadingInProgress: false,
  });
  const [saveProgressModalVisible, setSaveProgressModalVisible] = useState(
    false
  );
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const [error, setError] = useState<ResponseError | undefined>();

  const onFormStateChange = useCallback((formState: FormState) => {
    setFormState(formState);
  }, []);

  const onSubmit = useCallback(
    async (
      formData: MappedObject<FormValue>,
      tokenData: MappedObject<CustomFileItem[]>
    ) => {
      setLoading(true);
      try {
        if (shouldSaveTaskProgress.current) {
          await apiCall.post(generatePath(TASK_1TC_SAVE, { id }), {
            data: formData,
            files: getFilesTokens(tokenData),
          });
          if (formRef.current) formRef.current.setFormUntouched();
          await fetchData();
          remountKey.current++;
          setSaveProgressModalVisible(true);
        } else {
          await apiCall.post(generatePath(TASK_1TC_COMPLETE, { id }), {
            data: formData,
            files: getFilesTokens(tokenData),
          });
          setIsTaskCompleted(true);
        }
      } catch (error) {
        if (
          (isBadRequest(error) && isInvalidTokenError(error)) ||
          isForbidden(error)
        ) {
          setError({ status: error.response?.status || 400 });
        } else {
          showUnhandledErrorToast(error);
        }
      } finally {
        setLoading(false);
      }

      return true;
    },
    [fetchData, id, setLoading]
  );

  const closeSaveProgressModal = useCallback(() => {
    setSaveProgressModalVisible(false);
  }, []);

  const handleSaveProgress = useCallback(() => {
    if (formRef.current) {
      shouldSaveTaskProgress.current = true;
      formRef.current.submitForm();
    }
  }, []);

  const handleCompleteTask = useCallback(() => {
    if (formRef.current) {
      shouldSaveTaskProgress.current = false;
      formRef.current.submitForm();
    }
  }, []);

  const initialValues = useMemo(() => {
    const filesData = applyFilesMetaToFormFields({
      uiSchema: supportedUiSchema,
      formData: data?.response?.files || {},
      filesMeta: {
        ...(data?.response?._meta?.labels?.files ?? {}),
      },
    });

    return {
      ...data?.response?.data,
      ...filesData,
    };
  }, [data, supportedUiSchema]);

  return {
    data,
    schema: supportedSchema,
    uiSchema: supportedUiSchema,
    loading: loading || schemaLoading,
    error: error || dataError,
    onFormStateChange,
    ...formState,
    formRef,
    onSubmit,
    handleSaveProgress,
    handleCompleteTask,
    isTaskCompleted,
    saveProgressModal: {
      onOk: closeSaveProgressModal,
      visible: saveProgressModalVisible,
    },
    customUploadUrl: generatePath(FILE_UPLOAD_1TC, { id }),
    initialValues,
    remountKey: remountKey.current,
    objectClassId: object_class,
  };
};

export default useCompleteTaskBy1TC;
