import { useState } from 'react';
import { User } from 'utils/types/api/users.types';
import { UserGroup } from 'utils/types/api/userGroups.types';
import { apiCall } from 'utils/api';
import { generatePath } from 'react-router-dom';
import { USER, USER_GROUP } from 'utils/endpoints';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';

export const useUserAvatarDetails = (
  id: number | string | undefined,
  groupName: string | undefined,
  customDetailsEndpoint: string | undefined,
  openPopover: () => void
) => {
  const [details, setDetails] = useState<User | UserGroup>();
  const [noViewPermission, setNoViewPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getUserAvatarDetails = async () => {
    if (!id) {
      return;
    }

    try {
      setIsLoading(true);

      const endpoint =
        customDetailsEndpoint ??
        generatePath(!!groupName ? USER_GROUP : USER, { id });

      const result = await apiCall.get(endpoint);

      let details = result.data;

      if (customDetailsEndpoint) {
        details = result.data._meta[!!groupName ? 'user_groups' : 'users'][id];
      }

      setDetails(details);
      openPopover();
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        showUnhandledErrorToast(error);
        return;
      }

      if (error.response?.status === StatusCodes.FORBIDDEN) {
        setNoViewPermission(true);
        openPopover();
        return;
      }

      showUnhandledErrorToast(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { details, isLoading, noViewPermission, getUserAvatarDetails };
};
