import React from 'react';
import PasswordRuleChips from './components/PasswordRuleChips';

import usePasswordRulesStyles from './styles';
import { usePasswordRules, usePasswordRulesLabels } from './hooks';
import { PasswordRules as PwdRules } from './enums';
import { PasswordRulesProps, FormValues } from './types';
import clsx from 'clsx';

const PasswordRules = <T extends FormValues | { new_password: string }>({
  options,
  passwordKey,
  className,
  setIsFulfilled,
}: PasswordRulesProps<T>) => {
  const { passwordRules } = usePasswordRules<T>({
    options,
    setIsFulfilled,
    passwordKey,
  });
  const passwordRulesLabels = usePasswordRulesLabels(options);
  const styles = usePasswordRulesStyles();

  return (
    <div className={clsx(styles.rulesWrapper, className)}>
      {Object.entries(passwordRules).map(([key, isFulfilled]) => (
        <PasswordRuleChips
          key={key}
          text={passwordRulesLabels?.[key as PwdRules]}
          {...{ passwordRule: key as PwdRules, isFulfilled }}
        />
      ))}
    </div>
  );
};

export default PasswordRules;
