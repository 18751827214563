import React from 'react';
import { AutologyxInformation } from './components/AutologyxInformation';
import { useAuthLayoutStyles } from './AuthLayout.styles';
import { AuthLayoutFooter } from './components/AuthLayoutFooter';
import { AuthLayoutProps } from './AuthLayout.types';
import { LogoIcon } from 'components/Icon';
import { FallbackLogoOptions } from 'components/Icon/types';

/**
 * Layout for the authentication pages e.g., login, forgot password, etc.
 */
export const AuthLayout = ({ children }: AuthLayoutProps) => {
  const styles = useAuthLayoutStyles();

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.leftSideWrapper}>
        <div className={styles.card}>
          <div className={styles.logoWrapper}>
            <LogoIcon
              width={150}
              height={50}
              fallbackLogo={FallbackLogoOptions.NORMAL}
            />
          </div>

          {children}
        </div>

        <AuthLayoutFooter className={styles.footer} />
      </div>

      <AutologyxInformation className={styles.alxInformation} />
    </div>
  );
};
