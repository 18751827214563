import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { AvatarGroup } from 'components/AvatarGroup';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import { get, isNil } from 'lodash';
import React, { useCallback } from 'react';
import { mapBaseUserGroupInfoToAvatarItem } from 'utils/functions/mapBaseUserGroupInfoToAvatarItem';
import { mapBaseUserInfoToAvatarItem } from 'utils/functions/mapBaseUserInfoToAvatarItem';
import { UserGroupInfoBase } from 'utils/types/api/UserGroupInfoBase';
import { UserInfoBase } from 'utils/types/api/UserInfoBase.types';
import { UserFieldCellProps } from './UserFieldCell.types';
import { CONTENT_ID } from 'utils/elementsIds';
import { Logger, LogLevel } from 'utils/logger';
import { generatePath } from 'react-router-dom';
import { OBJECT_RECORD_FIELD_DETAILS } from 'utils/endpoints';

/**
 * Table row cell dedicated for user type field column. Displays the specified amount of avatars
 * of users and user groups. If there is more selections than specified amount, a "more" avatar is
 * added that displays the rest of users and groups.
 */
export const UserFieldCell = ({
  row,
  value,
  recordId,
  fieldAlias,
}: UserFieldCellProps) => {
  const mapUserIdsToAvatarItems = useCallback(
    (userIds: number[], users: Record<number, UserInfoBase>) => {
      return userIds.reduce<AvatarItem[]>((avatars, id: number) => {
        const userData = users[id];

        if (isNil(userData)) {
          Logger.log(
            `UserFieldCell - Unable to create avatar for user with id: ${id}. No user metadata found. Skipping user display.`,
            LogLevel.Warning
          );
          return avatars;
        }

        return [...avatars, mapBaseUserInfoToAvatarItem(users[id])];
      }, []);
    },
    []
  );

  const getUserAvatars = useCallback(
    (
      value: Partial<UsersAndGroupsFormValue>,
      users: Record<number, UserInfoBase> | null | undefined
    ): AvatarItem[] => {
      if (isNil(value.users) || isNil(users)) {
        return [];
      }

      return mapUserIdsToAvatarItems(value.users, users);
    },
    [mapUserIdsToAvatarItems]
  );

  const getUserGroupAvatars = useCallback(
    (
      value: Partial<UsersAndGroupsFormValue>,
      userGroups: { [key: number]: UserGroupInfoBase } | null | undefined
    ): AvatarItem[] => {
      if (isNil(value.user_groups) || isNil(userGroups)) {
        return [];
      }

      return value.user_groups.map((id: number) => {
        return mapBaseUserGroupInfoToAvatarItem(userGroups[id]);
      });
    },
    []
  );

  const usersAndGroupsMeta: {
    users: Record<number, UserInfoBase> | null;
    user_groups: { [key: number]: UserGroupInfoBase } | null;
  } = get(row.original, '_meta', undefined);

  const userAvatars = getUserAvatars(value, usersAndGroupsMeta?.users);
  const userGroupAvatars = getUserGroupAvatars(
    value,
    usersAndGroupsMeta?.user_groups
  );

  return (
    <AvatarGroup
      items={[...userGroupAvatars, ...userAvatars]}
      customDetailsEndpoint={generatePath(OBJECT_RECORD_FIELD_DETAILS, {
        id: recordId,
        fieldAlias,
      })}
      visibleItemsLimit={1}
      getPopupContainer={() =>
        document.getElementById(CONTENT_ID) ?? document.body
      }
    />
  );
};
