import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useFormPreviewStyles = createUseStyles((theme: ThemeState) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  description: {
    maxWidth: 700,
    fontSize: 12,
    marginTop: 'var(--spacingWithin, 6px)',
    textAlign: 'justify',
    fontWeight: 400,
    color: theme['disabled-color'],
    display: 'block',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  descriptionGrow: {
    flexGrow: 1,
  },
  counter: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontWeight: 400,
    color: theme['disabled-color'],
    fontSize: 12,
    marginTop: 'var(--spacingWithin, 6px)',
    marginLeft: 9,
  },
  numericField: {
    width: 200,
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .ant-checkbox-wrapper span:not(.ant-checkbox)': {
      paddingRight: 0,
      wordBreak: 'break-word',
    },
    '& .ant-checkbox input, .ant-checkbox-inner': {
      width: 20,
      height: 20,
      borderColor: theme['checkbox-border-color'],
      borderWidth: 1.5,
    },
    '& .ant-checkbox-wrapper': {
      marginRight: 3,
    },
  },
  previewModalWrapper: {
    paddingTop: 25,
  },
  errorMessage: {
    fontSize: 12,
    color: theme['error-color-light'],
  },
  InstructionWrapper: {
    fontSize: 14,
    marginTop: 6,
    textAlign: 'justify',
    fontWeight: 400,
    color: theme['disabled-color'],
    lineHeight: '21px',
    display: 'block',
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces',
  },
  previewOnlyHeader: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    backgroundColor: '#FFFFFF',
    padding: '0px 15px',
    height: 46,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  previewOnlyHeaderText: {
    fontSize: 16,
    fontWeight: 700,
  },
  previewOnlyCloseIcon: {
    color: theme['secondary-color'],
  },
  maxMinWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 5,
  },
}));
