import clsx from 'clsx';
import { useAssigneeAvatars } from 'hooks/avatars/useAssigneeAvatars';
import useDateFormat from 'hooks/useDateFormat';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  INCOMPLETE_TASK_DUE_DATE,
  TASK_PROPERTY_ASSIGNEES,
} from 'utils/testIds';
import useTaskPropertiesStyles from '../../styles';
import TaskOwnership from '../TaskOwnership';
import { IncompleteTaskPropertiesContentProps } from './types';
import { AvatarGroup } from 'components/AvatarGroup';

const IncompleteTaskPropertiesContent: React.FC<IncompleteTaskPropertiesContentProps> = ({
  dueDate,
  stage: { assignees, owner, name: stageName } = {},
  nextActions,
  id,
}) => {
  const styles = useTaskPropertiesStyles({});

  const userAssignees = assignees?.users ?? [];
  const groupAssignees = assignees?.user_groups ?? [];

  const { avatars } = useAssigneeAvatars(userAssignees, groupAssignees);
  const { dateFormat } = useDateFormat();

  return (
    <>
      <div
        className={clsx(styles.gridItem, {
          [styles.assigneItem]: !!owner,
        })}
      >
        <FormattedMessage id='task.taskOwner' defaultMessage='Task owner: ' />
        <TaskOwnership
          owner={owner}
          nextActions={nextActions}
          taskId={id}
          stageId={stageName}
        />
      </div>
      <div className={styles.gridItem}>
        <FormattedMessage id='task.dueDate' defaultMessage='Due: ' />
        <b data-testid={INCOMPLETE_TASK_DUE_DATE}>
          {dueDate ? (
            moment(dueDate).format(dateFormat)
          ) : (
            <FormattedMessage id='misc.none' defaultMessage='None' />
          )}
        </b>
      </div>
      <div className={styles.gridItem}></div>
      <div
        className={clsx(styles.gridItem, {
          [styles.assigneItem]: avatars.length,
        })}
        data-testid={TASK_PROPERTY_ASSIGNEES}
      >
        <FormattedMessage id='task.assignedTo' defaultMessage='Assigned to: ' />
        {avatars.length ? (
          <AvatarGroup items={avatars} visibleItemsLimit={3} />
        ) : (
          <b>
            <FormattedMessage id='misc.none' defaultMessage='None' />
          </b>
        )}
      </div>
      <div className={styles.gridItem}></div>
      <div className={styles.gridItem}></div>
    </>
  );
};

export default IncompleteTaskPropertiesContent;
