import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonPrimary, ButtonSecondaryOutlined } from 'components/lib/Button';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import { SCROLLABLE_CONTAINER } from 'utils/elementsIds';
import {
  BUTTON_CANCEL_TESTID,
  MANAGE_ASSIGNEES_PANEL_BUTTON_ADD_ASSIGNEES,
  OBJECT_CLASS_PERMISSIONS_MANAGE_ASSIGNEES,
} from 'utils/testIds';
import useManageAssigneesPanel from './hooks';
import RemoveAssigneeModal from './RemoveAssigneeModal';
import DisabledButtonTooltip from 'components/DisabledButtonTooltip';
import { PlusIcon } from 'components/Icon';
import clsx from 'clsx';
import GroupListElement from '../GroupListElement';
import { useManageAssigneesStyles } from '../ManageAssigneesPanel/styles';

const ManageAssigneesGroupsPanel = () => {
  const intl = useIntl();
  const classes = useManageAssigneesStyles();

  const {
    handleCancel,
    assigneesAvatars,
    onDeleteFabric,
    assigneeToDelete,
    setAssigneeToDelete,
    removePermissionSetAssignee,
    openAddOwnersPanel,
  } = useManageAssigneesPanel();

  return (
    <div
      className={classes.contentWrapper}
      data-testid={OBJECT_CLASS_PERMISSIONS_MANAGE_ASSIGNEES}
    >
      <div className={classes.headerWrapper}>
        <DisabledButtonTooltip
          title={intl.formatMessage({
            id: 'assignees.disabledButtonMessage',
            defaultMessage:
              'The maximum number of assignees has been reached. Remove assignees to add new ones.',
          })}
          showTooltip={false}
        >
          <ButtonPrimary
            onClick={openAddOwnersPanel}
            icon={<PlusIcon size={9} />}
            data-testid={MANAGE_ASSIGNEES_PANEL_BUTTON_ADD_ASSIGNEES}
            disabled={false}
            className={clsx({ [classes.buttonDisabled]: false })}
          ></ButtonPrimary>
        </DisabledButtonTooltip>
      </div>
      <div className={classes.scrollableContainer} id={SCROLLABLE_CONTAINER}>
        <List<AvatarItem>
          emptyDataDescription={intl.formatMessage({
            id: 'misc.noGroupsHaveBeenAssigned',
            defaultMessage: 'No user groups have been assigned',
          })}
          items={assigneesAvatars}
          renderItem={item => (
            <GroupListElement {...item} onDelete={onDeleteFabric(item)} />
          )}
        />
      </div>
      <div className={classes.buttonsWrapper}>
        <ButtonSecondaryOutlined
          onClick={handleCancel}
          data-testid={BUTTON_CANCEL_TESTID}
        >
          <FormattedMessage id='misc.close' defaultMessage='Close' />
        </ButtonSecondaryOutlined>
      </div>
      <RemoveAssigneeModal
        {...{
          assigneeToDelete,
          setAssigneeToDelete,
          removePermissionSetAssignee,
        }}
      />
    </div>
  );
};

export default ManageAssigneesGroupsPanel;
