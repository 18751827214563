import { propertiesIds } from 'components/formBuilder/formBuilder/InputsContainer/consts';
import { ExtendedUiSchema } from 'components/formBuilder/types/extendedUiSchema';
import { ExtendedJsonSchema } from 'components/formBuilder/types/extendedJsonSchema';
import { FIELD_PREFIX } from 'utils/consts';
import { getUiSections } from '../../utils';
import { MigrationFunction } from '../types';

/*
This migration function changes every class field alias from <alias> to field_<alias>
*/
const migrationToV5: MigrationFunction = ({ schema, uiSchema }) => {
  const [migratedSchema, migratedAliases] = migrateSchema(schema);
  const migratedUiSchema = migrateUiSchema(uiSchema, migratedAliases);

  return { schema: migratedSchema, uiSchema: migratedUiSchema };
};

const migrateSchema = (
  schema: ExtendedJsonSchema
): [ExtendedJsonSchema, string[]] => {
  const migratedAliases: string[] = [];
  const sectionsEntries = Object.entries(schema.properties || {});

  const migratedSectionEntries = sectionsEntries.map(
    ([sectionAlias, section]) => {
      const updatedSectionPropertiesEntries = Object.entries(
        section?.properties || {}
      ).map(([propertyAlias, property]) => {
        // if field does not contain fieldId then its not class field
        if (
          property.fieldId === undefined ||
          propertiesIds.includes(propertyAlias)
        )
          return [propertyAlias, property];

        migratedAliases.push(propertyAlias);

        return [`${FIELD_PREFIX}${propertyAlias}`, property];
      });

      const migratedRequired = [...(section.required || [])].map(requiredKey =>
        migratedAliases.includes(requiredKey)
          ? `${FIELD_PREFIX}${requiredKey}`
          : requiredKey
      );

      const migratedSection = {
        ...section,
        properties: Object.fromEntries(updatedSectionPropertiesEntries),
        required: migratedRequired,
      };

      return [sectionAlias, migratedSection];
    }
  );

  const sectionDependenciesEntries = Object.entries(schema.deps || {});

  const updatedSectionConditionsEntries = sectionDependenciesEntries.map(
    ([sectionKey, sectionConditions]) => {
      const updatedSectionConditions = sectionConditions.map(condition => {
        const [sectionName, fieldKey] = condition?.path || [];

        if (!sectionName || !fieldKey || !migratedAliases.includes(fieldKey))
          return condition;

        return {
          ...condition,
          path: [sectionName, `${FIELD_PREFIX}${fieldKey}`],
        };
      });

      return [sectionKey, updatedSectionConditions];
    }
  );

  const migratedSchema = {
    ...schema,
    schemaVersion: 5,
    properties: Object.fromEntries(migratedSectionEntries),
    deps: Object.fromEntries(updatedSectionConditionsEntries),
  };

  return [migratedSchema, migratedAliases];
};

const migrateUiSchema = (
  uiSchema: ExtendedUiSchema,
  migratedAliases: string[]
): ExtendedUiSchema => {
  const uiSectionsEntries = getUiSections(uiSchema);

  const migratedSectionEntries = uiSectionsEntries.map(
    ([sectionKey, section]) => {
      const {
        'ui:columns': sectionColumns,
        //@ts-ignore
        'ui:order': sectionOrder,
        //@ts-ignore
        'ui:spacingAround': sectionSpacingAround,
        //@ts-ignore
        'ui:spacingWithin': sectionSpacingWithin,
        ...sectionComponents
      } = section;

      const migratesSectionComponents = Object.fromEntries(
        Object.entries(
          sectionComponents || {}
        ).map(([componentKey, component]) => [
          migratedAliases.includes(componentKey)
            ? `${FIELD_PREFIX}${componentKey}`
            : componentKey,
          component,
        ])
      );

      const migratedSectionColumns = sectionColumns.map(sectionColumn => ({
        ...sectionColumn,
        components: sectionColumn.components.map(componentKey =>
          migratedAliases.includes(componentKey)
            ? `${FIELD_PREFIX}${componentKey}`
            : componentKey
        ),
      }));

      return [
        sectionKey,
        {
          'ui:columns': migratedSectionColumns,
          'ui:order': sectionOrder,
          'ui:spacingAround': sectionSpacingAround,
          'ui:spacingWithin': sectionSpacingWithin,
          ...migratesSectionComponents,
        },
      ];
    }
  );

  return { ...uiSchema, ...Object.fromEntries(migratedSectionEntries) };
};

export default migrationToV5;
