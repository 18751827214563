import { ExtendedJsonSchema } from 'components/formBuilder/types/extendedJsonSchema';
import { DraggableComponents } from 'components/formBuilder/formBuilder/InputsContainer/enums';
import { DynamicExtendedSchema } from 'components/formBuilder/formBuilder/types';

export default function (
  schema: ExtendedJsonSchema | DynamicExtendedSchema | undefined,
  field: DraggableComponents
) {
  const fieldsKeys = Object.values(schema?.properties || {}).reduce<string[]>(
    (result, item) => [...result, ...Object.keys(item?.properties || {})],
    []
  );
  return fieldsKeys.includes(field);
}
