import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useExpandablePickerStandardGroupItemStyles = createUseStyles(
  (theme: ThemeState) => ({
    arrowIcon: {
      color: theme['secondary-color'],
      transition: 'transform 0.15s ease-in-out',
    },
    arrowFlipped: {
      transform: 'rotate(180deg)',
    },
    itemOpenedBackground: {
      background: theme['dropdown-active-gray'],
    },
    syncIcon: {
      position: 'absolute',
      right: '30px',
      top: '8px',
      color: theme['primary-color'],
    },
    popoverOverlay: {
      borderRadius: '0',
      minWidth: '330px',
      width: '330px',
      zIndex: '1500',
      '& .ant-popover-inner-content': {
        padding: '0',
        backgroundColor: 'white',
      },
      '& .ant-popover-inner': {
        position: 'absolute !important',
        transform: 'translateY(-24px) translateX(0px) !important;',
        minWidth: '330px',
        width: '330px',
        borderRadius: 0,
      },
      '& .ant-popover-arrow': {
        display: 'none',
      },
    },
  })
);
