// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ForgotPasswordForm } from '../ForgotPasswordForm';

/**
 * Enum defining different views of the {@link ForgotPasswordForm} component.
 */
export enum ForgotPasswordFormView {
  /**
   * Default view of the form.
   */
  Default,
  /**
   * View displayed when the request has been successfuly sent.
   */
  RequestSent,
  /**
   * View displayed when the request failed due to a locked account.
   */
  AccountLocked,
}
