import React from 'react';
import { ForgotPasswordForm } from './components/ForgotPasswordForm';
import { AuthLayout } from 'components/AuthLayout';

export const ForgotPasswordPage = () => {
  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  );
};
