import React from 'react';
import { UsersGroupsListItemDeselectOnlyProps } from './UsersGroupsListItemDeselectOnly.types';
import clsx from 'clsx';
import {
  USERS_AND_GROUPS_SELECT_ITEM_ONLY_DESELECT_BUTTON_TESTID,
  USERS_AND_GROUPS_SELECT_ITEM_ONLY_DESELECT_TYPE_TESTID,
} from 'utils/testIds';
import { OptionAvatar } from 'components/OptionAvatars/OptionAvatar';
import { OptionGroupAvatar } from 'components/OptionAvatars/OptionGroupAvatar';
import { UserOrGroupSelectOptionType } from 'utils/types/selectInput.types';
import { CloseIcon } from 'components/Icon';
import { useUsersGroupsListItemDeselectOnlyStyles } from './UsersGroupsListItemDeselectOnly.styles';
import { UsersGroupsListItemDeselectOnlyTooltip } from './components/UsersGroupsListItemDeselectOnlyTooltip';

/**
 * Component for rendering a list item for a user or group that can only be deselected with the X button.
 */
export const UsersGroupsListItemDeselectOnly = ({
  item,
  searchText,
  additionalText,
  onDeselect,
}: UsersGroupsListItemDeselectOnlyProps) => {
  const styles = useUsersGroupsListItemDeselectOnlyStyles();

  return (
    <UsersGroupsListItemDeselectOnlyTooltip item={item}>
      <div
        className={clsx(styles.optionItem)}
        data-testid={`${USERS_AND_GROUPS_SELECT_ITEM_ONLY_DESELECT_TYPE_TESTID}-${item.type}-${item.id}`}
      >
        {item.type === UserOrGroupSelectOptionType.User ? (
          <OptionAvatar
            disabledText={undefined}
            option={item}
            searchValue={searchText ?? ''}
            disabled={true}
            selected={true}
            additionalText={additionalText}
            disableDefaultSelectionStyle
          />
        ) : (
          <OptionGroupAvatar
            disabledText={undefined}
            option={item}
            searchValue={searchText ?? ''}
            disabled={true}
            selected={true}
            disableDefaultSelectionStyle
          />
        )}

        <button
          onClick={() => onDeselect(item)}
          className={styles.deselectButton}
          data-testid={USERS_AND_GROUPS_SELECT_ITEM_ONLY_DESELECT_BUTTON_TESTID}
        >
          <CloseIcon size={16} />
        </button>
      </div>
    </UsersGroupsListItemDeselectOnlyTooltip>
  );
};
