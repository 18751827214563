import { ResetPasswordFormField } from '../types/resetPasswordFormField';

export const RESET_PASSWORD_FORM_VALIDATION = {
  [ResetPasswordFormField.Password]: {
    required: true,
  },
  [ResetPasswordFormField.ConfirmPassword]: {
    required: true,
  },
};
