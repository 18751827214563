import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useAccountLockedInfoStyles = createUseStyles(
  (theme: ThemeState) => ({
    mainWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '16px',
    },
    icon: {
      width: '5.5rem',
      height: '5.5rem',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
    },
    title: {
      margin: 0,

      fontSize: '1.25rem',
      fontWeight: 700,
      textAlign: 'center',
    },
    subtitle: {
      margin: 0,

      fontSize: '0.75rem',
      color: theme['disabled-color'],
      textAlign: 'center',
    },
  })
);
