import React from 'react';
import { Avatar } from 'components/lib/Avatar';
import { UserAvatar } from 'components/UserAvatar';
import { SingleUserAvatarWithCounterProps } from '../../../UserCell/UserCell.types';
import { useUserCellStyles } from '../../../UserCell/UserCell.styles';

export const SingleUserAvatarWithCounter = ({
  id,
  firstName,
  lastName,
  getPopupContainer,
  closeOnElementScroll,
  total,
}: SingleUserAvatarWithCounterProps) => {
  const styles = useUserCellStyles();
  const allOwnersExceptMain = total - 1;

  if (!total) return null;

  return (
    <div className={styles.singleUserCellWrapper}>
      <UserAvatar
        {...{
          id,
          firstName,
          lastName,
          getPopupContainer,
          closeOnElementScroll,
        }}
        size='small'
      />
      {allOwnersExceptMain > 0 && (
        <Avatar isCountAvatar size='small' className={styles.counter}>
          +{allOwnersExceptMain}
        </Avatar>
      )}
    </div>
  );
};
