import { createUseStyles } from 'react-jss';

const useUserIdentificationStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  item: {
    marginBottom: '0 !important',
    margin: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'flex',
    gap: '3px',
  },
  namePart: {
    display: 'inline-block',
    maxWidth: 70,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default useUserIdentificationStyles;
