import { createUseStyles } from 'react-jss';

export const useAvatarGroupStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '1px',
  },
  moreAvatar: {
    cursor: 'pointer',
  },
  popover: {
    maxWidth: 'min(100%, 250px)',

    '& .ant-popover-arrow': {
      display: 'none',
    },
    '& .ant-popover-inner': {
      borderRadius: 5,
    },
    '& .ant-popover-inner-content': {
      padding: 10,
    },
  },
});
