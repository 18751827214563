import { AvatarGroup } from 'components/AvatarGroup';
import { useAssigneeAvatars } from 'hooks/avatars/useAssigneeAvatars';
import React from 'react';
import { UsersAndGroupsCellProps } from './UsersAndGroupsCell.types';
import { CONTENT_ID } from 'utils/elementsIds';

/**
 * Table row cell dedicated for user and group column type. Displays standard avatar group of
 * users and user groups.
 */
export const UsersAndGroupsCell = ({ value }: UsersAndGroupsCellProps) => {
  const { avatars } = useAssigneeAvatars(value.users, value.user_groups);

  return (
    <AvatarGroup
      items={avatars}
      visibleItemsLimit={1}
      getPopupContainer={() =>
        document.getElementById(CONTENT_ID) ?? document.body
      }
    />
  );
};
