import React, { useRef } from 'react';
import { CloseIcon, SyncIcon } from 'components/Icon';
import { OptionGroupAvatar } from 'components/OptionAvatars/OptionGroupAvatar';
import {
  USERS_AND_GROUPS_SELECT_ITEM_GROUP_SYNC_ICON_TESTID,
  USERS_AND_GROUPS_SELECT_ITEM_ONLY_DESELECT_BUTTON_TESTID,
  USERS_AND_GROUPS_SELECT_ITEM_ONLY_DESELECT_TYPE_TESTID,
} from 'utils/testIds';
import { ExpandablePickerDeselectOnlyGroupItemProps } from './ExpandablePickerDeselectOnlyGroupItem.types';
import { useUsersAndGroupsExpandablePickerContext } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/contexts/UsersAndGroupsExpandablePickerContext';
import { useExpandablePickerDeselectOnlyGroupItemStyles } from './ExpandablePickerDeselectOnlyGroupItem.styles';
import Tooltip from 'components/lib/Tooltip';
import { useIntl } from 'react-intl';
import { useTooltipVisibility } from 'hooks/useTooltipVisibility';

export const ExpandablePickerDeselectOnlyGroupItem = ({
  item,
}: ExpandablePickerDeselectOnlyGroupItemProps) => {
  const intl = useIntl();
  const styles = useExpandablePickerDeselectOnlyGroupItemStyles();

  const elementRef = useRef<HTMLDivElement>(null);

  const { isTooltipVisible, setIsTooltipVisible } = useTooltipVisibility(
    elementRef
  );
  const {
    searchText,
    toggleItemSelectionState,
  } = useUsersAndGroupsExpandablePickerContext();

  const tooltipText = intl.formatMessage({
    id: 'misc.groupWillNotBeAvailableForReselection',
    defaultMessage:
      'This user group will no longer be available for re-selection if removed from the field.',
  });

  return (
    <Tooltip
      align={{ offset: [0, 3] }}
      trigger={'hover'}
      visible={isTooltipVisible}
      mouseLeaveDelay={0}
      onVisibleChange={setIsTooltipVisible}
      overlay={tooltipText}
    >
      <div
        ref={elementRef}
        className={styles.optionItem}
        data-testid={`${USERS_AND_GROUPS_SELECT_ITEM_ONLY_DESELECT_TYPE_TESTID}-${item.type}-${item.id}`}
      >
        <OptionGroupAvatar
          option={item}
          searchValue={searchText}
          disabled={true}
          selected={true}
          showUserCount
          disableDefaultSelectionStyle
        />

        <SyncIcon
          className={styles.syncIcon}
          size={16}
          data-testid={USERS_AND_GROUPS_SELECT_ITEM_GROUP_SYNC_ICON_TESTID}
        />

        <button
          className={styles.deselectButton}
          data-testid={USERS_AND_GROUPS_SELECT_ITEM_ONLY_DESELECT_BUTTON_TESTID}
          onClick={() => toggleItemSelectionState(item)}
        >
          <CloseIcon size={16} />
        </button>
      </div>
    </Tooltip>
  );
};
