import React from 'react';
import { useUsersAndGroupsExpandablePickerContext } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/contexts/UsersAndGroupsExpandablePickerContext';
import { ExpandablePickerUserGroupItemProps } from './ExpandablePickerUserGroupItem.types';
import { ExpandablePickerStandardGroupItem } from './components/ExpandablePickerStandardGroupItem';
import { ExpandablePickerDeselectOnlyGroupItem } from './components/ExpandablePickerDeselectOnlyGroupItem';

/**
 * A single item that represents a user group on the left panel of UserAndGroupsExpandablePicker with
 * the logic to expand the right side panel.
 */
export const ExpandablePickerUserGroupItem = ({
  item,
  limitReached,
  onPopupOpen,
}: ExpandablePickerUserGroupItemProps) => {
  const { availableItems } = useUsersAndGroupsExpandablePickerContext();

  const isAvailableForReselection = availableItems.groups.some(
    availableGroup => availableGroup.id === item.id
  );

  return isAvailableForReselection ? (
    <ExpandablePickerStandardGroupItem
      item={item}
      limitReached={limitReached}
      onPopupOpen={onPopupOpen}
    />
  ) : (
    <ExpandablePickerDeselectOnlyGroupItem item={item} />
  );
};
