import { useCallback, useState } from 'react';
import { FulfillmentValidationParameters } from './types/fulfillmentValidationParameters';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { useUserValidationMessages } from './hooks/useUserValidationMessages';
import { getFulfillmentErrorMessage } from './utils/getFulfillmentErrorMessage';
import { FieldSelectionWorkspace } from 'components/FormPreview2/types/fieldSelectionWorkspace';

/**
 * Validates if user type field is possible to fulfill based on it's constraints like min/max values etc. based on received
 * users and groups.
 */
export const useValidateFulfillment = (
  fieldLabel: string,
  fieldWorkspace: FieldSelectionWorkspace,
  fulfillmentFormErroredFields?: Set<string>
) => {
  const [tooltip, setTooltip] = useState('');
  const [fulfillmentErrors, setFulfillmentErrors] = useState<string[]>([]);
  const [isDisabledByFulfillment, setIsDisabledByFulfillment] = useState(false);

  const {
    notEnoughOptionsToSelectTooltip,
    notEnoughOptionsToSelectError,
  } = useUserValidationMessages();

  const getCanPickAnyOption = useCallback(
    ({ groups, users, limits }: FulfillmentValidationParameters) => {
      const canPickIndividualUser = users.length > 0;
      const canPickSyncedGroup =
        groups.length > 0 && limits.isAllowedToSyncGroups;
      const canPickGroupMember =
        limits.isAllowedToSelectGroupMembers &&
        groups.some(group => group.num_of_members > 0);

      return canPickIndividualUser || canPickSyncedGroup || canPickGroupMember;
    },
    []
  );

  const evaluateIsMinUsersFulfilledApiFlag = useCallback(
    (
      currentValue: UsersAndGroupsFormValue | undefined | null,
      isRequired: boolean,
      isMinUsersFulfilled: boolean
    ) => {
      if (isRequired) {
        return isMinUsersFulfilled;
      }

      // if field is not required we have to check if there is a possibility of changing the value from something to empty.
      if (isMinUsersFulfilled) {
        return true;
      }

      return (
        currentValue &&
        currentValue.users.length + currentValue.user_groups.length > 0
      );
    },
    []
  );

  const setTooltipAndDisableField = useCallback(() => {
    setTooltip(notEnoughOptionsToSelectTooltip);
    setIsDisabledByFulfillment(true);
  }, [notEnoughOptionsToSelectTooltip]);

  const validateCollection = useCallback(
    (
      currentValue: UsersAndGroupsFormValue | undefined | null,
      validationData: FulfillmentValidationParameters,
      isMinUsersFulfilled: boolean
    ) => {
      const canPickAnyOption = getCanPickAnyOption(validationData);
      const isApiMinUsersFlagFulfilled = evaluateIsMinUsersFulfilledApiFlag(
        currentValue,
        validationData.isRequired,
        isMinUsersFulfilled
      );

      if (isApiMinUsersFlagFulfilled && canPickAnyOption) {
        return;
      }

      setTooltipAndDisableField();

      const { isRequired, limits } = validationData;

      if (
        !isRequired &&
        fieldWorkspace !== FieldSelectionWorkspace.InPlaceEditRecord
      ) {
        return;
      }

      const isEditMode =
        fieldWorkspace === FieldSelectionWorkspace.EditRecordForm ||
        fieldWorkspace === FieldSelectionWorkspace.InPlaceEditRecord;

      const errorMessage = getFulfillmentErrorMessage(
        isEditMode,
        limits.selectionCountLimits?.minUsers
      );

      setFulfillmentErrors([errorMessage]);
    },
    [
      getCanPickAnyOption,
      evaluateIsMinUsersFulfilledApiFlag,
      setTooltipAndDisableField,
      fieldWorkspace,
    ]
  );

  const evaluateFieldAgainstFormFulfillmentValidation = useCallback(() => {
    const isPartOfFormFulfillmentError = !!fulfillmentFormErroredFields?.has(
      fieldLabel
    );

    if (!isPartOfFormFulfillmentError) {
      return;
    }

    setTooltipAndDisableField();
    setFulfillmentErrors([notEnoughOptionsToSelectError]);
  }, [
    fieldLabel,
    fulfillmentFormErroredFields,
    notEnoughOptionsToSelectError,
    setTooltipAndDisableField,
  ]);

  return {
    validateFulfillment: validateCollection,
    evaluateFieldAgainstFormFulfillmentValidation,
    tooltip,
    fulfillmentErrors,
    isDisabledByFulfillment,
  };
};
